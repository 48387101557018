import React, { useEffect } from 'react';
import style from './CreateEvent.module.css';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import Section3 from './Components/Section3';
import Section4 from './Components/Section4';
import Section5 from './Components/Section5';
import Section6 from './Components/Section6';
import Footer from '../../../desktop/components/Footer/Footer';
import { Desktop, Mobile } from '../../responsive/Responsive';
import { useNavigate } from "react-router-dom";
import Section4a from './Components/Section4a';
import Section7 from './Components/Section7';
import { useDispatch } from 'react-redux';
import { resetAuction, setFrom } from '../../../../redux/eventReducer';
import SectionBlog from './Components/SectionBlog';

function CreateEvent() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const navigateToFundRaising = () => {
    navigate("/get-started")
  }
  const applyNow = () => {
    window.location.href = "https://programs.boundlessgiving.com";
  }
  useEffect(() => {
    dispatch(setFrom({ from: 'home' }))
    dispatch(resetAuction())
  }, [])
  return (
    <div className={style.landing_page}>
      <Section1 fundraise={navigateToFundRaising} />
      <Section2 fundraise={navigateToFundRaising} containerStyle={{ marginTop: '-80px' }} />
      <Section6 fundraise={applyNow} />
      <Section3 fundraise={navigateToFundRaising} />
      <Section4a />
      {/* <Section4 fundraise={navigateToFundRaising} /> */}
      <Section5 fundraise={navigateToFundRaising} />
      <SectionBlog />
      <Section7 />
      <Desktop>
        <Footer />
      </Desktop>
    </div>

  )
}

export default CreateEvent