import { useState, useEffect, useContext } from 'react'
import userServices from '../../../../services/user-services';
import './PurchaseHistory.css';
import CircularProgress from '@mui/material/CircularProgress';

import { useNavigate } from 'react-router-dom';

import AuthContext from '../../../../store/auth-context';
import downArrow from '../../../../images/downArrow.png'
import noOrder from '../../../../images/no_order.svg';

import logger from '../../../../utils/logger';
import { useMediaQuery } from "react-responsive";
import { Box, TablePagination } from '@mui/material';
import { mobileStyle } from '../../Home';
import Image from '../../atoms/Image/Image';

function OrderCard({ img, title, name, pastOrder, totalItems, navigateToTrack, orderAmount, orderDate, orderID, orderStatus,setValue,setOrderid}) {
  const [imageLoading, setImageLoading] = useState(true)
  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const formattedDate = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(orderDate));

  return (
    <div>

      <div className='purchase-history mt-2'>
        <div>
          <p className='order-number'>ORDER #{orderID}</p>
        </div>
        <div className='history-data'>
          <div className='brand-name'>
            <div className='brand-image'>
              {/* <img src={BrandImage} /> */}
              <Image
                className="cart-card-img"
                src={img}
                style={{ display: imageLoading ? "none" : 'block' }}
                onLoad={() => setImageLoading(false)}
              />
            </div>
            <div className='brand-details'>
              <h5>{title}</h5>
              <p>{name}</p>
              <h5>TOTAL: ${orderAmount}</h5>
            </div>
          </div>
          <div className='brand-status'>
            <p>{formattedDate}</p>
            <h4>{orderStatus}</h4>
            <h4 style={{color:'#8F8F8F' ,fontSize:'16px'}}>{'Qty: ' + totalItems}</h4>

          </div>
        </div>
      </div>
      <div style={{ textAlign: 'right', fontSize: '15px', fontWeight: 'bold', marginTop: '10px', textDecoration: 'underline' }}>

        <a style={{cursor:'pointer'}} onClick={()=>{
          setOrderid(orderID)
          setValue(12)}}>Track My Order</a>
      </div>
    </div>
  );
}



const PurchaseHistory = (props) => {

  const [bool, setBool] = useState('12'); //"11"==='Current Orders' "12"==='Past Orders'
  const [help, setHelp] = useState({
    open: false,
    id: '', //If any ( For Tracking Purpose )
  });
  const authCtx = useContext(AuthContext);
  const [historyData, setHistoryData] = useState();
  const [PreviousHistoryData, setPreviousHistoryData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate()
  const [totalOrders, setTotalOrders] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [mobilePage, setMobilePage] = useState(1)
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);

  function toggleHelp(id = '') {
    setHelp({ id: id, open: !help.open });
  }

  useEffect(() => {
    setIsLoading(true);
    const getPurchaseHistory = async (id, status) => {
      try {
        await userServices.getUserPurchaseHistory(id, status, page, limit).then(
          (response) => {
            logger('./pages/components/History.jsx','getPurchaseHistory(): res',response);
            setHistoryData(response?.data);
            setTotalOrders(response?.total_count)
            setTotalPages(response?.total_page)
            setIsLoading(false);
          },
          (error) => {
            logger('./pages/components/History.jsx','getPurchaseHistory(): catch1',error);
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/History.jsx','getPurchaseHistory(): catch2',err);
        setIsLoading(false);
      }
    };
    const getPreviousHistory = async (id, status) => {
      try {
        await userServices.getUserPurchaseHistory(id, status, page, limit).then(
          (response) => {
            logger('./pages/components/PurchaseHistory.jsx','getPurchaseHistory(): res',response);
            setPreviousHistoryData(response?.data);
            // setTotalOrders(response?.total_count)
            // setTotalPages(response?.total_page)
            // setIsLoading(false);
          },
          (error) => {
            logger('./pages/components/PurchaseHistory.jsx','getPurchaseHistory(): catch1',error);
            setIsLoading(false);
          }
        );
      } catch (err) {
        logger('./pages/components/PurchaseHistory.jsx','getPurchaseHistory(): catch2',err);
        setIsLoading(false);
      }
    };
    getPurchaseHistory(authCtx?.userId, 'current');
    getPreviousHistory(authCtx?.userId, 'past');

    return () => {
      setHistoryData(null);
    };
  }, [authCtx?.userId, bool, limit, page]);

  const navigateToTrack = (id) => {
    navigate(`/order/track/${id}`)
    return
  }

  const loadMoreAuction = async () => {
    setIsLoadingMore(true);
    if (bool === '11') {
      try {
        await userServices
          .getUserPurchaseHistory(authCtx?.userId, 'current', mobilePage + 1, limit)
          .then(
            (response) => {
              // let newAuctionsArr = [...historyData, ...response?.data];
              setHistoryData([...historyData, ...response?.data]);
              setIsLoadingMore(false);
              setMobilePage(mobilePage + 1);
            },
            (error) => {
              logger('./pages/components/History.jsx', 'loadMoreAuction(): catch1', error);
              setIsLoadingMore(false);
            }
          );
      } catch (err) {
        logger('./pages/components/History.jsx', 'loadMoreAuction(): catch2', err);
        setIsLoadingMore(false);
      }
    } else if (bool === '12') {
      try {
        await userServices
          .getUserPurchaseHistory(authCtx?.userId, 'past', mobilePage + 1, limit)
          .then(
            (response) => {
              // let newAuctionsArr = [...auctions?.data, ...response?.data];
              setHistoryData([...historyData, ...response?.data]);
              setIsLoadingMore(false);
              setMobilePage(mobilePage + 1);
            },
            (error) => {
              logger('./pages/components/History.jsx', 'loadMoreAuction(): catch3', error);
              setIsLoadingMore(false);
            }
          );
      } catch (err) {
        logger('./pages/components/History.jsx', 'loadMoreAuction(): catch4', err);
        setIsLoadingMore(false);
      }
    }
  };

  const primaryColor = mobileStyle.color[1];
  const primaryBackgroundColor = mobileStyle.backgroundColor[2];
  const redColor = mobileStyle.color[0];
  // debugger
  return (
    <>
      <div className='purchase-container purchase-container__history'>
        <h1>PURCHASE HISTORY</h1>
        <p>Current Order</p>
        <div style={{ textAlign: 'left', width: '70%' }}>
          <hr style={{ height: '1px', backgroundColor: 'black', }} />
        </div>

      
          <>
            <div className='section'>

              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100vh - 203px)",
                    color: primaryColor,
                    backgroundColor: primaryBackgroundColor,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{ color: 'red' }} />
                  </div>
                </Box>
              ) : <>
                {historyData &&
                  historyData?.map((i) => (
                    <OrderCard
                      navigateToTrack={() => navigateToTrack(i.id)}
                      key={i.id}
                      help={() => toggleHelp(i?.id)}
                      img={i.preview_image.find(img => (img.context_type === 'thumbnail' || img.context_type === 'portrait'))?.name}
                      title={i.user_order_auction_item_xrefs[0].auction_item?.auction.title}
                      name={i.user_order_auction_item_xrefs[0].auction_item?.name}
                      totalItems={i.user_order_auction_item_xrefs?.length}
                      orderAmount={i.order_amount}
                      orderDate={i.created}
                      orderID={i.user_order_auction_item_xrefs[0].user_order_id}
                      orderStatus={i.user_order_auction_item_xrefs[0].auction_item.status}
                      setValue={props.setValue}
                      setOrderid={props.setOrderId}
                    />
                  ))}
                {isLoadingMore ? (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                  >
                    Loading <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                  </div>
                ) : historyData && historyData.length > 0 && (mobilePage < totalPages) ? (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                    onClick={loadMoreAuction}
                  >
                    Load more &nbsp;
                    <img src={downArrow} alt='' />
                  </div>
                ) : historyData && historyData.length !== 0 ? <div style={{ textAlign: "center", fontWeight: 600, marginTop: '15px' }}></div> : (
                  (
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                      onClick={loadMoreAuction}
                    >
                      <img src={noOrder} width='270px' alt='no-result' />
                      <br />
                      <br />
                      No order found!
                    </div>
                  )
                )}</>}
            </div>
          </>
        

        <p>Previous Order</p>
        <div style={{ textAlign: 'left', width: '70%' }}>
          <hr style={{ height: '1px', backgroundColor: 'black', }} />
        </div>
        <div className='section'>

              {isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "calc(100vh - 203px)",
                    color: primaryColor,
                    backgroundColor: primaryBackgroundColor,
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress style={{ color: 'red' }} />
                  </div>
                </Box>
              ) : <>
                { PreviousHistoryData&&
                  PreviousHistoryData?.map((i) => (
                    <OrderCard
                      navigateToTrack={() => navigateToTrack(i.id)}
                      key={i.id}
                      help={() => toggleHelp(i?.id)}
                      img={i.preview_image.find(img => (img.context_type === 'thumbnail' || img.context_type === 'portrait'))?.name}
                      title={i.user_order_auction_item_xrefs[0].auction_item?.auction.title}
                      name={i.user_order_auction_item_xrefs[0].auction_item?.name}
                      totalItems={i.user_order_auction_item_xrefs?.length}
                      orderAmount={i.order_amount}
                      orderDate={i.created}
                      orderID={i.user_order_auction_item_xrefs[0].user_order_id}
                      orderStatus={i.user_order_auction_item_xrefs[0].auction_item.status}
                      setValue={props.setValue}
                      setOrderid={props.setOrderId}
                    />
                  ))}
                {isLoadingMore ? (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                  >
                    Loading <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                    <span className='loader__dot'>.</span>
                  </div>
                ) : PreviousHistoryData && PreviousHistoryData.length > 0 && (mobilePage < totalPages) ? (
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: '600',
                      fontSize: '14px',
                    }}
                    onClick={loadMoreAuction}
                  >
                    Load more &nbsp;
                    <img src={downArrow} alt='' />
                  </div>
                ) : PreviousHistoryData && PreviousHistoryData.length !== 0 ? <div style={{ textAlign: "center", fontWeight: 600, marginTop: '15px' }}></div> : (
                  (
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '16px',
                      }}
                      onClick={loadMoreAuction}
                    >
                      <img src={noOrder} width='270px' alt='no-result' />
                      <br />
                      <br />
                      No order found!
                    </div>
                  )
                )}</>}
            </div>
      </div>

    </>
  )
}

export default PurchaseHistory