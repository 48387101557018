import React, { useContext, useEffect } from 'react';
// import { mobileStyle } from '../../Home';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import DesktopCardLayout from '../../atoms/DesktopCardLayout/DesktopCardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCausesHaveEvents } from '../../../../redux/causeReducer';
import classes from './BrowseCause.module.css'
// import auctionServices from '../../../../services/auction-services';
import BrowsePagesLayout from '../../molecules/BrowsePagesLayout/BrowsePagesLayout';
// import MobileSubFooter from '../../atoms/MobileSubFooter/MobileSubFooter';
import { FilterContext } from '../../../../store/filter-context';
import { changePageNumber } from '../../../../redux/itemsReducer';

const BrowseCauses = () => {
  // let params = useLocation().state;
  // let causeCategoryId = params?.cause_category_id;
  const params = {
    from: 'browse-cause'
  }
  const isDesktop = useMediaQuery({ minWidth: 768 })
  const { reset } = useContext(FilterContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { causes, loading } = useSelector(state => state.causesHaveEvents);
  useEffect(() => {
    // dispatch(fetchCausesHaveEvents({ page: 1, limit: 20, from:params?.from, causeCategoryId:causeCategoryId }))
    dispatch(fetchCausesHaveEvents({ page: 1, limit: 200, from: params?.from }))
    reset()/// for reseting filters in filtercontext
    dispatch(changePageNumber(1)) /// for changing page number of browseItems page
  }, [])

  // let imageContainerStyle = {};
  // if(isDesktop) {
  //     imageContainerStyle = {
  //         height:'170px',
  //         minHeight:'170px',
  //         maxHeight:'170px',
  //     }
  // }
  // else {
  //     imageContainerStyle = {
  //         height: '150px',
  //         minHeight:'150px',
  //         maxHeight: '150px',
  //     }
  // }

  const handleCardClick = async (cause) => {
    try {
      /// now we are finding the events related to this cause and then going to the first event in the array.
      // const auctions = await auctionServices.getAuctionsByCauseId(id);
      const auctions = cause?.auctions;
      if (auctions?.length) {
        const firstEvent = auctions[0]
        navigate(`/store/${firstEvent.custom_url ? firstEvent.custom_url : firstEvent.id}`) // changing this to only for stores according to Neil's requirement in the ticket BG-1457
        // if(firstEvent.is_store == '1'){
        //     /// for store also we are using for marketplace = true and from = donor because both has same ui in event page.
        //      navigate(`/store/${firstEvent.custom_url ? firstEvent.custom_url : firstEvent.id}`, 
        //     //  { state: { auction: firstEvent, image: firstEvent.image2, title: firstEvent.title, from: 'donor', market_place: true } }
        //      )
        // }
        // else if(firstEvent.market_place == '1') {
        //     navigate(`/marketplace/${firstEvent?.custom_url ? firstEvent?.custom_url : firstEvent?.id}`,
        //     // { state: { auction: firstEvent, image: firstEvent.image2, title: firstEvent.title, from: 'donor', market_place: true } }
        //     )
        // }
        // else navigate(`/event/${firstEvent.custom_url ? firstEvent.custom_url : firstEvent.id}`, 
        // // { state: { auction: firstEvent, image: firstEvent.image2, from: 'event' } }
        // )
      }
    } catch (error) {

    }
  }

  return (
    <>
      <BrowsePagesLayout params={params} loading={loading} helmetTag={'Causes'}>
        {causes && causes?.length ? <div className="dbrowse-items-list" style={{ marginBottom: "10px" }}>
          <div className={classes.causes_grid_container}>
            {
              causes?.map((cause, index) => {
                return (
                  <React.Fragment key={index}>
                    <DesktopCardLayout
                      mainContainerStyle={{ gap: "10px", margin: 'auto', width: '100%' }}
                      //   imageContainerStyle={imageContainerStyle}
                      imageContainerClass={'browsePageCardImage'}
                      onCardClick={() => handleCardClick(cause)} image={cause?.cause_logo ? cause?.cause_logo : cause?.image1}>
                      <div className="cart-card-content">
                        <h1 style={{ textAlign: "center", fontWeight: "800" }} >{cause?.legal_name}</h1>
                      </div>
                    </DesktopCardLayout>
                  </React.Fragment>
                )
              })
            }
          </div>
        </div>
          : <div className="no-data" style={{ paddingTop: "4vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
            Coming Soon!
          </div>
        }
      </BrowsePagesLayout>
    </>
  )
}

export default BrowseCauses;