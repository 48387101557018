import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { getFilterDetails, checkChecked, handleFilterChange } from "../../../../utils/shopnow.utils";
import { AccordionDetails } from "@mui/material";
import { setSelectedFilters } from "../../../../redux/shopNowFilterReducer";

const BrowseByCheckBox = ({ filterArray = [], filterType = "", donor,cause, selectedFilters = { selectedFilters }, pageType = "shop-now" }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    // const { selectedFilters } = useSelector(state => state.shopNow);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };

    useEffect(() => { 
        if (donor) {
            dispatch(setSelectedFilters({key:"brands", value: donor}))
        }
       
     }, [donor]);
     useEffect(() => { 

        if (cause) {
            dispatch(setSelectedFilters({key:"causes", value: cause}))
        }
     }, [cause]);
    
    return (

        <AccordionDetails
            sx={{ padding: "8px 16px 0" }}>
            {filterArray && filterArray.map((category) => (
                <label key={category.id} className="customCheckbox">
                    {category.legal_name}
                    <input
                        key={category.id}
                        type="checkbox"
                        value={getFilterDetails(category, filterType).id}
                        checked={checkChecked(getFilterDetails(category, filterType), filterType, selectedFilters)}
                        onChange={() => handleFilterChange(getFilterDetails(category, filterType), filterType, dispatch, pageType)}
                    />
                    <span className="checkmark" />
                </label>
            ))}
        </AccordionDetails>

    )
}

export default BrowseByCheckBox;