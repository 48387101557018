import React, { useEffect, useState } from 'react';
import ImageSkelton from '../ImageSkelton/ImageSkelton';
import { Desktop } from '../../responsive/Responsive';
import classes from './ItemDetailCard.module.css'
import { Grid } from '@mui/material';
import Slider from 'react-slick';
import customimage from '../../../../images/DC-Logo.png'

var mainSliderSettings = {
    // asNavFor: '.thumb-slider', // Link the main slider to the thumb slider
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};


const ItemDetailCardLayout = ({ imageArray = [], landscapeImage, onThumbNailClick, children, customImageClass = "", alt = '' }) => {
    const [imageLoading, setImageLoading] = useState(true)
    const [mainSlider, setMainSlider] = useState(null);
    const [thumbSlider, setThumbSlider] = useState(null);

    const [lotDetails, setLotDetails] = useState({ status: "cancelled" });

    const handleThumbnailClick = (index) => {
        if (mainSlider) {
            mainSlider.slickGoTo(index);
        }
    };

    const thumbSliderSettings = {
        // asNavFor: '.main-slider', // Link the thumb slider to the main slider
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: imageArray.length,
        slidesToScroll: 1,
        focusOnSelect: true, // Click on thumbnails to select
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    // console.log(imageArray)
    return (
        <>
            {/* <Desktop> */}
            {/* <div className='itemdetails-dimages-container'>
                <div className={`itemdetails-dimages-image ${classes[customImageClass]}`}>
                    <img style={{ display: imageLoading ? 'none' : 'block' }} onLoad={() => { setImageLoading(false) }} src={landscapeImage} alt={alt} />
                    <ImageSkelton loadingState={imageLoading} style={{height: "100%", width: "100%"}} />
                     </div>
                <div className='itemdetails-dimages-thumb'>
                    {
                        imageArray.map((i, idx) => (
                            <div className={`${classes[customImageClass]}`} onClick={() => onThumbNailClick(idx)} key={i.name}>
                                <img style={{ display: imageLoading ? 'none' : 'block' }} onLoad={() => { setImageLoading(false) }} src={(i.name)?i.name:i} alt={alt} />
                                <ImageSkelton loadingState={imageLoading} style={{height: "100%", width: "100%"}} />
                            </div>
                        ))}
                </div>
            </div> */}
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <div
                            className="product-slider-container"
                            style={{ width: "80%", margin: "auto" }}
                        >
                            {/* Main Product Slider */}
                            <Slider
                                {...mainSliderSettings}
                                ref={(slider) => setMainSlider(slider)}
                                className="main-slider"
                            >
                                {imageArray && imageArray?.map((image, index) => (
                                    <div key={index}>
                                        <img
                                            src={image.name}
                                            alt={`Product ${index + 1}`}
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                ))}
                                {
                                   imageArray && imageArray?.length % 2 !== 0 ?
                                        <img
                                            src={imageArray[0].name}
                                            alt={`Product`}
                                            style={{ width: "100%" }}
                                        /> : ""
                                }

                            </Slider>

                            {/* Thumbnail Navigation Slider */}
                            <Slider
                                {...thumbSliderSettings}
                                ref={(slider) => setThumbSlider(slider)}
                                className="thumb-slider"
                            >
                                {imageArray && imageArray?.map((image, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleThumbnailClick(index)}
                                    >
                                        <img
                                            src={image?.name}
                                            alt={`Thumbnail ${index + 1}`}
                                            style={{
                                                width: "100px",
                                                cursor: "pointer",
                                                opacity: 0.7,
                                                transition: "opacity 0.3s",
                                            }}
                                            onMouseEnter={(e) => (e.target.style.opacity = 1)}
                                            onMouseLeave={(e) => (e.target.style.opacity = 0.7)}
                                        />
                                    </div>

                                ))}
                                {imageArray && imageArray?.length % 2 !== 0 ?
                                    <img
                                        src={imageArray[0].name}
                                        alt={`Thumbnail`}
                                        style={{
                                            width: "100px",
                                            cursor: "pointer",
                                            opacity: 0.7,
                                            transition: "opacity 0.3s",
                                        }}
                                        onMouseEnter={(e) => (e.target.style.opacity = 1)}
                                        onMouseLeave={(e) => (e.target.style.opacity = 0.7)}
                                    /> : ""
                                }

                            </Slider>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {children}
                    </Grid>
                </Grid>
            </>
            {/* </Desktop> */}
        </>
    )
}

export default ItemDetailCardLayout