import React, {useState} from "react";

import './PaymentMethods.css';
import ShowPaymentMethods from "./ShowPaymentMethods";
import AddPaymentMethods from "./AddPaymentMethods";
import { ChevronLeft } from "@mui/icons-material";

function PaymentMethod() {
  const [paymentMethod, setPaymentMethod] = useState(true)

  return (
    <div className='payment-container'>
      <h1>Payment Method</h1>
      { !paymentMethod &&
        <div>
        <span style={{ cursor: 'pointer', fontSize: '16px', fontFamily: 'DM Mono' }} onClick={() => setPaymentMethod(!paymentMethod)}>
          <ChevronLeft sx={{color: '#FF0000', fontSize: '32px'}} />
        </span> Back
        </div>
      }
      <div className="">
        {paymentMethod ? <ShowPaymentMethods paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} /> : <AddPaymentMethods  setPaymentMethod={setPaymentMethod}/>}
      </div>
    </div>
  );
}

export default PaymentMethod;