import React from 'react';
import classes from './ESMBannerPart.module.css';
import { Desktop, Mobile } from '../../responsive/Responsive';
import BrowseItemsBanner from '../../atoms/BrowseItemsBanner/BrowseItemsBanner';
import { mobileStyle } from '../../Home';
import TagHeading from '../../atoms/TagHeading/TagHeading';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from '../CountDown/CountDown';

///this is the component for event, store and marketplace page's banner section

const ESMBannerPart = ({ eventData = {}, image = '', itemType = '', primaryColor = mobileStyle.color[1], showIncomplete = false, showDetailsOverBanner = true }) => {

  const navigate = useNavigate()
  let dbBoxColor = ''
  let dbTextColor = mobileStyle.color[1];
  if (eventData?.need_box === "1" && eventData?.box_color) {
    dbBoxColor = eventData?.box_color
  }
  if (eventData?.text_color) {
    dbTextColor = eventData?.text_color
  }

  const handleEventOverview = () => {
    let url = ''
    switch (itemType) {
      case 'marketplace':
        url = 'marketplace-overview'
        break;
      case 'store':
        url = 'store-overview'
        break;
      default:
        url = 'event-overview'
        break;
    }
    navigate(`/${url}/${eventData?.id}`, { state: { auctionDetails: eventData, showIncomplete: showIncomplete ? true : false } })
  }
  const handleCauseOverview = () => {
    if (itemType === 'marketplace') return navigate(`/about-donor/${eventData?.donor_id}`)
    navigate(`/about-cause/${eventData?.cause_id}?auction_id=${eventData?.id}`, { state: { auctionDetails: eventData } })
  }

  return (
    <>
      {/* {console.log("Thisis event data--------->",JSON.stringify(eventData))} */}
     
      <Mobile>
        {
          <div className={`mp-banner-container ${classes.banner_contianer}`} style={{ backgroundImage: `url(${image})` }}>
            {
              showDetailsOverBanner &&
              <div style={{ height: '100%', width: '100%' }} className="overlay_white_gradient mp-event-banner-contianer">
                {(itemType === 'store') && <h2 className={classes.cause_legal_name} style={{ color: dbTextColor, borderBottom: `1px solid ${dbTextColor}` }}>{eventData?.causeDetails?.legal_name}</h2>}
                <div
                  style={{
                    padding: eventData?.need_box === "1" ? '30px 20px' : '',
                    backgroundColor: dbBoxColor,
                    borderRadius: eventData?.need_box === "1" ? '25px' : '',
                    minHeight: itemType === 'store' ? '200px' : '150px',
                    justifyContent: itemType === 'store' ? 'flex-end' : 'space-between',
                  }}
                  className={`mobile_info_container ${classes.mobile_info_container}`}>

                  {(itemType === 'event') && <TagHeading heading={eventData?.causeDetails.legal_name} style={{ color: dbTextColor }} />}
                  <h1 style={{ color: dbTextColor }} className="mobile_auction_title" >{eventData?.title}</h1>
                  {(itemType === 'event') ? new Date(eventData?.start_date) > new Date() ? (
                    <p className="mobile_auction_ends" style={{ color: dbTextColor }}>
                      {" "}
                      Opens {" "}
                      {moment(eventData?.start_date).format("MMMM Do, YYYY")}
                      {", "}
                      {moment(eventData?.start_date)
                        .tz("America/Los_Angeles")
                        .format("ha z")}
                      .{" "}
                    </p>
                  ) : (
                    <p className="mobile_auction_ends" style={{ color: dbTextColor }}>
                      {" "}
                      Ends On{" - "}
                      {moment(eventData?.end_date).format("MMMM Do, YYYY")}
                      {", "}
                      {moment(eventData?.end_date)
                        .tz("America/Los_Angeles")
                        .format("ha z")}
                      .{" "}
                    </p>
                  ) : ""}

                  <div className="info_buttons" style={{ color: dbTextColor }}>
                    <button onClick={handleEventOverview} style={{ borderBottom: `1px solid ${dbTextColor}`, color: dbTextColor }} className={`info_btn ${classes.info_btn_class}`} >{itemType} Overview</button>
                    <button onClick={handleCauseOverview} style={{ borderBottom: `1px solid ${dbTextColor}`, color: dbTextColor }} className={`info_btn ${classes.info_btn_class}`}>{itemType === 'marketplace' ? 'About This Donor' : 'About This Cause'}</button>
                  </div>
                </div>
              </div>
            }
          </div>
        }

        {
          (eventData?.status === "complete" && showDetailsOverBanner) &&
          <div>
            <p
              className={classes.completed_alert}
              style={{ color: mobileStyle.color[0] }}
            >
              This event is now closed.
            </p>
          </div>
        }
      </Mobile>

      <Desktop>
        <>
          <BrowseItemsBanner
            image={image}
            primaryColor={primaryColor}
            gradientClass="overlay_white_gradient"
            desktopImageStyle={{ height: "auto" }}
            params={eventData}
            showDetailsOverBanner={showDetailsOverBanner}
            skeltonStyle={{ height: itemType === 'marketplace' ? '50vh' : '85vh' }}
          >
            {
              showDetailsOverBanner &&
              <>
                {(itemType === 'store') && <h2 className={classes.desktop_store_heading} style={{ color: dbTextColor, borderBottom: `1px solid ${dbTextColor}` }}>{eventData?.causeDetails?.legal_name}</h2>}

                <div style={{
                  backgroundColor: dbBoxColor
                }}
                  className={eventData?.need_box === "1" && 'event_custom_box'}>
                  <div className="mobile_info_container " style={{ marginBlock: "0", width: '100%', gap: eventData?.need_box === "1" && '5px' }}>

                    {(itemType === 'event') && <TagHeading heading={eventData?.causeDetails.legal_name} style={{ color: dbTextColor, fontSize: '24px', height: 'auto' }} />}
                    <h1 style={{ color: dbTextColor, position: "relative" }} className="mobile_auction_title" >
                      {eventData?.title}
                      {eventData?.status === "complete" &&
                        <span
                          className={classes.desktop_event_complete_alert}
                          style={{
                            color: dbTextColor,
                          }}
                        >
                          This event is now closed.
                        </span>}
                    </h1>

                    {(itemType === 'event') ? new Date(eventData?.start_date) > new Date() ? (
                      <p className="mobile_auction_ends" style={{ color: dbTextColor }}>
                        {" "}
                        Opens {" "}
                        {moment(eventData?.start_date).format("MMMM Do, YYYY")}
                        {", "}
                        {moment(eventData?.start_date)
                          .tz("America/Los_Angeles")
                          .format("ha z")}
                        .{" "}
                      </p>
                    ) : (
                      <p className="mobile_auction_ends" style={{ color: dbTextColor }}>
                        {" "}
                        Ends On{" - "}
                        {moment(eventData?.end_date).format("MMMM Do, YYYY")}
                        {", "}
                        {moment(eventData?.end_date)
                          .tz("America/Los_Angeles")
                          .format("ha z")}
                        .{" "}
                      </p>
                    ) : ""}

                    <div className="info_buttons" style={{ color: dbTextColor }}>
                      <button onClick={handleEventOverview}
                        style={{
                          borderBottom: `1px solid ${dbTextColor}`,
                          color: dbTextColor,
                        }}
                        className={`info_btn ${classes.info_btn_class}`} >{itemType} Overview
                      </button>
                      <button onClick={handleCauseOverview}
                        style={{
                          borderBottom: `1px solid ${dbTextColor}`,
                          color: dbTextColor,
                        }}
                        className={`info_btn ${classes.info_btn_class}`}>{itemType === 'marketplace' ? 'About This Donor' : 'About This Cause'}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }
          </BrowseItemsBanner>
        </>
      </Desktop>
      {
        eventData?.ui === "flash_sale" && itemType === 'store' ? <div>
          <CountdownTimer endTime={eventData?.end_date} isStore={true} discountPercentage={eventData?.discount_percentage} />
        </div> : null
      }
    </>
  )
}

export default ESMBannerPart;