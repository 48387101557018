import { Menu, CancelOutlined } from "@mui/icons-material";
import constants from "../../../../../constants/en";
import { imageUrls } from "../../../../../constants/url";
import AccountIcon from '../../../../../images/accountIcon.png';
import shoppingBad from "../../../../../images/shoppingBag.png"
import { mobileStyle } from "../../../Home";
import classes from './HeaderV3.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMediaQuery } from "react-responsive";
import { Box, Paper } from "@mui/material";
import CartIcon from "../../CartIcon/CartIcon";
import { memo, useEffect, useState } from "react";
// import { Desktop, Mobile } from "../../../responsive/Responsive";
import { AnimatePresence, motion } from "framer-motion";
import SubHeader from "../../SubHeader/SubHeader";
import NewLogo from '../../../../../images/BGLogoComponent/newlogo.png';
import donorServices from "../../../../../services/donor-services";
import auctionServices from "../../../../../services/auction-services";
import { useDispatch, useSelector } from "react-redux";
import { fetchCausesHaveEvents } from "../../../../../redux/causeReducer";
import { getAuction } from "../../../../../redux/eventReducer";
import { clearAllFilter } from "../../../../../redux/shopNowFilterReducer";



const { APP_HEADER_COPIES: { pageLinks } } = constants
const { header_account_img, header_search_img } = imageUrls


const HeaderV3 = ({ isSubHeader, subHeaderDesktop = true, subHeaderTitle = '', primaryBackgroundColor = '#FC3300', position = 'relative', subHeaderOnclick, primaryColor }) => {
  const { color } = mobileStyle
  const navigate = useNavigate()
   const location = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false)
  const [allBrands, setAllBrands] = useState([])
  const [allCategories, setAllCategories] = useState([])
  const dispatch = useDispatch()
      const { auctions, isLoading } = useSelector(state => state.auction);    
  const { causes, loading } = useSelector(state => state.causesHaveEvents);
  const badgeStyle = {
    width: "19px",
    height: '19px',
    marginLeft: '13px',
    top: '28px',
    backgroundColor: mobileStyle.color[1],
    color: "#fff",
  }
  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }
  const getAllBrands = async () => {
    try {

      const { data } = await donorServices.featuredDonors()
      setAllBrands(data?.donor)

    } catch (error) {
      console.log(error);
    }
  }
  const getAllCategories = async () => {
    try {

      const data = await auctionServices.getAuctionCategoryList()// First true is for sending showEvery where query. This will check the status show_everywhere in custom_categories. Second true is for grouping custom category by name.
      setAllCategories(data?.data)


    } catch (error) {
      console.log(error);
    }
  }
  const handleMoveToFrvt = (account) => {
    navigate(`/account`, { state: { account } }
    )
    if (location.pathname === '/account'){

      window.location.reload();
    }
  }
  const navigateDonor = (donor) => {

    dispatch(clearAllFilter())
    navigate(`/shop-now`, { state: { donor } }
    )
    window.location.reload();
  }
  const handleCauseClick = async (cause) => {

    dispatch(clearAllFilter())
    navigate(`/shop-now`, { state: { cause } })
    window.location.reload();
  }
  const navigateToShopNow = (category) => {

    dispatch(clearAllFilter())
    navigate(`/shop-now`, { state: { category } }
    )
    window.location.reload();
  }

  const navigateToCampaign = (campaign) => {
    navigate(`/store/${campaign.custom_url}`);
    window.location.reload();
  }
  

  const isDesktop = useMediaQuery({
    minWidth: 768
  })
  const isMobile = useMediaQuery({
    maxWidth: 843
  })

  const [shopOpen, setShopOpen] = useState(false);
  const [partnerOpen, setPartnerOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  useEffect(() => {
    getAllCategories()
    getAllBrands()
    dispatch(fetchCausesHaveEvents({ page: 1, limit: 100, from: "shop-now" }))


  }, [])


      useEffect(() => {
        if (auctions?.total_page === 0) {
          dispatch(getAuction({ page: 1, limit: 20, cause_category_id: null }));
        }
      }, [dispatch, auctions?.total_page]);
  return (
    <div style={{ position: position }} className={classes.sectionContainer}>

      <section className={classes.headerSection}>
        <div className={classes.headerContainer} >
          <div className={classes.headerlogo}>
            {/* <Link to={'/'} className={classes.logoLink} ><BGLogoComponent type={'large'} color={color[0]} /></Link> */}
            <Link to={'/'} className={classes.logoLink} ><img src={NewLogo} alt="Logo" /></Link>

            {!isMobile &&
              <div className={classes.linksContainer} >
                {/* {
                pageLinks.map((item) =>
                  <Link key={item.link} to={item.link} className={classes.textLinks} >{item.label}</Link>
                )
              } */}
                <Box sx={{ display: "flex", gap: "20px" }}>
                  {/* SHOP MENU */}
                  <Box sx={{ position: "", display: "inline-block" }}>
                    <Box
                      onMouseEnter={() => setShopOpen(true)}
                      onMouseLeave={() => setShopOpen(false)}
                      // component={Link}
                      // to="/shop-now"
                      sx={{
                        cursor: "pointer",
                        fontSize: "21px",
                        fontWeight: "400",
                        color: "black",
                        textDecoration: "none",
                        padding: "0px",
                        borderBottom: "2px solid transparent",
                        "&:hover": { color: "#FF0000", borderBottom: "2px solid #ff0000" },
                      }}
                    >
                      Shop
                    </Box>

                    {/* SHOP DROPDOWN */}
                    {shopOpen && (
                      <Box
                        onMouseEnter={() => setShopOpen(true)}
                        onMouseLeave={() => setShopOpen(false)}
                        sx={{
                          position: "absolute",
                          top: "71%",
                          right: '0',
                          left: "-20px",
                          backgroundColor: "white",
                          borderRadius: "4px",
                          padding: "16px",
                          zIndex: 9999,
                        }}
                      >
                        <Paper sx={{ padding: "16px", width: "100%", boxShadow: '0' }}>
                          <Box sx={{ display: "flex", gap: 4, flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            {/* CATEGORIES COLUMN */}
                            <Box>
                              <Box sx={{
                                cursor: 'pointer', fontWeight: "bold", marginBottom: "20px", borderBottom: '2px solid #000', display: 'inline-block', "&:hover": {
                                  color: "#ff0000", borderBottom: '2px solid #FF0000'
                                },
                              }}>General</Box>
                              {allCategories.map((item) => (
                                <Box key={item} sx={{
                                  cursor: "pointer", fontSize: '14px', color: '#8f8f8f', transition: "color 0.3s ease",
                                  "&:hover": {
                                    color: "#ff0000", // Change color on hover
                                  },
                                }}
                                
                                onClick={() => navigateToShopNow(item)}
                                >
                                  {item.name}
                                </Box>
                              ))}
                            </Box>
                            {/* BRANDS COLUMN */}
                            <Box>
                              <Box sx={{
                                cursor: 'pointer', fontWeight: "bold", marginBottom: "20px", borderBottom: '2px solid #000', display: 'inline-block', "&:hover": {
                                  color: "#ff0000", borderBottom: '2px solid #FF0000'
                                },
                              }}>Brands</Box>
                              {allBrands.map((item) => (
                                <Box key={item} sx={{
                                  cursor: "pointer", fontSize: '14px', color: '#8f8f8f', transition: "color 0.3s ease",
                                  "&:hover": {
                                    color: "#ff0000", // Change color on hover
                                  },
                                }}

                                  onClick={() => navigateDonor(item)}
                                >
                                  {item.legal_name}
                                </Box>
                              ))}
                            </Box>
                            {/* 
                           
                            {/* CAMPAIGNS COLUMN */}
                            <Box>
                              <Box sx={{
                                cursor: 'pointer', fontWeight: "bold", marginBottom: "20px", borderBottom: '2px solid #000', display: 'inline-block',
                                "&:hover": {
                                  color: "#ff0000", borderBottom: '2px solid #FF0000'
                                },
                              }}>Campaigns</Box>
                              {auctions.data
                                .filter(item => item.ui === 'flash_sale')
                                .map((item) => (

                                  <Box key={item} sx={{
                                    cursor: "pointer", fontSize: '14px', color: '#8f8f8f', transition: "color 0.3s ease",
                                    "&:hover": {
                                      color: "#ff0000", // Change color on hover
                                    },
                                  }}
                                    onClick={() => navigateToCampaign(item)}
                                  >
                                    {item.title}
                                  </Box>
                              ))}
                            </Box>

                            {/* CAUSES COLUMN */}
                            <Box>
                              <Box sx={{
                                cursor: 'pointer', fontWeight: "bold", marginBottom: "20px", borderBottom: '2px solid #000', display: 'inline-block',
                                "&:hover": {
                                  color: "#ff0000", borderBottom: '2px solid #FF0000'
                                },
                              }}>Causes</Box>
                              {causes.map((item) => (
                                <Box key={item} sx={{
                                  cursor: "pointer", fontSize: '14px', color: '#8f8f8f', transition: "color 0.3s ease",
                                  "&:hover": {
                                    color: "#ff0000",
                                  },
                                }}
                                  onClick={() => handleCauseClick(item)}
                                >
                                  {item.legal_name}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    )}
                  </Box>

                  {/* PARTNER WITH US MENU */}
                  <Box sx={{ position: "", display: "inline-block" }}>
                    <Box
                      onMouseEnter={() => setPartnerOpen(true)}
                      onMouseLeave={() => setPartnerOpen(false)}
                      component={Link}
                      to="/brand-partner"
                      sx={{
                        cursor: "pointer",
                        fontSize: "21px",
                        fontWeight: "400",
                        color: "black",
                        textDecoration: "none",
                        padding: "0px",
                        borderBottom: "2px solid transparent",
                        "&:hover": { color: "#FF0000", borderBottom: "2px solid #ff0000" },
                      }}
                    >
                      Partner With Us
                    </Box>

                    {/* PARTNER WITH US DROPDOWN */}
                    {partnerOpen && (
                      <Box
                        onMouseEnter={() => setPartnerOpen(true)}
                        onMouseLeave={() => setPartnerOpen(false)}
                        sx={{
                          position: "absolute",
                          top: "71%",
                          left: '30px',
                          right: '0',
                          backgroundColor: "white",
                          borderRadius: "4px",
                          padding: "16px",
                          width: "300px",
                          zIndex: 9999,
                        }}
                      >
                        <Paper sx={{ padding: "16px", width: "100%", boxShadow: '0' }}>
                          <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box component={Link}
                              to="/browse-brands" sx={{
                                cursor: 'pointer', fontWeight: "bold", borderBottom: '2px solid #000', display: 'inline-block', borderBottom: "2px solid transparent",
                                "&:hover": { color: "#FF0000", borderBottom: "2px solid #ff0000" },
                              }}>For Brands</Box>
                            <Box component={Link}
                              to="/browse-stores" sx={{
                                cursor: 'pointer', fontWeight: "bold", borderBottom: '2px solid #000', display: 'inline-block', borderBottom: "2px solid transparent",
                                "&:hover": { color: "#FF0000", borderBottom: "2px solid #ff0000" },
                              }}>For Non Profits</Box>
                          </Box>
                        </Paper>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  component={Link}
                  to="/about"
                  sx={{
                    cursor: "pointer",
                    fontSize: "21px",
                    fontWeight: "400",
                    color: "black",
                    textDecoration: "none",
                    padding: "0px",
                    borderBottom: "2px solid transparent",
                    "&:hover": { color: "#FF0000", borderBottom: "2px solid #ff0000" },
                  }}
                >
                  About
                </Box>
              </div>
            }
          </div>
          <div className={classes.iconLinks} >
            <Link to={'/search'} > <img src={header_search_img} className={classes.search_icon} alt="header search" /> </Link>
            <Link to={'/account'}><img src={AccountIcon} className={classes.account_icon} alt="header account" /></Link>
            <img onClick={()=>handleMoveToFrvt('frvt')} style={{cursor:'pointer'}} src={shoppingBad} alt="fvrt" className={classes.bag_icon} />
            <CartIcon badgeStyle={badgeStyle} fontSize={'1.5rem'} iconClassName={classes.cart_icon} color={color[1]} />
            {/* <Link to={'/'}><img src={shoppingBad} alt="fvrt" className={classes.bag_icon} /></Link>
            <CartIcon badgeStyle={badgeStyle} fontSize={'24px'} iconClassName={classes.cart_icon} color={color[1]} />
            <ShoppingCartIcon badgeStyle={badgeStyle} style={{ fontSize: '25px', marginTop: '4px' }} iconClassName={classes.cart_icon} color={color[1]} /> */}


            {/* <FavouriteIcon badgeStyle={badgeStyle} fontSize={'1.5rem'} color={color[1]} /> */}
            {/* {
              !isMobile && <Link to={'/shop-now'} ><NewButton text={'SHOP NOW'} className="headerShopNow" /></Link>
            } */}
            {
              isMobile && <Menu onClick={handleMobileMenu} className={`${classes.icon} ${classes.menuIcon}`} />
            }
          </div>
        </div>

        <AnimatePresence>
          {
            mobileMenu ?
              <motion.div transition={{ duration: 0.3 }} initial={{ height: 0 }} animate={{ height: "100vh" }} exit={{ height: 0 }} className={`${classes.mobileNavLinksAnimater}`}>
                <div className={classes.openHeaderTop} ><CancelOutlined onClick={handleMobileMenu} className={classes.icon} /></div>
                <div className={classes.mobileLinksContainer}>
                  {
                    pageLinks.map((item) =>
                      <Link key={item.link} to={item.link} className={classes.mobileLinks} style={{ opacity: mobileMenu ? 1 : 0 }} >{item.label}</Link>
                    )
                  }
                  <Link to={'/shop-now'} className={classes.mobileLinks} >Shop Now</Link>
                </div>
              </motion.div>
              : null
          }
        </AnimatePresence>

      </section>
      <section className={classes.subHeaderSection}>
        {(((isDesktop && subHeaderDesktop) || !isDesktop) && (isSubHeader || subHeaderTitle !== '') && subHeaderTitle !== 'Account' && subHeaderTitle !== 'Cart') && <SubHeader title={subHeaderTitle} onClick={subHeaderOnclick} backgroundColor={primaryBackgroundColor} color={primaryColor} />}
      </section>
    </div>
  )
}

export default memo(HeaderV3);