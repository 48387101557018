import { useEffect, useState } from "react"
import userServices from "../../../../services/user-services"
import { useMediaQuery } from "react-responsive"
import { mobileStyle } from "../../Home" 
import { getDisplayPrice } from "../../../../utils/displayPrice"
import { Alert } from "bootstrap"
import LocationMarker from "../../../../images/locationMarker.svg"
import Image from "../../atoms/Image/Image"
import { ChevronLeft } from "@mui/icons-material";



const calculateStatus = (status) => {
  switch (status) {
    case "1":
      return "0"
    case "9":
      return "1"
    case "6":
      return "2"
    case "12":
      return "3"
    case "7":
      return "4"
    case "8":
      return "5"
    case "14":
      return "5"
    case "15":
      return "100"
    default:
      return "0"
  }
}
const primaryBackgroundColor = mobileStyle.backgroundColor[2]
const primaryColor = mobileStyle.color[1]
function OrderCard({ img, title, quantity, name, price, orderDate, orderID, orderStatus }) {
  const [imageLoading, setImageLoading] = useState(true)
  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const formattedDate = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(new Date(orderDate));

  return (
    <div>

      <div className='purchase-history mt-2'>
        <div>
          <p className='order-number'>ORDER #{orderID}</p>
        </div>
        <div className='history-data'>
          <div className='brand-name'>
            <div className='brand-image'>
              {/* <img src={BrandImage} /> */}
              <Image
                className="cart-card-img"
                src={img}
                style={{ display: imageLoading ? "none" : 'block' }}
                onLoad={() => setImageLoading(false)}
              />
            </div>
            <div className='brand-details'>
              <h5>{title}</h5>
              <p>{name}</p>
              <h5>TOTAL: ${price}</h5>
            </div>
          </div>
          <div className='brand-status'>
            <p>{formattedDate}</p>
            <h4>{orderStatus}</h4>

            <h4 style={{color:'#8F8F8F' ,fontSize:'16px'}}>{'Qty: ' + quantity}</h4>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'right', fontSize: '15px', fontWeight: 'bold', marginTop: '10px', textDecoration: 'underline' }}>


      </div>
    </div>
  );
}
const TrackMyOrder = (props) => {
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const [order, setOrder] = useState(null)
  const [orderStaus, setOrderStatus] = useState()

  useEffect(() => {
    console.log(props.orderId);

    (async () => {
      const data = await userServices.getUserOrderStatus(props.orderId)

      console.log(data);

      setOrder(data)
      setOrderStatus(calculateStatus(data?.status))
    })()
  }, [])


  return (
    <>
      <div className='purchase-container purchase-container__history' style={{ height: '75vh' }}>
        <h1>Track Order</h1>

        <p style={{ cursor: 'pointer', fontSize: '16px', fontFamily: 'DM Mono' }} onClick={() => props.setValue(0)}>
        <ChevronLeft sx={{color: '#FF0000', fontSize: '32px'}} /> Back
        </p>


        {order ? (
          <div style={{ height: '100%' }} >

            <div style={{ paddingTop: isMobile ? '200px' : '10px', }} className='track-order-container'>
              <div className='item-container'>

                <div className='order-item-contianer'>
                  {order?.user_order_auction_item_xrefs?.map((i) => {
                    return <OrderCard
                      key={i.auction_item_id}
                      img={i.preview_image.find(img => (img.context_type === 'thumbnail' || img.context_type === 'portrait'))?.name}
                      title={i.auction_item?.auction.title}
                      name={i.auction_item?.name}
                      quantity={i.quantity_bought}
                      price={
                        i?.auction_item?.store_item_id
                          ? getDisplayPrice(i?.auction_item)
                          : i?.auction_item?.buy_now_price
                            ? i?.auction_item?.buy_now_price
                            : i?.auction_item?.price}
                      orderStatus={order.status === '1' ? 'Received' : order.status === '9' ? 'Preparing' : order.status === '6' ? 'Shipped' : order.status === '12' ? 'On its way' : order.status === '7' ? 'Out for delivery' : order.status === "14" ? "Order Returned" : "Delivered"}
                      orderDate={order.created}
                      orderID={props.orderId}

                    />
                  })}
                </div>

              </div>
              <div>


                {
                  (orderStaus === "100" ?
                    <Alert style={{ margin: "20px 0" }} severity="error" >Address Check Failed</Alert>
                    : (
                      <>
                        <div className='track-order-progess'>
                          <div style={{ zIndex: 0 }} className={`track-order-line track-order-${orderStaus}`}>
                            <div className='track-order-dot'></div>
                            <div className='track-order-dot'></div>
                            <div className='track-order-dot'></div>
                            <div className='track-order-dot'></div>
                            <div className='track-order-dot'></div>
                            <div className='track-order-dot'></div>
                          </div>
                          <div className={`track-order-status track-order-${orderStaus}`}>
                            <div className='track-order-header'>Order Received</div>
                            <div className='track-order-header'>Preparing your order</div>
                            <div className='track-order-header'>Order Shipped</div>
                            <div className='track-order-header'>On its way</div>
                            <div className='track-order-header'>Out for delivery</div>
                            <div className='track-order-header'>
                              {order.status === "14" ? "Order Returned" : "Delivered"}<br />
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                }
                {/* {order.shipping_detail && <div className='track-order-del'>
                  <div style={{ fontSize: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>Delivery Address <img style={{ marginLeft: "7px" }} src={LocationMarker} alt="" /> </div>
                  <div style={{ fontSize: "14px", marginTop: "10px" }}>
                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.name}</p>
                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.address1}</p>
                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.city}</p>
                    <p style={{ margin: 0, textAlign: "left" }}>{order.shipping_detail.state}, {order.shipping_detail.zip}</p>
                  </div>
                </div>} */}
              </div>

            </div>
          </div>
        ) : <></>}

      </div>

    </>
  )
}

export default TrackMyOrder