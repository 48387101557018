import React, { useState } from 'react';
import { mobileStyle } from '../../Home';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider } from '@mui/material';
import NewButton from '../../atoms/NewButton/NewButton';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { changePageNumber, clearAttribute, clearCategoryDetails, clearSearchText, setSelectedCategory } from '../../../../redux/itemsReducer';
import { BorderAll, ExpandMore } from "@mui/icons-material";
import { AnimatePresence, motion } from 'framer-motion';
import NewFilterButton from '../../atoms/NewButton/NewFilterButton';

const FilterScreen = ({
    sortBy,
    // handleSorting=()=>null,
    setSortBy,
    // sortByRef,
    groupBy,
    // handleGrouping=()=>null,
    setGroupBy,
    // groupByRef,
    itemType = '',
    priceRange = [],
    handlePriceChange = () => null,
    handleFilterApply = () => null,
    toggleClearPage = () => null,
    filterState = '',
    eventCategories = [],
    eventCustomCategories = [],
    onCategoryClick = () => null,
    handelAllClicked = () => null,
    handleClose
}) => {
    
    const isDesktop = useMediaQuery({
        minWidth: 768
    })
    const clearFilters = () => {
        window.location.reload()
    }
    const { pageNumber: page, customCategoryHeadingId, selectedCategory, customCategoryHeading, categoryName } = useSelector(state => state.items)
    const [groupByChange, setGroupByChange] = useState(false)///to detect if there is any change in groupBy value
    const [categoryObject, setCategoryObject] = useState({
        category_id: selectedCategory,
        category_name: categoryName,
        custom_category_heading_id: customCategoryHeadingId,
        custom_category_heading: customCategoryHeading,
        choosed_category_type: customCategoryHeading === 'All' ? customCategoryHeading : '',
        more_clicked: ''
    })

    const handleSorting = (e) => { 
        setSortBy(e.target.value) 
        // console.log("This is sorting value: " + e.target.value);
    };
    const dispatch = useDispatch()
    const handleGrouping = (e) => {
        if (e.target.value1 === groupBy) {
            setGroupByChange(false)
        }
        else {
            setGroupByChange(true)
        }
        setGroupBy(e.target.value)
    };
    const handleApplyFilterClick = () => {
        dispatch(clearCategoryDetails())
        dispatch((clearSearchText()))
        if (page !== 1 && groupBy !== '' && groupByChange) {
            toggleClearPage();
        }
        else if (page === 1 && groupBy !== '') {
            handleFilterApply()
        }
        else {
            handleFilterApply()
        }
    }
    const handleCustomHeadingRadioChange = (event, headingName) => {
        if (!event.target?.value) return
        setCategoryObject({
            // custom_category_heading_id: event.target?.value,
            ...categoryObject,
            custom_category_heading: headingName,
            category_id: null,
            category_name: '',
            // choosed_category_type: 'from-custom-category'
        })
    }
    const handleCategoryRadioChange = (event, categoryName, from = '', moreHeading='') => {
        if (!event.target?.value) return
        if (from === 'from-custom-category') {
            setCategoryObject({
                ...categoryObject,
                custom_category_heading:moreHeading,
                category_id: event.target?.value,
                category_name: categoryName,
                choosed_category_type: from,
                more_clicked:moreHeading
            })
        }
        else {
            setCategoryObject({
                custom_category_heading_id: null,
                custom_category_heading: moreHeading,
                category_id: event.target?.value,
                category_name: categoryName,
                choosed_category_type: from,
                more_clicked: moreHeading
            })
        }
    }

    const onAllClicked = () => {
        setCategoryObject({
            custom_category_heading_id: null,
            custom_category_heading: 'All',
            category_id: null,
            category_name: '',
            choosed_category_type: 'All'
        })
    }

    const handleApplySortByClick = () => {
        dispatch((clearSearchText()))
        dispatch(changePageNumber(1))
        dispatch(clearAttribute({ key: 'customCategoryHeading', value: categoryObject.more_clicked }))
        switch (categoryObject?.choosed_category_type) {
            case 'from-custom-category':
                if(!categoryObject.category_id) return;
                dispatch(setSelectedCategory({ name: categoryObject.category_name, id: categoryObject.category_id, categoryType: 'from-custom-category', customCategoryHeading: categoryObject.custom_category_heading, customCategoryHeadingId: categoryObject.custom_category_heading_id }))
                onCategoryClick(categoryObject.category_id, categoryObject?.choosed_category_type)
                break;
                case 'from-category':
                if(!categoryObject.category_id) return;
                dispatch(setSelectedCategory({ name: categoryObject.category_name, id: categoryObject.category_id, categoryType: 'from-category', customCategoryHeading: '', customCategoryHeadingId: null }))
                dispatch(clearAttribute({ key: 'customCategoryHeadingId', value: null }))
                onCategoryClick(categoryObject.category_id, categoryObject?.choosed_category_type)
                break;
            case 'All':
                handelAllClicked()
                break;
            default:
                handelAllClicked()
        }
    }

    const getCategoryData = (item) => {
        if(item?.custom_category) {
            return {
                id: item?.custom_category?.id,
                name: item?.custom_category?.category_name,
                from: 'from-custom-category'
            }
        }
        else {
            return {
                id: item?.category?.id,
                name: item?.category?.name,
                from: 'from-category'
            }
        }
    }

    return (
        <div className="filter-screen">
            <div className="filter-screen-top margin_class">
                {
                    filterState === 'filterby' ?
                        <div className="mobile_filter_header">
                            <h5 style={{ color: mobileStyle.color[1] }} className="filters_heading" >FILTER</h5>
                            {/* <h5 onClick={clearFilters} style={{ color: mobileStyle.color[0] }} className="reset_button">Reset</h5> */}
                            <button onClick={handleClose} style={{ marginBottom:'8px'}}>
                                <svg width="20" height="20" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7324 1L1.73242 13M1.73242 1L13.7324 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                        :
                        <div className="mobile_filter_header">
                            <h5 style={{ color: mobileStyle.color[1] }} className="filters_heading" >SORT</h5>
                            {/* <NewButton
                                text={'APPLY'}
                                onClick={() => handleApplySortByClick()}
                                color={mobileStyle.color[0]}
                                backgroundColor={mobileStyle.backgroundColor[4]}
                                style={{
                                    width: 'fit-content',
                                    fontSize: '16px',
                                    lineHeight: '16px',
                                    paddingBlock: '7px'
                                }}
                            /> */}
                            <button onClick={handleClose} style={{ marginBottom:'8px'}}>
                                <svg width="20" height="20" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.7324 1L1.73242 13M1.73242 1L13.7324 13" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </div>
                }
                <section className="filter-control-wrapper">
                    {
                        filterState === 'filterby' ?

                            <>
                                {/* <div className="filter-control-1">
                                    <div className="sort-by">
                                        <FormControl>
                                            <FormLabel sx={{ color: mobileStyle.color[1], marginTop: '20px' }} className="filter-control-heading">
                                                Sort By
                                            </FormLabel>
                                            <RadioGroup
                                                value={sortBy}
                                                onChange={handleSorting}
                                                // ref={sortByRef}
                                                sx={{ gap: '7px' }}
                                            >
                                                <FormControlLabel
                                                    value="Recommended"
                                                    control={<Radio />}
                                                    label="Recommended"
                                                />
                                                <FormControlLabel
                                                    value="RecentlyAdded"
                                                    control={<Radio />}
                                                    label="Recently Added"
                                                />
                                                <FormControlLabel
                                                    value="priceLow2High"
                                                    control={<Radio />}
                                                    label="Price: Low to high"
                                                />
                                                <FormControlLabel
                                                    value="priceHigh2Low"
                                                    control={<Radio />}
                                                    label="Price: High to low"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div> */}
                                <div className="filter-control-2">
                                    <FormControl>
                                        {/* <FormLabel sx={{ color: mobileStyle.color[1] }} className="filter-control-heading">
                                            Group By
                                        </FormLabel> */}
                                        <RadioGroup
                                            value={groupBy}
                                            onChange={handleGrouping}
                                            // ref={groupByRef}
                                            sx={{ gap: '7px' }}
                                        >
                                            {(itemType !== 'event-category' && itemType !== 'event-custom-category' && itemType !== 'browse-category') &&
                                                <FormControlLabel
                                                    value="itemCategory"
                                                    control={<Radio />}
                                                    label="Item Category"
                                                />}
                                            {(itemType !== 'cause-category') &&
                                                <FormControlLabel
                                                    value="causeType"
                                                    control={<Radio />}
                                                    label="Cause Type"
                                                />}
                                            <FormControlLabel     /// if itemType is event-auction then value is auction-byNow. If itemType is marketplace or store or event-category or 'event-custom-category' or browse-category (These types show both auction and store items together) then value is market-store-buyNow. Else the value is just buyNow which is normal events store item.
                                                // value={itemType ==="event-auction" ? 'auction-buyNow' : (itemType === 'marketplace' || itemType === 'store' || itemType === 'event-category' || itemType === 'event-custom-category' || itemType === 'browse-category') ? 'market-store-buyNow' : "buyNow"}
                                                value={itemType === "event-auction" ? 'auction-buyNow' : (['marketplace', 'store', 'event-category', 'event-custom-category', 'browse-category', 'cause-category'].includes(itemType)) ? 'market-store-buyNow' : "buyNow"}
                                                control={<Radio />}
                                                label="Buy Now"
                                            />
                                            {(itemType === 'buyNow' || itemType === 'event-byNow' || itemType === 'store') ? null :
                                                <FormControlLabel
                                                    value='auction'
                                                    control={<Radio />}
                                                    label="Auction"
                                                />}
                                            { itemType !== 'event' && <FormControlLabel
                                                value='event'
                                                control={<Radio />}
                                                label="Event"
                                            />}
                                        </RadioGroup>
                                        
                                    </FormControl>
                                </div>
                            </>
                            :
                            <div className='sort_by_main'>
                                <div className="filter-control-1">
                                    <AnimatePresence>
                                    <motion.div transition={{ duration: 0.2 }} initial={{ maxHeight: 400 }} animate={{ maxHeight: "auto" }} exit={{ maxHeight: 400 }} style={{overflowY:'auto',width:'100%'}}>
                                        <FormControl style={{width:'100%'}}>

                                            {/* custom categories */}

                                            <RadioGroup
                                                value={sortBy}
                                                onChange={handleSorting}
                                                // ref={sortByRef}
                                                sx={{ gap: '7px' }}
                                            >
                                                    <div style={{ fontWeight: '600' }}>
                                                        <FormControlLabel
                                                            value="Recommended"
                                                            control={<Radio />}
                                                            label="RELEVANCE"
                                                        />
                                                    </div>
                                                    <div style={{ backgroundColor: '#30313A29', height: '1px' }}></div>
                                                    <span style={{ opacity: '40%', fontWeight: '600' }}>PRICE</span>
                                                    <div style={{ fontWeight: '600' }}>
                                                        <FormControlLabel
                                                            value="priceLow2High"
                                                            control={<Radio />}
                                                            label="LOWEST FIRST"
                                                        />
                                                    </div>
                                                    <div style={{ fontWeight: '600' }}>
                                                        <FormControlLabel
                                                            value="priceHigh2Low"
                                                            control={<Radio />}
                                                            label="HIGHEST FIRST"
                                                        />
                                                    </div>
                                                    <div style={{ backgroundColor: '#30313A29', height: '1px' }}></div>
                                                    <span style={{ opacity: '40%', fontWeight: '600' }}>DATE</span>
                                                    <div style={{ fontWeight: '600' }}>
                                                        <FormControlLabel
                                                            value="RecentlyAdded"
                                                            control={<Radio />}
                                                            label="Recently Added"
                                                        />
                                                    </div>
                                                
                                            </RadioGroup>

                                                <NewFilterButton
                                                    text={'APPLY'}
                                                    onClick={() => handleApplyFilterClick()}
                                                    color={mobileStyle.color[0]}
                                                    backgroundColor={mobileStyle.backgroundColor[4]}
                                                    style={{
                                                        width:'100%',
                                                        fontSize: '16px',
                                                        backgroundColor:'#30313A',
                                                        color:'white',
                                                        paddingBlock: '7px',
                                                        marginTop: '30px',
                                                    }}
                                                />
                                            
                                            {/* <RadioGroup
                                                sx={{ gap: '7px' }}
                                            >
                                                <FormControlLabel
                                                    // value="All"
                                                    checked={(categoryObject.choosed_category_type === 'All')}
                                                    onChange={onAllClicked}
                                                    control={<Radio />}
                                                    label="All"
                                                /> */}
                                                {/* {
                                                    eventCustomCategories?.map((item, index) => {
                                                        return (
                                                            <span>
                                                                <FormControlLabel
                                                                    sx={{ marginRight: "5px" }}
                                                                    key={index}
                                                                    value={item?.id}
                                                                    checked={categoryObject.custom_category_heading_id == item?.id}
                                                                    control={<Radio />}
                                                                    label={item?.name ? item?.name : 'More'}
                                                                    onChange={(event) => handleCustomHeadingRadioChange(event, item?.name ? item?.name : 'More')}
                                                                />
                                                                <ExpandMore sx={{ transform: (categoryObject.custom_category_heading_id == item?.id) && 'rotate(180deg)', transition: 'transform 1s' }} />
                                                                <RadioGroup
                                                                    sx={{ gap: '6px', marginTop: '6px', marginLeft: '20px' }}
                                                                >
                                                                    {
                                                                        (categoryObject.custom_category_heading_id == item?.id && item?.auction_custom_categories?.length) && item?.auction_custom_categories?.map((obj) => {
                                                                            return (
                                                                                <AnimatePresence>
                                                                                    <motion.div transition={{ duration: 0.15 }} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }}>
                                                                                    <FormControlLabel
                                                                                        key={obj?.id}
                                                                                        value={obj?.id}
                                                                                        control={<Radio />}
                                                                                        label={obj?.category_name}
                                                                                        checked={categoryObject.category_id == obj?.id}
                                                                                        onChange={(event) => handleCategoryRadioChange(event, obj?.category_name, 'from-custom-category')}
                                                                                    />
                                                                                    </motion.div>
                                                                                </AnimatePresence>
                                                                            )
                                                                        })
                                                                    }
                                                                </RadioGroup>
                                                            </span>
                                                        )
                                                    })
                                                } */}
                                                {/* {
                                                    eventCategories?.length ?
                                                        eventCategories?.map((item, index) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={item?.category?.id}
                                                                    value={item?.category?.id}
                                                                    control={<Radio />}
                                                                    label={item?.category?.name}
                                                                    checked={categoryObject.category_id == item?.category?.id}
                                                                    onChange={(event) => handleCategoryRadioChange(event, item?.category?.name, 'from-category')}
                                                                />
                                                            )
                                                        })
                                                        : null
                                                } */}

                                                {/* {
                                                    eventCategories?.length ?
                                                        eventCategories?.map((item, index) => {
                                                            return (
                                                                <FormControlLabel
                                                                    key={index}
                                                                    value={getCategoryData(item).id}
                                                                    control={<Radio />}
                                                                    label={getCategoryData(item).name}
                                                                    checked={categoryObject.category_id == getCategoryData(item).id}
                                                                    onChange={(event) => handleCategoryRadioChange(event, getCategoryData(item).name, getCategoryData(item).from)}
                                                                />
                                                            )
                                                        })
                                                        : null
                                                } */}
                                                 {/* {
                                                    eventCategories?.length ?
                                                             <span>
                                                                <FormControlLabel
                                                                    sx={{ marginRight: "5px" }}
                                                                    value={'More'}
                                                                    checked={categoryObject.custom_category_heading == 'More'}
                                                                    control={<Radio />}
                                                                    label={'More'}
                                                                    onChange={(event) => handleCustomHeadingRadioChange(event, 'More')}
                                                                />
                                                                <ExpandMore sx={{ transform: (categoryObject.custom_category_heading == 'More') && 'rotate(180deg)', transition: 'transform 1s' }} />
                                                                {
                                                                  (categoryObject.custom_category_heading == 'More') &&
                                                                  <RadioGroup
                                                                    sx={{ gap: '6px', marginTop: '6px', marginLeft: '20px' }}
                                                                >
                                                                    {
                                                                        eventCategories?.slice(7,eventCategories?.length)?.map((item, index) => {
                                                                            return (
                                                                                <AnimatePresence key={index}>
                                                                                    <motion.div transition={{ duration: 0.4 }} initial={{ height: 0 }} animate={{ height: "auto" }} exit={{ height: 0 }}>
                                                                                    <FormControlLabel
                                                                                        value={getCategoryData(item)?.id}
                                                                                        control={<Radio />}
                                                                                        label={getCategoryData(item)?.name}
                                                                                        checked={categoryObject.category_id == getCategoryData(item)?.id}
                                                                                        onChange={(event) => handleCategoryRadioChange(event, getCategoryData(item)?.name, getCategoryData(item)?.from, 'More')}
                                                                                    />
                                                                                    </motion.div>
                                                                                </AnimatePresence>
                                                                            )
                                                                        })
                                                                    }
                                                                </RadioGroup>
                                                                }
                                                            </span>
                                                        :null
                                                 } */}
                                                
                                            {/* </RadioGroup> */}
                                        </FormControl>
                                    </motion.div>
                                    </AnimatePresence>
                                </div>
                            </div>
                    }
                    {/* <div className="filter-control-3">
                        <FormLabel sx={{ color: mobileStyle.color[1] }} className="filter-control-heading price-range">
                            Price Range
                        </FormLabel>
                        <div className="price-slider-wrapper">
                            <Slider
                                value={priceRange}
                                onChange={handlePriceChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={25000}
                                step={25}
                            />
                        </div>
                        <div className="slider-labels">
                            <span className="slider-label-item">${priceRange[0]}</span>
                            <span className="slider-label-item">${priceRange[1]}</span>
                        </div>
                    </div> */}
                </section>
                <br />
                <section className="filter-apply-wrapper">
                    {/* <Desktop>
            <button onClick={() => handleFilterApply()} className="apply-filter-btn">
              Apply
            </button>
            </Desktop> */}
                    {/* <Mobile> */}
                    {filterState === 'filterby' && <div style={{display:'flex', flexDirection:'column', alignItems:'center', gap:'10px'}}>
                        <NewFilterButton
                            text={'VIEW PRODUCTS'}
                            onClick={() => handleApplyFilterClick()}
                            color={mobileStyle.color[0]}
                            backgroundColor={mobileStyle.backgroundColor[4]}
                            style={{
                                width: '100%',
                                fontSize: '16px',
                                backgroundColor: '#30313A',
                                color: 'white',
                                paddingBlock: '7px',
                                marginTop: '30px',
                            }}
                        />
                        <h5 onClick={clearFilters} style={{ fontSize:'14px', fontWeight:"600" }} className="reset_button">CLEAR ALL</h5>
                        </div>
                    }
                    {/* </Mobile> */}
                </section>
            </div>
        </div>
    )
}

export default FilterScreen