import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import newWhiteLogo from "../../images/newHomeImages/whiteLogo.svg"
import { validEmail, validPassword, validName } from "../../utils/reg-ex.js";
import authService from "../../services/auth-services";
import logger from "../../utils/logger";
import { Helmet } from "react-helmet-async";
import { Mobile, Desktop } from "../components/responsive/Responsive";
import style from '../desktop/styles/register.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { config } from "../../config/config";
import "./register.css"
import DialogMui from "../components/molecules/Dialog/Dialog";
import HeaderV3 from "../components/molecules/Header/HeaderV3/HeaderV3.jsx";
import signuplogo from "../../images/sign-up-logo.png";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameErr, setLastNameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [password, setPassword] = useState("");
  const [pwdErr, setPwdErr] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [agreeToTAC, setAgreeToTAC] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [subscribeEmail, setSubscribeEmail] = useState(true);
  const { state } = useLocation()
  let navigate = useNavigate();
  const REACT_APP_URL = config.REACT_APP_URL;

  // handle first name
  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    if (validName.test(e.target.value)) {
      setFirstNameErr(false);
    } else {
      setFirstNameErr(true);
    }
  };

  // handle last name
  const handleLastName = (e) => {
    setLastName(e.target.value);
    if (validName.test(e.target.value)) {
      setLastNameErr(false);
    } else {
      setLastNameErr(true);
    }
  };

  // handle email
  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (validEmail.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  };

  // handle password
  const handlePassword = (e) => {
    setPassword(e.target.value);
    if (validPassword.test(e.target.value)) {
      setPwdErr(false);
    } else {
      setPwdErr(true);
    }
  };

  // Submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMsg(null)

    if (firstName && lastName && email && password && !emailErr && !pwdErr && !firstNameErr && !lastNameErr && agreeToTAC) {
      try {
        setIsLoading(true)
        await authService
          .signup(firstName, lastName, email, password, subscribeEmail)
          .then(
            (response) => {
              // if (response?.data?.role !== "Admin") {
              localStorage.setItem("token", response.token);
              localStorage.setItem("username", email);
              // navigate("/");
              // if(state && state.redirect) {
              //   window.location.replace(state.redirect)
              // } else {
              //   window.location.reload();
              // }
              setIsOpen(true)
              // }
              // setSuccessMsg({
              //   type: "success",
              //   body: "A verification link has been sent to your email address. Please check your email and click on the link to verify.",
              // });
              setFirstName("");
              setLastName("");
              setEmail("");
              setPassword("");
              setIsLoading(false)

              // if (state && state.redirect) {
              //   localStorage.setItem("redirect", JSON.stringify(state.redirect))
              // }
            },
            (error) => {
              logger('./pages/layouts/Register.jsx', 'handleSubmit(): catch1', error);
              setSuccessMsg({
                type: "error",
                body: error?.response?.data?.message || "Registration failed!",
              });
              setFirstName("");
              setLastName("");
              setEmail("");
              setPassword("");
              setIsLoading(false)
            }
          );
      } catch (err) {
        logger('./pages/layouts/Register.jsx', 'handleSubmit(): catch2', err);
        setSuccessMsg({ type: "error", body: "Registration failed!" });
        setIsLoading(false)
      }
    }
  };

  const [showPwd, setShowPwd] = useState(false);
  const handleGuestUser = () => {
    localStorage.setItem("guest_user_visited", true);
    navigate("/");
  }
  const handleGo = () => {
    setIsOpen(false)
    if (state && state.redirect) {
      window.location.replace(state.redirect)
    } else {
      window.location.reload();
    }
  }

  const handleGotoTerms = () => {
    window.open(`${REACT_APP_URL}/tos`, "_blank")
  }
  const handleCheck = () => {
    setAgreeToTAC(!agreeToTAC)
  }
  const handleEmailSubCheck = (e) => {
    setSubscribeEmail(e.target.checked)
  }

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>Join Boundless Giving - Sign Up for an Account</title>
        <meta
          name="description"
          content="Register now on Boundless Giving to make purchases and support incredible causes. Create your account with your name, email & password, agreeing to our terms."
        />
      </Helmet>

<HeaderV3/>
      {/* <Mobile> */}
      <div className="register-main">
        <div className="register-container">
          <div className="register-logo">
            <Link to='/'>
              <img src={newWhiteLogo} alt="Boundless Giving Logo" />
            </Link>

          </div>
          <div className="register-form-container">
            <h1 className="create-account">SIGN UP</h1>
            <p className="sub-heading common_font" >
              By creating an account you may make purchases and support amazing causes.
              {successMsg && successMsg?.type === "success" && (
                <div className="reg_verify_text">{successMsg?.body}</div>
              )}
            </p>
            <form className="logForm">
              <section>
                <div className="form-group">
                  <div className="row">
                    {/*<div className="col-md-6 col-lg-6">
                      <div className="field_outer">
                        <select name="title" id="title" onChange={handleTitle}>
                          <option value="Mr.">Mr.</option>
                          <option value="Mrs.">Mrs.</option>
                          <option value="Ms.">Ms.</option>
                        </select>
                        <input
                          type="text"
                          value={title}
                          className={titleErr ? "register-form-control error-field" : "register-form-control"}
                          placeholder={titleErr ? "Enter a valid title!" : "Title"}
                          onChange={handleTitle}
                        />
                      </div>
                    </div>*/}
                    <div
                      className="field_outer"
                      style={{ display: "flex", alignItems: "stretch" }}
                    >
                      <input
                        type="text"
                        value={firstName}
                        className={
                          firstNameErr
                            ? "register-form-control-name error-field"
                            : "register-form-control-name"
                        }
                        placeholder={
                          firstNameErr
                            ? "Enter a valid first name!"
                            : "First Name"
                        }

                        onChange={handleFirstName}
                      />
                      {/* {firstNameErr && <div className='error-label'>Enter a valid first name!</div>} */}
                    </div>

                  </div>
                </div>
                <div className="form-group">
                  <div className="field_outer">
                    <input
                      type="text"
                      value={lastName}
                      className={
                        lastNameErr
                          ? "register-form-control-name error-field"
                          : "register-form-control-name"
                      }
                      placeholder={
                        lastNameErr ? "Enter a valid last name!" : "Last Name"
                      }
                      onChange={handleLastName}
                    />
                    {/* {lastNameErr && <label className='error-label'>Enter a valid last name!</label>} */}
                  </div>
                </div>
                <div className="form-group">
                  <div className="field_outer">
                    <i className="input-icon">
                      <EmailOutlinedIcon />
                    </i>
                    <input
                      type="email"
                      value={email}
                      className={
                        emailErr ? "register-form-control error-field" : "register-form-control"
                      }
                      placeholder={
                        emailErr ? "Enter a valid email id!" : "Email Address"
                      }
                      onChange={handleEmail}
                    />
                    {/* {emailErr && <label className='error-label'>Enter a valid email id!</label>} */}
                  </div>
                </div>
                <div className="form-group">
                  <div className="field_outer">
                    <i className="input-icon">
                      <PersonOutlineIcon />
                    </i>
                    <input
                      type={showPwd ? "text" : "password"}
                      value={password}
                      className={
                        pwdErr ? "register-form-control error-field" : "register-form-control"
                      }
                      placeholder={
                        pwdErr
                          ? "Enter a valid password!"
                          : "Password"
                      }
                      onChange={handlePassword}
                    />
                    {/* <img
                      src={eye_open}
                      alt=""
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}
                    /> */}
                    {
                      showPwd ? <span><VisibilityOffOutlinedIcon className={style.eye} onClick={() => { setShowPwd(!showPwd) }} /></span> :
                        <span><RemoveRedEyeOutlinedIcon className={style.eye} onClick={() => { setShowPwd(!showPwd) }} /></span>
                    }
                  </div>
                  {pwdErr && <label className='pw-error-label'>Invalid password!</label>}
                  <p style={{ textAlign: 'left', fontSize: "12px", color: "#FC3300", marginTop: "7px" }} className='common_font'>
                    <ul style={{margin: '20px'}}>
                      <li>Password should be min 8 characters long.</li>
                      <li>It should contain at least 1 number.</li>
                      <li>It should contain at least 1 special character.</li>
                    </ul>
                  </p>
                </div>
                <div className="form-group" style={{ marginBottom: "0px", display: 'flex' }}>
                  <label htmlFor="emailPolicy" style={{ fontWeight: 500 }} >
                    <input type="checkbox" id="emailPolicy" onChange={handleCheck} />
                    <p style={{ textAlign: "left", fontWeight: 400, color: "#FC3300", marginBottom: "0" }} className='common_font'>
                      I agree to Boundless Giving{" "}
                      <span style={{ cursor: "pointer", borderBottom: `1px solid red` }} onClick={handleGotoTerms}>
                        Terms of Service
                      </span>{" "}
                      <span style={{ color: "red" }}>*</span></p>
                  </label>
                </div>
                <div className="form-group" style={{ marginBottom: "10px", display: 'flex', marginTop: "10px" }}>
                  <label htmlFor="emailUpadte">
                    <input type="checkbox" id="emailUpadte" />
                    <p style={{ textAlign: "left", color: "#FC3300" }} className='common_font'>Keep me informed about  Boundless Giving news and events</p>
                  </label>
                </div>

                {successMsg && successMsg?.type === "error" && (
                  <p
                    className={
                      `${successMsg?.type === "error"
                        ? "error_response_msg"
                        : "success_response_msg"} common_font error_register`
                    }
                  >
                    {successMsg?.body}
                  </p>
                )}
              </section>

            </form>

            {/* </div> */}
            <button
              className={
                email !== "" &&
                  email !== null &&
                  password !== "" &&
                  password !== null &&
                  firstName !== "" &&
                  firstName !== null &&
                  lastName !== "" &&
                  lastName !== null &&
                  !firstNameErr &&
                  !lastNameErr &&
                  !emailErr &&
                  !pwdErr &&
                  agreeToTAC
                  ? (isLoading ? "register-blockBtn-loading " : "register-block-btn")
                  : "register-block-btn not-allowed-btn"
              }
              type="button"
              onClick={handleSubmit}
            >
              {isLoading ? <CircularProgress sx={{ color: '#FC3300' }} size={29} /> : "Create Account"}

            </button>
            <div className="stickDown" style={{ color: "#FC3300;", paddingBottom: '0px' ,marginTop:'20px'}}>
              <h6 style={{ fontSize: '14px' }} className='common_font'>
                Already a user? <span className='common_font' onClick={() => navigate("/login", { state: { redirect: state && state?.redirect ? state?.redirect : null } })}>Login</span> or<span className='common_font' onClick={handleGuestUser} > Browse as a guest</span>
              </h6>
              {/* <h6>Or</h6>
                <h6>
                  <Link to="/register" style={{ color: "black" }}>
                    Continue as guest
                  </Link>
                </h6> */}
            </div>
            <p className="required_text common_font" style={{ fontSize: '13px', color: '#FC3300' }}>* required</p>
          </div>
        </div>
        {/* </Mobile> */}
        <div>
          {/* <Dialog
          open={isOpen}
        >
          <DialogContent sx={{ fontFamily: "AvenirNext" }}>
            <DialogContentText textAlign="left">
              <p style={{margin: "10px 20px", fontSize: "20px", textAlign: "center"}} className='alert-login-signup'>
              Registration successful!<br />Please confirm your email to verify your account.
              </p>
              <PrimaryBtn
                  style={{marginTop: "20px"}}
                  text="Start Browsing"
                  handleClick={handleGo}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
          <DialogMui isOpen={isOpen} ButtonText="Start Browsing" handleClick={handleGo} textStyle={{ margin: '0' }} >
            <p style={{ textAlign: 'center' }}>
              Registration successful! Please confirm your email to verify your account.
            </p>
          </DialogMui>
        </div>
      </div>
    </>
  );
}

export default Register;
