import React from 'react';
import './donationSection.css';
import Grid from '@mui/material/Grid';

const DonationSection = (props) => {
  return (
    <div className="donation-section">
      <Grid container className='justify-content-between'>
        <Grid xs={12} md={6} lg={5}>
          <div className="left-content">
            <h1>You’ll Increase Donations <br /> From Current Donors.</h1>
            <p>
              Current donors will make incremental donations through the great offers from your partner brand.
            </p>
            <div className="btn-holder">
              <button className="right_btn">Free Demo</button>
            </div>
          </div>
        </Grid>
        <Grid xs={12} md={6} lg={5}>
          <div className="right-content">
            <div className="card background-image">
              <p className="card-title">Don’t just shop. <br /> Shop Generously.</p>
              <button className="shop-button">Shop Now.</button>
            </div>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

export default DonationSection;
