import { Grid, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState, } from "react";
import './Newcheckout.css'
import HeaderV3 from '../../molecules/Header/HeaderV3/HeaderV3'
import LoadingScreen from "../../LoadingScreen/Loading";
import AddPaymentMethods from "../../Account/PaymentMethods/AddPaymentMethods";
import HomeAddresses from "../../Account/Address/HomeAddresses";
import { useDispatch } from 'react-redux';
import { getCartItems } from "../../../../redux/cartReducer";
import { useSelector } from 'react-redux';
import AuthContext from "../../../../store/auth-context";
import { createPayment, fetchSavedCard } from "../../../../services/payment-services";
import { mobileStyle } from "../../Home";
import userServices from "../../../../services/user-services";
import { getDiscount, getDisplayPrice, getDisplayVariantPrice, getVariantDiscount } from "../../../../utils/displayPrice";
import orderServices from "../../../../services/order-services";
import { useNavigate } from "react-router-dom";
import logger from "../../../../utils/logger";
import constants from "../../../../constants/en";
import { Purchase } from "../../../../utils/meta-pixel-tracking";
import { resetCart } from "../../../../redux/cartReducer";
import NewFooterV2 from "../../molecules/NewFooter/NewFooterV2/NewFooterV2";
const totalprice = (cartItems) => {
  let sumWithoutDiscount = 0;
  let sum = 0;
  let advatixSum = 0;
  let selfSum = 0;
  let virtualSum = 0;
  let ediSum = 0;
  let discountPriceSum = 0;
  for (let i = 0; i < cartItems?.length; i++) {
    if (cartItems[i]?.auction_item?.store_item?.variant) {
      if (cartItems[i]?.auction_item?.store_item?.variant.quantity_in_stock >= cartItems[i].quantity) {
        sum += (Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item));
        discountPriceSum += (Number(+cartItems[i]?.quantity)) * Number(getVariantDiscount(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item));
        if (cartItems[i].auction_item.fulfilment.name === "advatix") {
          advatixSum += (Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        } else if (cartItems[i].auction_item.fulfilment.name === "self") {
          selfSum += (Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        } else if (cartItems[i].auction_item.fulfilment.name === "edi") {
          ediSum += (Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        } else {
          virtualSum += (Number(+cartItems[i]?.quantity)) * Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item))
        }
      }

    } else {
      if (cartItems[i]?.auction_item?.store_item?.quantity_in_stock >= cartItems[i].quantity) {
        sum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)));
        discountPriceSum += (Number(+cartItems[i]?.quantity)) * (Number(getDiscount(cartItems[i]?.auction_item)))
        if (cartItems[i].auction_item.fulfilment.name === "advatix") {
          advatixSum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        } else if (cartItems[i].auction_item.fulfilment.name === "self") {
          selfSum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        } else if (cartItems[i].auction_item.fulfilment.name === "edi") {
          ediSum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        } else {
          virtualSum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayPrice(cartItems[i]?.auction_item)))
        }
      }
    }
  }
  sumWithoutDiscount = sum;
  return [sum - discountPriceSum, advatixSum - discountPriceSum, selfSum - discountPriceSum, virtualSum - discountPriceSum, ediSum - discountPriceSum, discountPriceSum, sumWithoutDiscount];
};

const totalTaxablePrice = (cartItems) => {
  let sum = 0;
  for (const item of cartItems) {
    if (+item?.auction_item?.store_item?.charge_tax !== 0) {
      if (item?.auction_item?.store_item?.variant) {
        if (item?.auction_item?.store_item?.variant.quantity_in_stock >= item.quantity) {
          sum += (+item?.quantity) * ((Number(getDisplayVariantPrice(item?.auction_item?.store_item?.variant, item?.auction_item))) - (Number(getVariantDiscount(item?.auction_item?.store_item?.variant, item?.auction_item))));
        }

      } else {
        if (item?.auction_item?.store_item?.quantity_in_stock >= item.quantity) {
          sum += (+item?.quantity) * ((getDisplayPrice(item?.auction_item)) - (getDiscount(item?.auction_item)));
        }
      }
    }
  }
  return sum
}
function NewCheckout() {
  const { cusId, cardId } = useContext(AuthContext);

  const primaryColor = mobileStyle.color[1]
  let currentDiscountFromAuction = [];
  const { data: cartItems } = useSelector(state => state.cartDetails)
  const dispatch = useDispatch();
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const authCtx = useContext(AuthContext);
  const [updateQty, setUpdateQty] = useState(false);
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [shippingRate, setShippingRate] = useState(0);
  const [tax, setTax] = useState(0);
  const [showDiscountPercentage, setShowDiscountPercentage] = useState(true);
  const [userPaymentDetails, setUserPaymentDetails] = useState({});
  const navigate = useNavigate();
  const [cardDetails, setCardDetails] = useState(null);
  const [advatixItems, setAdvatixItems] = useState([])
  const [ediOrderItems, setEdiOrderItems] = useState([]);
  const [ediOrderItemsByDonor, setEdiOrderItemsByDonor] = useState([]);
  const [selfOrderItems, setselfOrderItems] = useState([])
  const [virtualItems, setvirtualItems] = useState([])

  const [modal, setModal] = useState({ isOpen: false, message: '' });
  const [noOfEvent, setNumberOfevent] = useState(1);
  const [uniqueEventId, setUniqueEventId] = useState(null);

  const [advatixItemPrice, setadvatixItemPrice] = useState(0);
  const [selfOrderItemPrice, setSelfOrderItemPrice] = useState(0);
  const [virtualOrderItemPrice, setvirtualOrderItemPrice] = useState(0);
  const [ediOrderItemPrice, setEdiOrderItemPrice] = useState(0);
  const [totalPriceWithoutDiscount, setTotalPriceWithoutDiscount] = useState(0);

  const [advatixShippingRate, setadvatixShippingPrice] = useState(0);
  const [selfShippingRate, setselfShippingPrice] = useState(0);
  const [ediShippingRate, setEdiShippingPrice] = useState(0);

  const [advatixSaleTax, setadvatixSaleTax] = useState(0);
  const [selfItemSaleTax, setSelfItemSaleTax] = useState(0);
  const [virtualSaleTax, setVirtualSaleTax] = useState(0);
  const [ediSaleTax, setEdiSaleTax] = useState(0);



  if (cartItems.length > 0) {
    cartItems.forEach((item) => {

      const isDiscount = item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage ? item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage : item.auction_item?.auction?.discount_percentage;
      if (isDiscount) {
        currentDiscountFromAuction.push(isDiscount);
      }
    });

  }
    useEffect(() => {
      // if(!authCtx.isLoggedIn){
      //   return
      // }
      setIsLoading(true)
      dispatch(getCartItems({ loggedIn: authCtx.isLoggedIn }))
  
      setIsLoading(false)
  
    }, [updateQty]);
  useEffect(() => {
    const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];

    if (uniqueDiscounts.length > 1 && showDiscountPercentage !== false) {
      setShowDiscountPercentage(false);
    } else if (uniqueDiscounts.length === 1) {
      setShowDiscountPercentage(true);
    }
  }, [cartItems.length, currentDiscountFromAuction]);


  // }, [])

  useEffect(() => {
    fetchCardData()
  }, [])


  const fetchCardData = async () => {
    try {
      if (!cusId || !cardId) return

      const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
      if (card) {
        setUserPaymentDetails(card);
      }

    }
    catch (error) {
      console.error(error)
    }
    finally {

    }
  }


  useEffect(() => {
    const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];

    if (uniqueDiscounts.length > 1 && showDiscountPercentage !== false) {
      setShowDiscountPercentage(false);
    } else if (uniqueDiscounts.length === 1) {
      setShowDiscountPercentage(true);
    }
  }, [cartItems.length, currentDiscountFromAuction]);





  const getShippingRate = async (cartItems) => {
    let sum = 0;
    let productsTotalWeight = 0;
    for (let i = 0; i < cartItems.length; i++) {
      if (cartItems[i].auction_item.is_virtual === 0 && cartItems[i].auction_item.fulfilment.pickup_type === "ship") {
        if (cartItems[i].auction_item.store_item.variant) {
          if (cartItems[i].auction_item.store_item.variant.quantity_in_stock >= cartItems[i].quantity) {
            productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
          }
        } else {
          if (cartItems[i].auction_item.store_item.quantity_in_stock >= cartItems[i].quantity) {

            productsTotalWeight += (+cartItems[i].auction_item?.weight) * (+cartItems[i].quantity);
          }
        }
      }
    }
    // return sum;

    // new logic
    if (productsTotalWeight > 0) {
      const shippingRate = await orderServices.getShippingRate(
        productsTotalWeight
      );
      return +shippingRate?.data?.shipping_rate
    }
    else {
      return 0
    }
  };

  const checkEmptyShipping = (error) => {
    if (error?.response?.data?.noShipping == true) {
      // if there is no shipping then we don't need to go back to the cart page. We need to move to shipping details page.
      return true
    }
    else {
      return false
    }
  }

  const getSalesTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if (tax.state !== "CA") {
        tax.amount = 0
      }
      setTax(tax)
      return tax
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getSalesTax()', { error: error });
      if (!checkEmptyShipping(error)) {
        // If user has shipping and error is some thing else then we will go back to cart page.
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getAdvatixSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if (tax.state !== "CA") {
        tax.amount = 0
      }
      setadvatixSaleTax(tax)
      return tax
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getAdvatixSaleTax()', { error: error });
      if (!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getSelfOrderSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if (tax.state !== "CA") {
        tax.amount = 0
      }
      setSelfItemSaleTax(tax)
      return tax
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getSelfOrderSaleTax()', { error: error });
      if (!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getVirtualSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      if (tax.state !== "CA") {
        tax.amount = 0
      }
      setVirtualSaleTax(tax)
      return tax
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getVirtualSaleTax()', { error: error });
      if (!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  };
  const getEdiSaleTax = async (amount) => {
    try {
      const tax = await orderServices.getSalesTax(amount)
      setEdiSaleTax(tax)
      return tax
    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'getEdiSaleTax()', { error: error });
      if (!checkEmptyShipping(error)) {
        alert(error.response.data.message || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
        return false
      }
      else {
        return true
      }
    }
  }

  const separateItems = (items) => {
    let advtItmes = [];
    let selfOrdItems = [];
    let virItems = [];
    let ediItems = [];
    let ediItemsByDonor = {}
    for (let i = 0; i < items.length; i++) {
      if (items[i].auction_item.store_item.variant) {
        if (items[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= items[i].quantity) {
          if (items[i]?.auction_item?.fulfilment?.name === "advatix") {
            advtItmes.push(items[i])
          } else if (items[i]?.auction_item?.fulfilment?.name === "self") {
            selfOrdItems.push(items[i])
          } else if (items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1) {
            virItems.push(items[i])
          }
          else if (items[i]?.auction_item?.fulfilment?.name === "edi") {
            ediItems.push(items[i])
            const item = items[i];
            const donor_id = item?.auction_item?.donor_id;
            if (!ediItemsByDonor[donor_id]) {
              ediItemsByDonor[donor_id] = [];
            }
            ediItemsByDonor[donor_id].push(item);
          }
        }
      } else {
        if (items[i].auction_item.store_item.quantity_in_stock >= items[i].quantity) {
          if (items[i]?.auction_item?.fulfilment?.name === "advatix") {
            advtItmes.push(items[i])
          } else if (items[i]?.auction_item?.fulfilment?.name === "self") {
            selfOrdItems.push(items[i])
          } else if (items[i]?.auction_item?.fulfilment?.name === "virtual" || items[i]?.auction_item?.is_virtual === 1) {
            virItems.push(items[i])
          }
          else if (items[i]?.auction_item?.fulfilment?.name === "edi") {
            ediItems.push(items[i])
            const item = items[i];
            const donor_id = item?.auction_item?.donor_id;
            if (!ediItemsByDonor[donor_id]) {
              ediItemsByDonor[donor_id] = [];
            }
            ediItemsByDonor[donor_id].push(item);
          }
        }
      }
    }
    return [advtItmes, selfOrdItems, virItems, ediItems, Object.values(ediItemsByDonor)]/// the last element is the array which includes arrays with objects of items according to donor_id eg: [[{},{}], [{},{}], [{},{}]]
  }

  const calculateDonation = (item) => {
    var amt = 0;
    // console.log("this is item from calculation",item)
    const causeSharePercentage = (+item?.auction_item?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100) || 0.8

    if (!item?.auction_item?.store_item_id) {
      //Auction
      // if (bidDetails?.data?.bidCount > 0) {
      //   amt = Number(bidDetails?.data?.bid_amount * causeSharePercentage);
      // }
      // else {
      //   amt = Number(bidDetails?.data?.price * causeSharePercentage);
      // }
    }
    else {

      let price = getDisplayPrice(item?.auction_item)
      let variantPrice = getDisplayVariantPrice(item?.auction_item?.store_item?.variant, item?.auction_item)
      if (item?.auction_item?.store_item?.variant) {
        amt = Number((variantPrice - Number(getVariantDiscount(item?.auction_item?.store_item?.variant, item?.auction_item))) * causeSharePercentage);
      }
      else {
        amt = Number((price - getDiscount(item?.auction_item)) * causeSharePercentage);
      }

    }

    return amt * (item?.quantity || 1);
  }


  useEffect(() => {
    if (!authCtx.isLoggedIn) {
      return
    }
    (async () => {
      const getEventId = (items) => {
        let eventIds = [];
        for (let i = 0; i < items.length; i++) {
          if (items[i].auction_item.store_item.variant) {
            if (items[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= items[i].quantity) {
              // eventIds.push(items[i]?.auction_item?.auction?.id)
              eventIds.push(items[i]?.auction_id)
            }
          } else {
            if (items[i].auction_item.store_item.quantity_in_stock >= items[i].quantity) {
              // eventIds.push(items[i]?.auction_item?.auction?.id)
              eventIds.push(items[i]?.auction_id)
            }
          }
        }
        return eventIds
      }


      const cardDetails = async () => {
        if (authCtx.cusId && authCtx.cardId) {
          const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
          setCardDetails({
            name: card.name,
            last4: card.last4,
            brand: card.brand
          })
        } else {
          setModal({ isOpen: true, message: constants.APP_COPY_CART_NO_CARD_DETAILS })
        }
      }

      function removeDuplicates(arr) {
        return [...new Set(arr)];
      }

      const taxValue = await getSalesTax(totalTaxablePrice(cartItems))
      if (taxValue === false) {
        navigate(-1);
        return
      }
      setPrice(totalprice(cartItems)[0]);
      setadvatixItemPrice(totalprice(cartItems)[1]);
      setSelfOrderItemPrice(totalprice(cartItems)[2]);
      setvirtualOrderItemPrice(totalprice(cartItems)[3])
      setEdiOrderItemPrice(totalprice(cartItems)[4])
      setDiscountPrice(totalprice(cartItems)[5])
      setTotalPriceWithoutDiscount(totalprice(cartItems)[6])
      cardDetails()
      setAdvatixItems(separateItems(cartItems)[0])
      setselfOrderItems(separateItems(cartItems)[1])
      setvirtualItems(separateItems(cartItems)[2])
      setEdiOrderItems(separateItems(cartItems)[3])
      setEdiOrderItemsByDonor(separateItems(cartItems)[4])



      // to get unique event ID to pass in state to buynow checkOut page
      if (removeDuplicates(getEventId(cartItems)).length > 1) {
        setNumberOfevent(2)
      } else {
        setNumberOfevent(1);
        setUniqueEventId(removeDuplicates(getEventId(cartItems))[0])
      }
      if (separateItems(cartItems)[0]?.length > 0) {
        getShippingRate(separateItems(cartItems)[0]).then((res) => {
          setadvatixShippingPrice(res);
        });
      }
      if (separateItems(cartItems)[1]?.length > 0) {
        getShippingRate(separateItems(cartItems)[1]).then((res) => {
          setselfShippingPrice(res);
        });
      }
      if (separateItems(cartItems)[4]?.length) {
        for (let itemsByDonor of separateItems(cartItems)[4]) {
          getShippingRate(itemsByDonor).then((res) => {
            setEdiShippingPrice((prev) => prev + res)
          })
        }
      }
      const advItems = separateItems(cartItems)[0]
      if (advItems?.length) {
        const advTaxValue = await getAdvatixSaleTax(totalTaxablePrice(advItems));
        if (!advTaxValue) {
          navigate(-1)
          return;
        }
      }
      const selfItems = separateItems(cartItems)[1]
      if (selfItems?.length) {
        const selfTax = await getSelfOrderSaleTax(totalTaxablePrice(selfItems));
        if (!selfTax) {
          navigate(-1)
          return;
        }
      }
      const virItems = separateItems(cartItems)[2]
      if (virItems?.length) {
        const virTax = await getVirtualSaleTax(totalTaxablePrice(virItems));
        if (!virTax) {
          navigate(-1)
          return;
        }
      }
      const ediItems = separateItems(cartItems)[3]
      if (ediItems?.length) {
        const ediTax = await getEdiSaleTax(totalTaxablePrice(ediItems));
        if (!ediTax) {
          navigate(-1)
          return;
        }
      }
    })()
  }, [cartItems]);


  useEffect(() => {
    setShippingRate(9.99) // Setting shipping rate to 9.99 per order
    // setShippingRate(+advatixShippingRate + +selfShippingRate + +ediShippingRate)
  }, [advatixShippingRate, selfShippingRate, ediShippingRate])

  const calcFinalPrice = (price, shipping_price, tax) => {
    // console.log("this is price",price, shipping_price, tax)
    let _price = price ? +price : 0;
    let _shipping_price = shipping_price ? +shipping_price : 0;
    let _tax = tax && tax.amount ? +tax.amount : 0;
    let total = _price + _shipping_price + _tax;
    const proccessingFees = Number((_price * 0.03).toFixed(2));
    total += proccessingFees

    return { totalAmount: total, proccessingFees };
  };

  const calcCharityPercentage = (products) => {
    let charityAmount = 0;
    for (let prod of products?.flat(Infinity)) {
      const causeSharePercentage = (Number(prod?.auction_item?.donor?.revenue_splits[0]?.cause_rev_share_percent) / 100) || 0.8
      charityAmount += Number(getDisplayPrice(prod?.auction_item)) * causeSharePercentage * (Number(prod?.quantity) || 1)
    }
    return charityAmount
  }


  const handleCheckout = async (e) => {


    e.preventDefault();
    if (!tax.state) {
      alert('Shiping Detail not Found' || constants.APP_COPY_ORDER_SALES_TAX_ERROR)
      return false
    }
    setIsOrderProcessing(true);
    try {
      // deduct amount
      const { totalAmount } = calcFinalPrice(price, shippingRate, tax)
      // create order using Advatix
      let totalOrderAmount = totalAmount
      try {
        const orderIds = {};
        const confirmOrder = {};
        let successItemsArray = []
        if (advatixItems.length > 0) {
          let { totalAmount, proccessingFees } = calcFinalPrice(advatixItemPrice, advatixShippingRate, advatixSaleTax)
          let items = [];
          for (let i = 0; i < advatixItems.length; i++) {
            let price = null;
            if (advatixItems[i]?.auction_item?.store_item?.variant) {
              price = Number(getDisplayVariantPrice(advatixItems[i]?.auction_item?.store_item?.variant, advatixItems[i]?.auction_item)) - Number(getVariantDiscount(advatixItems[i]?.auction_item?.store_item?.variant, advatixItems[i]?.auction_item))
            } else {
              price = getDisplayPrice(advatixItems[i]?.auction_item) - getDiscount(advatixItems[i]?.auction_item)
            }
            items.push({
              price: price,
              quantity: advatixItems[i].quantity,
              product_sku_id: advatixItems[i].item_sku,
              auction_item_id: advatixItems[i].auction_item_id,
              auction_id: advatixItems[i]?.auction_id,
              cause_id: advatixItems[i].cause_id
            })
          }
          let orderPayload = {
            user_id: authCtx.userId,
            order_amount: +totalAmount.toFixed(2),
            subtotal: advatixItemPrice,
            tax: +advatixSaleTax.amount,
            shipping_rate: +advatixShippingRate,
            proccessingFees: +proccessingFees.toFixed(2),
            items: items
          };
          const advatixOrder = await orderServices.createAdvatixOrder(orderPayload);
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Advatix Order Response', { advatixOrder: advatixOrder });
          if (advatixOrder?.responseStatus && advatixOrder?.responseStatusCode === 200) {
            confirmOrder.advatixOrder = orderPayload.items;
            confirmOrder.advatix_amount = +totalAmount;
            orderIds.advatixOrderId = advatixOrder.BG_response;
            successItemsArray.push(advatixItems)
            for (let i = 0; i < advatixItems.length; i++) {
              //update quantity
              let qtyUpdatePayload = {
                sku: advatixItems[i].item_sku,
                quantity: +advatixItems[i].quantity,
                isStoreItem: true,
                isVirtual: advatixItems[i].auction_item?.is_virtual ? true : false,
              };
              await orderServices.updateStoreItemQuantity(
                cartItems[i].auction_item_id,
                qtyUpdatePayload
              );
            }
          }
          else {
            totalOrderAmount = totalOrderAmount - totalAmount;
          }
        }
        if (selfOrderItems.length > 0) {
          let { totalAmount, proccessingFees } = calcFinalPrice(selfOrderItemPrice, selfShippingRate, selfItemSaleTax)
          let items = [];
          for (let i = 0; i < selfOrderItems.length; i++) {
            let price = null;
            if (selfOrderItems[i]?.auction_item?.store_item?.variant) {
              price = Number(getDisplayVariantPrice(selfOrderItems[i]?.auction_item?.store_item?.variant, selfOrderItems[i]?.auction_item)) - Number(getVariantDiscount(selfOrderItems[i]?.auction_item?.store_item?.variant, selfOrderItems[i]?.auction_item))
            } else {
              price = getDisplayPrice(selfOrderItems[i]?.auction_item) - getDiscount(selfOrderItems[i]?.auction_item)
            }
            items.push({
              price: price,
              quantity: selfOrderItems[i].quantity,
              product_sku_id: selfOrderItems[i].item_sku,
              auction_item_id: selfOrderItems[i].auction_item_id,
              auction_id: selfOrderItems[i]?.auction_id,
              cause_id: selfOrderItems[i].cause_id
            })
          }
          let orderPayload = {
            user_id: authCtx.userId,
            order_amount: +totalAmount.toFixed(2),
            subtotal: selfOrderItemPrice,
            tax: +selfItemSaleTax.amount,
            shipping_rate: +selfShippingRate,
            proccessingFees: +proccessingFees.toFixed(2),
            items: items
          };
          const selfOrder = await orderServices.createSelfOrder(orderPayload);
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Self Order Response', { selfOrder: selfOrder });
          successItemsArray.push(selfOrderItems)
          confirmOrder.selfOrder = orderPayload.items;
          confirmOrder.self_order_amount = +totalAmount;
          orderIds.selfOrderId = selfOrder.BG_response;
          for (let i = 0; i < selfOrderItems.length; i++) {
            //update quantity
            let qtyUpdatePayload = {
              sku: selfOrderItems[i].item_sku,
              quantity: +selfOrderItems[i].quantity,
              isStoreItem: true,
              isVirtual: selfOrderItems[i].auction_item?.is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              selfOrderItems[i].auction_item_id,
              qtyUpdatePayload
            );
          }

        }



        if (ediOrderItems.length > 0) {
          confirmOrder.ediOrder = [];
          orderIds.ediOrderId = []
          // let ediOrder = {}
          for (let i = 0; i < ediOrderItemsByDonor.length; i++) {
            // let {totalAmount,proccessingFees} = calcFinalPrice(ediOrderItemPrice, ediShippingRate, ediSaleTax)
            const itemsByDonor = ediOrderItemsByDonor[i]// here this will be again an array
            const ediItemsByDonorPrice = totalprice(itemsByDonor)[4]
            const ediItemsByDonorShippingRate = await getShippingRate(separateItems(itemsByDonor)[3])
            const ediItemsByDonorSalesTax = await getEdiSaleTax(totalTaxablePrice(separateItems(itemsByDonor)[3]));
            // console.log(ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax, "ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax")
            let { totalAmount, proccessingFees } = calcFinalPrice(ediItemsByDonorPrice, ediItemsByDonorShippingRate, ediItemsByDonorSalesTax)
            let items = [];
            for (let i = 0; i < itemsByDonor.length; i++) {
              let price = null;
              if (itemsByDonor[i]?.auction_item?.store_item?.variant) {
                price = Number(getDisplayVariantPrice(itemsByDonor[i]?.auction_item?.store_item?.variant, itemsByDonor[i]?.auction_item)) - Number(getVariantDiscount(itemsByDonor[i]?.auction_item?.store_item?.variant, itemsByDonor[i]?.auction_item))
              }
              else {
                price = getDisplayPrice(itemsByDonor[i]?.auction_item) - getDiscount(itemsByDonor[i]?.auction_item)
              }

              function getVendorSkuId(item) {
                if (item?.auction_item?.store_item?.variant && item?.auction_item?.store_item?.variant !== null) {
                  return item?.auction_item?.store_item?.variant?.vendor_sku_id
                }
                else {
                  return item?.auction_item?.store_item?.vendor_sku_id
                }
              }
              function getUPC(item) {
                if (item?.auction_item?.store_item?.variant && item?.auction_item?.store_item?.variant !== null) {
                  return item?.auction_item?.store_item?.variant?.upc
                }
                else {
                  return item?.auction_item?.store_item?.upc
                }
              }
              items.push({
                price: price,
                quantity: itemsByDonor[i].quantity,
                product_sku_id: itemsByDonor[i].item_sku,
                auction_item_id: itemsByDonor[i].auction_item_id,
                auction_id: itemsByDonor[i]?.auction_id,
                cause_id: itemsByDonor[i].cause_id,
                item_name: itemsByDonor[i].auction_item?.name,
                vendor_sku_id: getVendorSkuId(itemsByDonor[i]),
                upc: getUPC(itemsByDonor[i])
              })
            }
            let orderPayload = {
              user_id: authCtx.userId,
              order_amount: +totalAmount.toFixed(2),
              subtotal: ediItemsByDonorPrice,
              tax: +ediItemsByDonorSalesTax.amount,
              shipping_rate: +ediItemsByDonorShippingRate,
              proccessingFees: +proccessingFees.toFixed(2),
              items: items
            };
            const ediOrder = await orderServices.createEdiOrder(orderPayload);
            logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Edi Order Response', { ediOrder: ediOrder });
            if (ediOrder?.status === 200 && (ediOrder?.data?.path && ediOrder?.data?.url)) {
              // confirmOrder.ediOrder = [];
              // confirmOrder.ediOrder.push(orderPayload?.items);
              // confirmOrder.edi_order_amount = +totalAmount;
              ediOrder.BG_response.generatedFilePath = ediOrder?.data?.path
              ediOrder.BG_response.items = orderPayload?.items
              confirmOrder.ediOrder.push(ediOrder?.BG_response)
              orderIds.ediOrderId.push(ediOrder?.BG_response)
              // confirmOrder.ediOrder = orderPayload?.items;
              // orderIds.ediOrderId = ediOrder?.BG_response;
              // orderIds.ediOrderId.generatedFilePath = ediOrder?.data?.path;
              successItemsArray.push(itemsByDonor);

              for (let i = 0; i < itemsByDonor?.length; i++) {
                // update quantity
                let qtyUpdatePayload = {
                  sku: itemsByDonor[i].item_sku,
                  quantity: itemsByDonor[i].quantity,
                  isStoreItem: true,
                  isVirtual: itemsByDonor[i].auction_item?.is_virtual ? true : false,
                }
                // console.log(cartItems[i], "cartItems[i]")
                await orderServices.updateStoreItemQuantity(
                  itemsByDonor[i].auction_item_id,
                  qtyUpdatePayload
                );
              }

            }
            else {
              totalOrderAmount = totalOrderAmount - totalAmount;
            }
          }
        }
        // setIsOrderProcessing(false);
        // console.log(confirmOrder, successItemsArray, orderIds, 'confirmOrder, successItemsArray, orderIds')
        // return;

        if (virtualItems.length > 0) {
          let { totalAmount, proccessingFees } = calcFinalPrice(virtualOrderItemPrice, 0, virtualSaleTax)
          let items = [];
          for (let i = 0; i < virtualItems.length; i++) {
            let price = null;
            if (virtualItems[i]?.auction_item?.store_item?.variant) {
              price = Number(getDisplayVariantPrice(virtualItems[i]?.auction_item?.store_item?.variant, virtualItems[i]?.auction_item)) - Number(getVariantDiscount(virtualItems[i]?.auction_item?.store_item?.variant, virtualItems[i]?.auction_item))
            } else {
              price = getDisplayPrice(virtualItems[i]?.auction_item) - getDiscount(virtualItems[i]?.auction_item)
            }
            items.push({
              price: price,
              quantity: virtualItems[i].quantity,
              product_sku_id: virtualItems[i].item_sku,
              auction_item_id: virtualItems[i].auction_item_id,
              auction_id: virtualItems[i]?.auction_id,
              cause_id: virtualItems[i].cause_id
            })
          }
          let orderPayload = {
            user_id: authCtx.userId,
            order_amount: +totalAmount.toFixed(2),
            subtotal: virtualOrderItemPrice,
            tax: +virtualSaleTax.amount,
            shipping_rate: 0,
            proccessingFees: +proccessingFees.toFixed(2),
            items: items
          };
          const virtualOrder = await orderServices.createVirtualOrder(orderPayload);
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): create Virtual Order Response', { virtualOrder: virtualOrder });
          successItemsArray.push(virtualItems)
          confirmOrder.virtualOrder = orderPayload.items;
          confirmOrder.virtual_amount = +totalAmount;
          orderIds.virtualOrderId = virtualOrder.BG_response;

          for (let i = 0; i < virtualItems.length; i++) {
            //update quantity
            let qtyUpdatePayload = {
              sku: virtualItems[i].item_sku,
              quantity: +virtualItems[i].quantity,
              isStoreItem: true,
              isVirtual: virtualItems[i].auction_item?.is_virtual ? true : false,
            };
            await orderServices.updateStoreItemQuantity(
              virtualItems[i].auction_item_id,
              qtyUpdatePayload
            );
          }

        }
        ///payment
        let payload = {
          amount: Number(totalOrderAmount.toFixed(2)),
        };
        try {
          if (payload?.amount <= 0) {
            alert(constants.APP_COPY_ORDER_FAILED);
            logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): amount 0', payload);
            setIsOrderProcessing(false);
            return navigate(-1)
          }
          const response = await createPayment(payload);
          if (response.success) {
            await orderServices.confirmOrder(orderIds, confirmOrder);

            //meta pixel tracking event
            let content_ids = []
            let contents = []
            cartItems.map(item => {
              content_ids.push(item.auction_item_id)
              contents.push({
                name: item.auction_item.name,
                id: item.auction_item_id,
                sku: item.item_sku,
                quantity: item.quantity
              })
              return null
            })
            Purchase({ content_ids, contents, num_items: cartItems.length, value: totalOrderAmount.toFixed(2), currency: "USD" })
            setIsOrderProcessing(false);
            dispatch(resetCart())
            navigate('/checkout', {
              state: {
                noOfEvent: noOfEvent,
                auctionId: uniqueEventId,
                charityAmount: Number(calcCharityPercentage(successItemsArray)).toFixed(2)
              }
            });
          }
        } catch (error) {
          logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): updating payment failure', { orderIds: orderIds });
          await orderServices.updatePaymentFailure(orderIds);
          // restore advatix items
          for (let i = 0; i < advatixItems.length; i++) {
            //update quantity
            let qtyUpdatePayload = {
              sku: advatixItems[i].item_sku,
              quantity: +advatixItems[i].quantity,
              isStoreItem: true,
              isVirtual: advatixItems[i].auction_item?.is_virtual ? true : false,
              incrementStock: true
            };
            await orderServices.updateStoreItemQuantity(
              cartItems[i].auction_item_id,
              qtyUpdatePayload
            );
          }

          ///restore self items
          for (let i = 0; i < selfOrderItems.length; i++) {
            //update quantity
            let qtyUpdatePayload = {
              sku: selfOrderItems[i].item_sku,
              quantity: +selfOrderItems[i].quantity,
              isStoreItem: true,
              isVirtual: selfOrderItems[i].auction_item?.is_virtual ? true : false,
              incrementStock: true
            };
            await orderServices.updateStoreItemQuantity(
              selfOrderItems[i].auction_item_id,
              qtyUpdatePayload
            );
          }

          //  restore ediItems
          if (ediOrderItems.length) {
            for (let i = 0; i < ediOrderItems.length; i++) {
              //update quantity
              let qtyUpdatePayload = {
                sku: ediOrderItems[i].item_sku,
                quantity: +ediOrderItems[i].quantity,
                isStoreItem: true,
                isVirtual: ediOrderItems[i].auction_item?.is_virtual ? true : false,
                incrementStock: true
              };
              await orderServices.updateStoreItemQuantity(
                ediOrderItems[i].auction_item_id,
                qtyUpdatePayload
              );
            }
          }

          ///restore virtual items
          for (let i = 0; i < virtualItems.length; i++) {
            //update quantity
            let qtyUpdatePayload = {
              sku: virtualItems[i].item_sku,
              quantity: +virtualItems[i].quantity,
              isStoreItem: true,
              isVirtual: virtualItems[i].auction_item?.is_virtual ? true : false,
              incrementStock: true
            };
            await orderServices.updateStoreItemQuantity(
              virtualItems[i].auction_item_id,
              qtyUpdatePayload
            );
          }
          alert(constants.APP_COPY_PAYMENT_FAILED);
          setIsOrderProcessing(false);
        }

      } catch (error) {
        logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): catch1', error);
        // console.log('Error at handleCheckout() catch 1 ---', error)
        alert(error?.response?.data?.responseMsg || error?.response?.data?.message || constants.APP_COPY_ORDER_FAILED);
        setIsOrderProcessing(false);
        navigate(-1)
      }

    } catch (error) {
      logger('./pages/components/organisms/cart/cart.jsx', 'handleCheckout(): catch2', error);
      // console.log('Error at handleCheckout() catch 2 ---', error)
      alert(error?.response?.data?.message || constants.APP_COPY_ORDER_FAILED);
      setIsOrderProcessing(false);
      navigate(-1)
    }
  };

  const checkMaxQuantity = (item) => {
    if (item?.auction_item?.store_item?.variant) {
      if (item?.auction_item?.store_item?.variant?.quantity_in_stock <= item.quantity) {
        return true
      } else {
        return false
      }
    } else {
      if (item?.auction_item?.store_item?.quantity_in_stock <= item.quantity) {
        return true
      } else {
        return false
      }
    }
  }

  const handleQuantityDecrement = async (item) => {
    if (item?.quantity <= 1) {
      return
    } else {
      await userServices.updateQuantity({ quantity: item?.quantity - 1 }, item.id, authCtx.isLoggedIn)
    }
    setUpdateQty(!updateQty)
  }

  const handleQtyIncrement = async (item) => {
    if (item?.auction_item?.store_item?.variant) {
      if (item?.auction_item?.store_item?.variant?.quantity_in_stock === item.quantity) {
        return
      } else {
        await userServices.updateQuantity({ quantity: item?.quantity + 1 }, item.id, authCtx.isLoggedIn)
      }
    } else {
      if (item?.auction_item?.store_item?.quantity_in_stock === item.quantity) {
        return
      } else {
        await userServices.updateQuantity({ quantity: item?.quantity + 1 }, item.id, authCtx.isLoggedIn)
      }
    }
    setUpdateQty(!updateQty)
  }


  return (
    <>
      <HeaderV3 />
      <div className='ch-main-div'>
        <h1>CHECKOUT</h1>

        <Grid container spacing={2} style={{ marginTop: '50px' }}>
          <Grid xs={12} md={8}>
            <div className="padding">

              {isOrderProcessing && (
                <LoadingScreen loadingText='Saving address information ...' />
              )}
              {
                isLoading && (
                  <LoadingScreen bgColor='rgb(240, 232, 227)' />
                )
              }
              <AddPaymentMethods />

              <HomeAddresses />

            </div>
          </Grid>
          <Grid xs={12} md={4} order={{ xs: 1, md: 2 }} >
            <div style={{ backgroundColor: 'white', borderRadius: '30px' }}>
              <div className="item-carts" >
                {cartItems.length > 0 ? (
                  cartItems?.map((item, i) => {
                    const imageUrl = item.auction_item.assets?.find(
                      (img) =>
                        img.context_type === "thumbnail" ||
                        img.context_type === "portrait"
                    )?.name;

                    return (
                      <>
                        <div className="item-cart" key={i}>
                          <div className="cart-">
                            <img
                              src={imageUrl}
                              alt="Product"
                              className="item-image"
                            />
                          </div>
                          <div className="item-details">
                            <p>{item.auction_item.name}</p>
                            <div className="quantity-control">
                              <div>
                                <button
                                  onClick={() =>
                                    handleQuantityDecrement(item)
                                  }
                                >
                                  -
                                </button>
                                <span> {item.quantity}</span>
                                <Tooltip
                                  title={
                                    checkMaxQuantity(item)
                                      ? "You have reached maximum quantity"
                                      : ""
                                  }
                                >
                                  <button
                                    onClick={() => handleQtyIncrement(item)}
                                  >
                                    +
                                  </button>
                                </Tooltip>
                              </div>

                            </div>
                          </div>
                          <div className="item-price">
                            <div className="cart-card-remove_price">
                              <p style={{ color: primaryColor, fontWeight: "600", fontWeight: 'bold', textDecoration: currentDiscountFromAuction[0] && (Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 || Number(getDiscount(item.auction_item)) !== 0) ? 'line-through' : 'none' }} >
                                {item.auction_item.store_item.variant
                                  ? ` $${(item.quantity * Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item))).toFixed(2)}`
                                  : ` $${(item.quantity * Number(getDisplayPrice(item.auction_item))).toFixed(2)}`}
                              </p>
                              {/* <p>(${(donationPrice).toFixed(2)} Donation Impact)</p> */}
                            </div>
                            {(Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 ||
                              Number(getDiscount(item.auction_item)) !== 0) &&
                              currentDiscountFromAuction[0] && <p style={{ color: "var(--red-color)", fontWeight: "600" }}>
                                {
                                  item.auction_item.store_item.variant
                                    ? `$${(item.quantity * (Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item)) - Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)))).toFixed(2)}`
                                    : `$${(item.quantity * (Number(getDisplayPrice(item.auction_item)) - Number(getDiscount(item.auction_item)))).toFixed(2)}`
                                } Flash Sale Price
                              </p>}
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p className="empty_cart">Your cart is empty.</p>
                )}
              </div>
              <div className="custom-borde">
                <div className="cart-expenditure">
                  {currentDiscountFromAuction[0] &&
                    <div className="subtotal">
                      <h2>{showDiscountPercentage ? `${currentDiscountFromAuction[0]}% Flash Sale Discount` : 'Total Flash Sale Extra Savings'}</h2>
                      <h2>${Number(discountPrice).toFixed(2)}</h2>
                    </div>}
                  <div className="subtotal">
                    <h2>Subtotal</h2>
                    <h2>
                      ${Number(price).toFixed(2)}
                    </h2>
                  </div>
                  <div className="subtotal">
                    <h2>Shipping</h2>
                    <h2>${Number(shippingRate).toFixed(2)}</h2>
                  </div>


                  {tax?.amount > 0 && (
                    <div className="subtotal">
                      <h2>
                        Tax{" "}
                        <span>
                          ({`${tax?.amount === 0 ? 0 : tax?.percentage}%`})
                        </span>
                      </h2>
                      <h2>${tax.amount}</h2>
                    </div>
                  )}

                  <div>
                    <div className="subtotal">
                      <h2>
                        Processing Fees <span>(3%) </span>
                      </h2>
                      <h2>
                        $
                        {Number(calcFinalPrice(price, shippingRate, tax).proccessingFees).toFixed(2)}
                      </h2>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "red",
                    }}
                    className="subtotal"
                  >
                    <h2 style={{ fontSize: "26px" }}>Total</h2>
                    <h2 style={{ fontSize: "26px" }}>
                      ${Number(calcFinalPrice(price, shippingRate, tax).totalAmount).toFixed(2)}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <button className="pay-now-btn"
              onClick={handleCheckout}
            >
              Pay Now
            </button>
          </Grid>

        </Grid>
      </div>
      <NewFooterV2 />
    </>
  )
}

export default NewCheckout

