import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import auctionServices from '../services/auction-services';


export const fetchShopNowProducts = createAsyncThunk(
    'redux/fetchShopNowProducts',
    async ({  page=1, limit=12, filters, isFilters, selectedSortValue='relevance', filterByName=false, searchText }) => {
            const response = await auctionServices.getItemsForShopNow(page, limit, filters, isFilters, selectedSortValue, filterByName, searchText );
            return { response: response.data, totalPage: response.totalPage, totalCount: response.count }
    }
)

const initialState = {
    selectedFilters: { category: [], customCategory: [], brands: [], causes: [] },
    selectedFiltersObj: { category: [], customCategory: [], brands: [], causes: [] },
    selectedSortValue:'relevance',
    products: [],
    totalPage: 0,
    totalCount: 0,
    page: 1,
    isShopNowLoading:false,
    searchText:'',
    error: ''
}


const shopNowFilterSlice = createSlice({
    name: 'shopwnow',
    initialState,
    reducers: {
        setSelectedFilters: (state, action) => {
      
            
            if(!state.selectedFilters[action.payload.key]?.includes(action.payload.value.id)) {
                state.selectedFilters[action.payload.key].push(action.payload.value.id)
                state.selectedFiltersObj[action.payload.key].push({
                    id: action.payload.value.id,
                    name: action.payload.value.name,
                    valueType: action.payload.valueType
                })
            }
            else {
                state.selectedFilters[action.payload.key] = state.selectedFilters[action.payload.key].filter((id) => id !== action.payload.value.id)
                state.selectedFiltersObj[action.payload.key] = state.selectedFiltersObj[action.payload.key].filter((obj) => obj.id !== action.payload.value.id)
            }
            state.page = 1
        },
        clearFilter: (state, action) => {
            state.selectedFilters[action.payload.key] = state.selectedFilters[action.payload.key].filter((id) => id !== action.payload.value.id)
            state.selectedFiltersObj[action.payload.key] = state.selectedFiltersObj[action.payload.key].filter((obj) => obj.id !== action.payload.value.id)
            state.page = 1
        },
        clearAllFilter: (state, action) => {
            state.selectedFilters = { category: [], customCategory: [], brands: [], causes: [] }
            state.selectedFiltersObj = { category: [], customCategory: [], brands: [], causes: [] }
            state.page = 1
            state.selectedSortValue = "relevance"
            state.searchText = ""
        },
        setPage: (state, action) => {
            state.page = action.payload.page
        },
        setSortValue: (state, action) => {
            state.selectedSortValue = action.payload.sortValue
        },
        setShopNowSearchText: (state, action) => {
            state.searchText = action.payload.searchText
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchShopNowProducts.pending, (state, action) => {
            state.isShopNowLoading = true
            state.error = ''
        })
        builder.addCase(fetchShopNowProducts.fulfilled, (state, action) => {
            const { response, totalCount, totalPage } = action.payload
            state.products = response
            state.isShopNowLoading = false
            state.totalPage = totalPage
            state.totalCount = totalCount
        })
        builder.addCase(fetchShopNowProducts.rejected, (state, action) => {
            state.isShopNowLoading = false;
            state.error = action.error.message;
        })
    }
})

export default shopNowFilterSlice.reducer;
export const { setSelectedFilters, clearAllFilter, clearFilter, setPage, setSortValue, setShopNowSearchText } = shopNowFilterSlice.actions;