import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import userServices from "../../../../services/user-services";
import AuthContext from "../../../../store/auth-context";
import AddIcon from '@mui/icons-material/Add';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { getCartItems } from "../../../../redux/cartReducer";
import { useDispatch } from 'react-redux';
import { InitiateCheckout } from "../../../../utils/meta-pixel-tracking";
import HeaderV3 from "../../molecules/Header/HeaderV3/HeaderV3";
import { mobileStyle } from "../../Home";
import { useMediaQuery } from "react-responsive";
import newDeleteIcon from "../../../../images/newDeleteIcon.svg";
import NewButton from "../../atoms/NewButton/NewButton";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";
import NewCardLayout from "../../atoms/NewCardLayout/NewCardLayout";
import LoadingScreen from "../../LoadingScreen/Loading";
import { clearPageNumber } from "../../../../redux/itemsReducer";
import { Tooltip } from '@mui/material';
import NewFooter from "../../molecules/NewFooter/NewFooter";
import ConfirmAlertLogin from "../../ConfirmAlertLogin";
import './cart.css'
import { getDiscount, getDisplayPrice, getDisplayVariantPrice, getVariantDiscount } from "../../../../utils/displayPrice";
import orderServices from "../../../../services/order-services";
import logger from "../../../../utils/logger";
import { clearAllFilter } from "../../../../redux/shopNowFilterReducer";

const ShopMore = () => {
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div style={{ textAlign: 'center' }}>
      <button style={{ width: isMobile ? "" : "100px" }} onClick={() => {
        dispatch(clearPageNumber());
        // navigate("/browse-items", {
        //   state: { buyNow: true, title: "Buy now", from: 'buyNow' },
        // })
        navigate('/shop-now')
      }

      } className='buy-checkout-btn-outline'>Keep shopping</button>
    </div>
  )
}
const calcFinalPrice = (price, shipping_price, tax) => {
  // console.log("this is price",price, shipping_price, tax)
  let _price = price ? +price : 0;
  let _shipping_price = shipping_price ? +shipping_price : 0;
  let _tax = tax && tax.amount ? +tax.amount : 0;
  let total = _price + _shipping_price + _tax;
  const proccessingFees = Number((_price * 0.03).toFixed(2));
  total += proccessingFees

  return { totalAmount: total, proccessingFees };
};

const CartOverView = () => {

  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [donationImpact, setDonationImpact] = useState(0)
  const [showDiscountPercentage, setShowDiscountPercentage] = useState(true);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [updateQty, setUpdateQty] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState({})
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [tax, setTax] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: cartItems, itemRemoved } = useSelector(state => state.cartDetails)
  const primaryBackgroundColor = "var(--pure-white)"
  const primaryColor = mobileStyle.color[1]
  const redColor = mobileStyle.backgroundColor[0]
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  useEffect(() => {
    // if(!authCtx.isLoggedIn){
    //   return
    // }
    setIsLoading(true)
    dispatch(getCartItems({ loggedIn: authCtx.isLoggedIn }))

    setIsLoading(false)

  }, [updateQty]);

  let currentDiscountFromAuction = [];

  if (cartItems.length > 0) {
    cartItems.forEach((item) => {

      const isDiscount = item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage ? item.auction_item?.auction_auction_item_xrefs?.[0]?.auction?.discount_percentage : item.auction_item?.auction?.discount_percentage;
      if (isDiscount) {
        currentDiscountFromAuction.push(isDiscount);
      }
    });

    // const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];

    // // If there's more than one unique discount, set `setShowDiscountPercentage(false)`
    // if (uniqueDiscounts.length > 1) {

    //   setShowDiscountPercentage(false);
    // }
  }

  useEffect(() => {
    const uniqueDiscounts = [...new Set(currentDiscountFromAuction)];

    if (uniqueDiscounts.length > 1 && showDiscountPercentage !== false) {
      setShowDiscountPercentage(false);
    } else if (uniqueDiscounts.length === 1) {
      setShowDiscountPercentage(true);
    }
  }, [cartItems.length, currentDiscountFromAuction]);

  const calculateDonation = (item) => {
    var amt = 0;

    // console.log("this is item from calculation",item)
    const causeSharePercentage = (+item?.auction_item?.donor?.revenue_splits[0]?.cause_rev_share_percent / 100) || 0.8

    if (!item?.auction_item?.store_item_id) {
      //Auction
      // if (bidDetails?.data?.bidCount > 0) {
      //   amt = Number(bidDetails?.data?.bid_amount * causeSharePercentage);
      // }
      // else {
      //   amt = Number(bidDetails?.data?.price * causeSharePercentage);
      // }
    }
    else {

      let price = getDisplayPrice(item?.auction_item)
      let variantPrice = getDisplayVariantPrice(item?.auction_item?.store_item?.variant, item?.auction_item)
      if (item?.auction_item?.store_item?.variant) {
        amt = Number((variantPrice - Number(getVariantDiscount(item?.auction_item?.store_item?.variant, item?.auction_item))) * causeSharePercentage);
      }
      else {
        amt = Number((price - getDiscount(item?.auction_item)) * causeSharePercentage);
      }

    }

    return amt * (item?.quantity || 1);
  }

  const calculateTotalDonation = () => {
    let totalAmt = 0; // Initialize total amount

    cartItems.forEach((item) => {
      const causeSharePercentage =
        (Number(item?.auction_item?.donor?.revenue_splits?.[0]?.cause_rev_share_percent) / 100) || 0.8;

      let amt = 0; // Initialize item amount

      if (!item?.auction_item?.store_item_id) {
        // Auction
        // Uncomment this if bidDetails is available
        // if (bidDetails?.data?.bidCount > 0) {
        //     amt = Number(bidDetails?.data?.bid_amount * causeSharePercentage);
        // } else {
        //     amt = Number(bidDetails?.data?.price * causeSharePercentage);
        // }
      } else {
        let price = getDisplayPrice(item?.auction_item);
        let variantPrice = getDisplayVariantPrice(item?.auction_item?.store_item?.variant, item?.auction_item);

        if (item?.auction_item?.store_item?.variant) {
          amt = Number(
            (variantPrice - Number(getVariantDiscount(item?.auction_item?.store_item?.variant, item?.auction_item)))
            * causeSharePercentage
          );
        } else {
          amt = Number(
            (price - getDiscount(item?.auction_item))
            * causeSharePercentage
          );
        }
      }

      totalAmt += amt * (item?.quantity || 1);
    });

    return totalAmt;
  };


  useEffect(() => {
    // if(!authCtx.isLoggedIn){
    //   return
    // }
    if (!cartItems?.length) return;
    setIsLoading(true)
    const totalprice = (cartItems) => {
      let sum = 0;
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i]?.auction_item?.store_item?.variant) {
          if (cartItems[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= cartItems[i].quantity) {
            sum += (Number(+cartItems[i]?.quantity)) * (Number(getDisplayVariantPrice(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item)));
          }

        } else {
          if (cartItems[i]?.auction_item?.store_item?.quantity_in_stock >= cartItems[i].quantity) {
            sum += (Number(+cartItems[i]?.quantity)) * (Number(+getDisplayPrice(cartItems[i]?.auction_item)));
          }
        }
      }
      return sum;
    };


    const totalDiscountPrice = (cartItems) => {
      let sum = 0;
      for (let i = 0; i < cartItems.length; i++) {
        if (cartItems[i]?.auction_item?.store_item?.variant) {
          if (cartItems[i]?.auction_item?.store_item?.variant?.quantity_in_stock >= cartItems[i].quantity) {
            sum += (Number(+cartItems[i]?.quantity)) * (Number(getVariantDiscount(cartItems[i]?.auction_item?.store_item?.variant, cartItems[i]?.auction_item)));
          }

        } else {
          if (cartItems[i]?.auction_item?.store_item?.quantity_in_stock >= cartItems[i].quantity) {
            sum += (Number(+cartItems[i]?.quantity)) * (Number(+getDiscount(cartItems[i]?.auction_item)));
          }
        }
      }
      return sum;
    };

    const getSalesTax = async (amount) => {
      try {
        const taxs = await orderServices.getSalesTax(amount)
        return taxs
      } catch (error) {
        logger('./pages/components/organisms/cart/cart.jsx', 'getSalesTax()', { error: error });

      }

    };

    const calculateDonationImpact = (cartItems) => {
      let sum = 0;
      for (let i = 0; i < cartItems.length; i++) {

        const donation = calculateDonation(cartItems[i]);
        sum += donation;

      }
      return sum;
    };

    const fetchSalesTax = async () => {
      try {
        const taxValue = await getSalesTax(totalprice(cartItems) - totalDiscountPrice(cartItems));
        setTax(taxValue);
      } catch (error) {
        console.error("Error fetching sales tax:", error);
      }
    };

    fetchSalesTax();

    setPrice(totalprice(cartItems));
    setDiscountPrice(totalDiscountPrice(cartItems));
    setDonationImpact(calculateDonationImpact(cartItems));
    setIsLoading(false)

    let content_ids = []
    let contents = []
    cartItems.map(item => {
      content_ids.push(item.auction_item_id)
      contents.push({
        name: item.auction_item.name,
        id: item.auction_item_id,
        sku: item.item_sku,
        quantity: item.quantity,
        auction_id: item?.auction_id
      })
      return null
    }

    )

    setCheckoutDetails({ content_ids, contents, num_items: cartItems.length, value: (totalprice(cartItems) - totalDiscountPrice(cartItems)) })
  }, [cartItems]);



  const handleRemove = async (id, idx) => {
    await userServices
      .removeFromCart(id, authCtx.isLoggedIn)
      .then((res) => {
        dispatch(getCartItems({ loggedIn: authCtx.isLoggedIn }))
      })
      .catch((err) => {
        throw err;
      });

  };

  const clickHandler = (i) => {
    navigate(
      `/lot/${i?.auction_item?.id}?auction_id=${i?.auction_item?.auction.id}&cause_id=${i?.auction_item?.auction.cause_id}`
    );
  };

  const handleQtyIncrement = async (item) => {
    if (item?.auction_item?.store_item?.variant) {
      if (item?.auction_item?.store_item?.variant?.quantity_in_stock === item.quantity) {
        return
      } else {
        await userServices.updateQuantity({ quantity: item?.quantity + 1 }, item.id, authCtx.isLoggedIn)
      }
    } else {
      if (item?.auction_item?.store_item?.quantity_in_stock === item.quantity) {
        return
      } else {
        await userServices.updateQuantity({ quantity: item?.quantity + 1 }, item.id, authCtx.isLoggedIn)
      }
    }

    setUpdateQty(!updateQty)
  }

  const handleQuantityDecrement = async (item) => {
    if (item?.quantity <= 1) {
      return
    } else {
      await userServices.updateQuantity({ quantity: item?.quantity - 1 }, item.id, authCtx.isLoggedIn)
    }
    setUpdateQty(!updateQty)
  }

  const checkout = () => {
    if (!authCtx.isLoggedIn) {
      setOpenConfirmAlert(true);
      return
    }
    //   //meta pixel tracking
    InitiateCheckout(checkoutDetails)
    navigate("/cart-checkout")
  }

  const checkMaxQuantity = (item) => {
    if (item?.auction_item?.store_item?.variant) {
      if (item?.auction_item?.store_item?.variant?.quantity_in_stock <= item.quantity) {
        return true
      } else {
        return false
      }
    } else {
      if (item?.auction_item?.store_item?.quantity_in_stock <= item.quantity) {
        return true
      } else {
        return false
      }
    }
  }

  const handleContinueShoping = (shop) => {
    dispatch(clearAllFilter());
    navigate('/shop-now',{ state: { shop } })
    // if(lastCartItem?.auction_item?.auction?.status !== "live" || lastCartItem?.auction_item?.auction?.is_store){
    //   return
    // }
    // setParams({auction:data?.data,image:data?.data?.image2,from:paramsData?paramsData?.from:'event',market_place:(data?.data.market_place === "1" || paramsData?.market_place === true),type:data?.data.market_place === "1"?'Marketplace':data?.data.is_store?'Store':'Event'})

    // let params = {
    //   auction:lastCartItem?.auction_item?.auction,
    //   image:lastCartItem?.auction_item?.auction?.image2,
    //   from:(lastCartItem?.auction_item?.auction?.market_place === "1") ? 'donor' : 'event-byNow',
    //   market_place : (lastCartItem?.auction_item?.auction?.market_place === "1"),
    //   type: (lastCartItem?.auction_item?.auction?.market_place === "1")  ? 'Marketplace' : (lastCartItem?.auction_item?.auction?.is_store) ? 'Store' : 'Event',
    //   title:lastCartItem?.auction_item?.auction?.title
    // }
    // navigate("/browse-items", {
    //   state: { ...params }
    // })
  }
  return (
    <div className="cart-outer-container">

      {/* <GlobalHeader title="My Cart" /> */}
      {/* <HeaderV2 logoType='black2' primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}} isSubHeader subHeaderTitle="Cart"/> */}
      <HeaderV3 primaryBackgroundColor={primaryBackgroundColor} isSubHeader subHeaderTitle="Cart" position="absolute" />

      {isLoading && (
        <LoadingScreen bgColor="#F0E8E3" />
      )}
      <div className={` ${isMobile ? "bag-container cart-page-font" : "margin_class cart-page-font"}`} style={{ padding: isMobile ? "55px 5vw" : "", minHeight: isMobile ? "95vh" : "52vh", backgroundColor: "#FAFAFA", paddingTop: isMobile ? "170px" : "200px" }}>
        <div className="card" style={{ boxShadow: 'none' }}>
          {/* {itemRemoved && <p className="alert1"> <InfoOutlinedIcon/> <span>One or more item from your cart have been removed due to unavailability</span></p>} */}
          {(cartItems.length === 0) ? (

            <div className="bag-container-empty" style={{ marginTop: isMobile ? "100px" : "" }}>
              <div className="no-items">Your Cart Is Empty</div>
              <ShopMore />
            </div>

          ) : (
            cartItems.map((item, i) => {
              let donationPrice = calculateDonation(item);
              return (
                <React.Fragment key={item.id}>

                  {/* product card*/}

                  <NewCardLayout imageContainerStyle={{ width: !isMobile && '150px', height: !isMobile && '150px' }} onClick={() => clickHandler(item)} image={item.auction_item.assets.find(img => (img.context_type === 'thumbnail' || img.context_type === 'portrait'))?.name} neededLine={true}>
                    <div className="cart-card-content" key={item.id}>
                      <h1 >{item.auction_item?.name}</h1>
                      <div style={{ marginTop: "-10px", fontSize: "12px", display: "flex", gap: "10px" }}>
                        {item.auction_item.store_item.variant && item.auction_item.store_item.option ? (
                          Object.keys(item.auction_item.store_item.option).map((key) => (
                            <span style={{ color: primaryColor }}>{key}: {item.auction_item.store_item.option[key]}</span>
                          ))
                        ) : null}
                      </div>

                      <div className="cart-card-qty_price">
                        <div className="cart-card-remove_price">
                          <h5 style={{ color: primaryColor, fontWeight: "600", fontFamily: "alternategotno1dregular", fontSize: '30px', textDecoration: currentDiscountFromAuction[0] && (Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 || Number(getDiscount(item.auction_item)) !== 0) ? 'line-through' : 'none' }} >
                            {item.auction_item.store_item.variant
                              ? ` $${(item.quantity * Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item))).toFixed(2)}`
                              : ` $${(item.quantity * Number(getDisplayPrice(item.auction_item))).toFixed(2)}`}
                          </h5>
                          {/* <p>(${(donationPrice).toFixed(2)} Donation Impact)</p> */}
                        </div>
                        {(Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)) !== 0 ||
                          Number(getDiscount(item.auction_item)) !== 0) &&
                          currentDiscountFromAuction[0] && <h5 style={{ color: "var(--red-color)", fontWeight: "600" }}>
                            {
                              item.auction_item.store_item.variant
                                ? `$${(item.quantity * (Number(getDisplayVariantPrice(item.auction_item?.store_item?.variant, item?.auction_item)) - Number(getVariantDiscount(item.auction_item?.store_item?.variant, item?.auction_item)))).toFixed(2)}`
                                : `$${(item.quantity * (Number(getDisplayPrice(item.auction_item)) - Number(getDiscount(item.auction_item)))).toFixed(2)}`
                            } Flash Sale Price
                          </h5>}
                      </div>
                      <div style={{ marginBottom: "5px", fontSize: "12px", }}>
                        {
                          (item.auction_item.store_item?.variant) ?

                            (item.auction_item.store_item?.variant?.quantity_in_stock < 5 && item.auction_item.store_item?.variant?.quantity_in_stock > 0) ? <span style={{ color: mobileStyle.color[0] }}>Only {item.auction_item.store_item?.variant?.quantity_in_stock} left - order soon</span> : (item.auction_item.store_item?.variant?.quantity_in_stock === 0) ? <span style={{ color: mobileStyle.color[0] }}>Sorry this item is now sold out</span> : ""
                            : (item.auction_item.store_item?.quantity_in_stock < 5 && item.auction_item.store_item?.quantity_in_stock > 0) ? <span style={{ color: mobileStyle.color[0] }}>Only {item.auction_item.store_item?.quantity_in_stock} left - order soon</span> : (item.auction_item.store_item?.quantity_in_stock === 0) ? <span style={{ color: mobileStyle.color[0] }}> Sorry this item is now sold out </span> : ""
                        }
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "column", gap: '17px' }}>
                      <div className="mobile_quantity_wrapper" >
                        <Tooltip title={(checkMaxQuantity(item)) ? "You have reached maximum quantity" : ""}>
                          <span className="increment_btn" style={{ backgroundColor: (checkMaxQuantity(item) ? "gray" : "var(--shady-white)") }} onClick={() => { handleQtyIncrement(item) }}>
                            <AddIcon style={{ color: primaryColor, fontSize: "1rem" }} />
                          </span>
                        </Tooltip>

                        <span className="quantity_text" style={{ fontSize: "1rem", fontWeight: "600" }}>
                          {item.quantity}
                        </span>
                        <span className="increment_btn" onClick={() => handleQuantityDecrement(item)}>
                          <RemoveOutlinedIcon style={{ color: primaryColor, fontSize: "1rem" }} />
                        </span>
                      </div>
                      <button
                        className="remove-btn"
                        onClick={() => handleRemove(item.id, i)}>
                        <img src={newDeleteIcon} alt="" />
                      </button>
                    </div>
                  </NewCardLayout>

                </React.Fragment>
              );
            })
          )}
          {
            (cartItems.length === 0) ? null : (price <= 0) ?              //condetion satisfy when there is only sold out items in the cart
              <div className="emptyContainerSoldItems" ><ShopMore /></div> :
              <div className="summary_container" >
                <div className="bag-order-summary">
                  <div>
                    <div className="order-details">
                      <h4 style={{ fontFamily: "alternategotno1dregular", lineHeight: "27px", fontWeight: "bold", fontSize: '28px', letterSpacing: '0.5px' }}>ItemTotal</h4>
                      <p>${(Number(price).toFixed(2))}</p>
                    </div>
                    <div className="order-details">
                      <h4 style={{ fontFamily: "alternategotno1dregular", lineHeight: "27px", fontWeight: "bold", fontSize: '28px', letterSpacing: '0.5px' }} >Amount Donated</h4>
                      <p>${calculateTotalDonation().toFixed(2) || 0}</p>
                    </div>
                    <div className="order-details">
                      <h4 style={{ fontFamily: "alternategotno1dregular", lineHeight: "27px", fontWeight: "bold", fontSize: '28px', letterSpacing: '0.5px' }} >Shippinp</h4>
                      <p>$9.99</p>
                    </div>
                    <div className="order-details">
                      <h4 style={{ fontFamily: "alternategotno1dregular", lineHeight: "27px", fontWeight: "bold", fontSize: '28px', letterSpacing: '0.5px' }} >Tax ({tax?.percentage || '0'}%)</h4>
                      <p>${tax?.amount || 0 }</p>
                    </div>
                    <div className="order-details">
                      <h4 style={{ fontFamily: "alternategotno1dregular", lineHeight: "27px", fontWeight: "bold", fontSize: '28px', letterSpacing: '0.5px' }} >Processing Fees (3%)</h4>
                      <p>${Number(calcFinalPrice((Number(price).toFixed(2) - Number(discountPrice).toFixed(2)).toFixed(2), 9.99, tax).proccessingFees).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="bag-summary-grid">
                    <div>
                   
                      {currentDiscountFromAuction[0] &&
                        <div style={{ fontSize: "1.2rem", fontWeight: '600', color: '#FC3300' }}>
                          <div className="cart_subtotal_heading">{showDiscountPercentage ? `${currentDiscountFromAuction[0]}% Flash Sale Discount` : 'Total Flash Sale Extra Savings'}</div>
                          <div className="bag-card-price">${Number(discountPrice).toFixed(2)}</div>
                        </div>}
                      <div style={{ fontSize: "1.2rem", fontWeight: '600' }}>
                        <div className="cart_subtotal_heading">Subtotal</div>
                        <div className="bag-card-price">${Number(calcFinalPrice((Number(price).toFixed(2) - Number(discountPrice).toFixed(2)).toFixed(2), 9.99, tax).totalAmount).toFixed(2)}</div>
                      </div>
                      {/* {currentDiscountFromAuction[0] && 
            <div style={{fontSize: "1.2rem", fontWeight: '600', color:'#FC3300'}}>
              <div className="cart_subtotal_heading">Total Donation Impact</div>
              <div className="bag-card-price">${Number(donationImpact).toFixed(2)}</div>
            </div>} */}
                    </div>
                  </div>

                </div>

                {/* checkout and keepshoping buttons */}
              </div>

          }
        </div>
        <div className="mobile_cart_checkout_buttons_wrapper">
          {<NewButton text={'CHECKOUT'} onClick={cartItems.length > 0 ? checkout : null} backgroundColor={redColor} color={primaryBackgroundColor} disabled={cartItems.length === 0}
            style={{ opacity: cartItems.length === 0 ? 0.5 : 1,cursor: cartItems.length === 0 ? 'not-allowed' : 'pointer', width: '100%', border: '0px !important', fontSize: '22px', fontFamily: 'alternategotno1dregular', lineHeight: "20px", paddingBlock: '9px', maxWidth: '370px' }} />}
          <NewButton text={'CONTINUE SHOPPING'} onClick={()=>handleContinueShoping('shop')} backgroundColor={primaryBackgroundColor} color={redColor}
            style={{ width: '100%', fontFamily: 'alternategotno1dregular', fontSize: '22px', lineHeight: "20px", paddingBlock: '9px', maxWidth: '370px' }} />
        </div>
        <NeedHelp style={{ marginTop: "60px" }} primaryColor={primaryColor} />
      </div>
      <div style={{ width: '100%', backgroundColor: '#F0E8E3' }}>
        {/* <MobileSubFooter needHelp style={{padding:'6vw 5vw 2vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
        <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

      </div>
      <ConfirmAlertLogin
        openConfirmAlert={openConfirmAlert}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
        context={{
          returnUrl: '/cart'
        }}
      />
    </div>
  );
};

export default CartOverView;
