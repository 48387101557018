import React from 'react';
import Grid from '@mui/material/Grid';
import './salepromo.css';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PromoImages from '../../../../icons/brands05.png';

const SalesPromo = () => {
  return (
    <div className="sale-promo">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <div className="sales-promo">
            <div className="promo-header">
              <h1 className="promo-title">You’ll Get Higher Sales - <br /> Guaranteed</h1>
              <p className="promo-description greyBoldText">
                Grow a new channel with lower CAC, higher AOV, and higher LTV customers.
              </p>
              <div className="promo-button">
                <button>Free Demo</button>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className='promo-features'>
            <div className=" promo-image">

              <img src={PromoImages} alt="" />
              {/* <div className="feature-item">
              <LocalPoliceIcon />
              <h3 className="feature-title">Guaranteed Results</h3>
              <p className="feature-description">Performance-based pricing</p>
            </div>
            <div className="feature-item">
              <StarBorderIcon />
              <h3 className="feature-title">Premium Support</h3>
              <p className="feature-description">Dedicated success team</p>
            </div>
            <div className="feature-item">
              <EmojiEventsIcon />
              <h3 className="feature-title">Proven System</h3>
              <p className="feature-description">Tested methodology</p>
            </div> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesPromo;
