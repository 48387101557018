
import React, { useContext, useEffect, useState } from "react";
import { fetchSavedCard, deletePaymentDetails } from "../../../../services/payment-services";
import AuthContext from "../../../../store/auth-context";
import { DeleteOutline } from "@mui/icons-material";
import { Button } from "@mui/material";
import DialogMui from "../../molecules/Dialog/Dialog";

const ShowPaymentMethods = ({paymentMethod, setPaymentMethod}) => {
  const authCtx = useContext(AuthContext);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);
  const { setCardId, setCusId } = useContext(AuthContext);

  useEffect(() => {
    if(!authCtx.isLoggedIn){
      return
    }
    (async () => {
    const cardDetails = async () => {
      if(authCtx.cusId &&authCtx.cardId){
        const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
        setCardDetails({
          name: card.name,
          last4: card.last4,
          brand: card.brand
        })
      }
    }
    cardDetails()  
  })()
  }, []);

  function handleDeletePayment() {
    deletePaymentDetails()
    setisModalOpen(true)
    setCardDetails(null)
    setCardId(null)
    setCusId(null)
  }

  const handleNavigation = () => {
    setisModalOpen(false)
  }

  return (
    <div className="container-payment-show">
      <div>
        <div  className='payment-card' style={{backgroundColor: "white"}}>
          <div className='bag_shipping_content' style={{ fontSize: "1rem" }}>
            <div>{`${cardDetails?.name || 'No Payment Method Found'}`}</div>
            <div>{`**** **** **** ${cardDetails?.last4 || '****'}`}</div>
            <div>{cardDetails?.brand || '****'}</div>
          </div>
          {cardDetails &&
          <div className='bag_shipping_edit' onClick={handleDeletePayment}>
            <DeleteOutline />
          </div>}
        </div>
      </div>
      { paymentMethod &&
      <div className="add-payment-method">
        <Button variant="contained" 
          sx={{
            backgroundColor: '#fff', color: '#ff0000', borderRadius: '20px', borderColor: '#ff0000', fontWeight: '600', width: 'inherit',
            '&:hover': {
              backgroundColor: '#ff0000',
              color: '#fff',
              borderColor: '#ff0000'
            }
          }}
          variant="outlined" onClick={() => setPaymentMethod(!paymentMethod)}>
          {cardDetails ? 'CHANGE PAYMENT METHOD' : 'ADD PAYMENT METHOD'}
        </Button>
      </div>}
      <DialogMui isOpen={isModalOpen} message={"Successfully Deleted Payment Method"} ButtonText={'Okay'} handleClick={handleNavigation} />
    </div>
  );
};

export default ShowPaymentMethods;