import React, { useContext, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import countryList from "react-select-country-list";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputMask from 'react-input-mask';

import Visa from "../../images/visa.png"
import Mastercard from "../../images/mastercard-logo.png"
import PayPal from "../../images/payPal.svg"
import Amex from "../../images/amex.png"

import "./AuthorizePayment.css";
import { createCard, fetchBillingAddress, fetchSavedCard, updateCard } from "../../services/payment-services";
import AuthContext from "../../store/auth-context";
import logger from "../../utils/logger";
import { Backdrop, CircularProgress, Dialog, DialogContent, DialogContentText } from "@mui/material";
import Alert from "./molecules/Alert";
import { AddPaymentInfo } from "../../utils/meta-pixel-tracking";
import {Helmet} from "react-helmet-async"
import userServices from '../../services/user-services';
import { State } from 'country-state-city';
import { Desktop, Mobile } from "./responsive/Responsive";
import Navbar from "../desktop/components/Navbar/Navbar";
import Header from "../desktop/components/Header/Header";
import Footer from "../desktop/components/Footer/Footer";
import SubNavbar from "../desktop/components/SubNavbar/SubNavbar";
import DialogMui from "./molecules/Dialog/Dialog";
import { useEffect } from "react";
import constants from "../../constants/en";
import { mobileStyle } from "./Home";
import { useMediaQuery } from "react-responsive";
import HeaderV2 from "./molecules/Header/HeaderV2";
import SubHeader from "./molecules/SubHeader/SubHeader";
import NewButton from "./atoms/NewButton/NewButton";
import NeedHelp from "./atoms/NeedHelp/NeedHelp";
import LoadingScreen from "./LoadingScreen/Loading";
import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import NewFooter from "./molecules/NewFooter/NewFooter";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";


const CaptureCardDetails = () => {
  const { state } = useLocation();
  const { setCardId, setCusId, isSavedShip, cusId, cardId,setIsSavedShip,userInfo,userId } = useContext(AuthContext);
  const { type, user_id, store_item_id, item_name, feat_img, description, price, product_sku_id, is_virtual, itemId, auctionId, causeId, charge_tax} = state;
  const [country, setCountry] = useState("US");
  const [addressState, setAddressState] = useState("CA");
  const [cardNymber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const fullNameRef = useRef('');
  const cardNumberRef = useRef('');
  const cvvRef = useRef('');
  const address1Ref = useRef();
  const address2Ref = useRef();
  const pincodeRef = useRef();
  const navigateTo = useNavigate();
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [city,setCity]=useState("");
  const [phone,setPhone]=useState("");
  const [shippingAddress,setShippingAddress]=useState(false)
  const [doneMessage, setDoneMessage] = useState(false)
  const [alertMsg, setAlertMsg] = useState(null)
  const [alert, setAlert] = useState(false)
  const authCtx = useContext(AuthContext);
  // const [cardDetails, setCardDetails] = useState({
  //   address_line1:'',
  //   address_line2:'',
  //   address_city:'',
  //   phone:null,
  //   address_zip:'',
  // })
  const [addressLine1,setAddressLine1] = useState("")
  const [addressLine2,setAddressLine2] = useState("")
  const [addressCity,setAddressCity] = useState("")
  const [addressZip,setAddressZip] = useState("")
  const [cardNumber2, setCardNumber2] = useState("")
 
  const primaryColor = mobileStyle.color[1];
  const primaryBackgroundColor = "var(--pure-white)";
  const redColor = mobileStyle.color[0];
  const isMobile = useMediaQuery({
    maxWidth: 768
  })

  const updatedState = (country) => {
    return State.getStatesOfCountry(country).map((s) => {
      return {
        label: s.name,
        value: s.isoCode,
      };
    });
  };

  useEffect(() => {
     fetchCardData() 
  },[])

  useEffect(()=>{
  // console.log({addressLine1,addressLine2,addressCity,addressZip,phone,cardNumber2})
  },[addressLine1,addressLine2,addressCity,addressZip,phone,cardNumber2])

  const fetchCardData = async() => {
    try{
      if (!cusId || !cardId) return
      setLoading(true)
      const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)
      // setCardDetails(card)
      setAddressLine1(card.address_line1)
      setAddressLine2(card.address_line2)
      setAddressCity(card.address_city)
      setPhone(card.phone)
      setAddressZip(card.address_zip)

      setAddressState(card?.address_state)
      setLoading(false)
    }
    catch(error){
      setAlertMsg('Something went wrong')
      setAlert(true)
    }
    finally{
      setLoading(false)
    }
  }


  // const handleDataChange = (e) => {
  //   setCardDetails({
  //     ...cardDetails,
  //     [e.target.name]:e.target.value
  //   })
  // }

  const primaryBtnHandler =async (e) => {
    e.preventDefault();
    const USZipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
    const newPhoneNumber = phone.replace(/-/g, '');
    // if (!pincodeRef.current?.value.match(USZipCodeRegex)) {
      if (!addressZip.match(USZipCodeRegex)) {
      setLoading(false);
      setAlert(true)
      setAlertMsg(constants.APP_COPY_INVALID_ZIP_CODE)
    }
    else {
      setAlert(false)
      setAlertMsg(null)
    if(shippingAddress){
      const obj = {
        to_firstname:userInfo.first_name,
        to_lastname: userInfo.last_name,
        address1:addressLine1,
        address2:addressLine2,
        city:addressCity,
        state:addressState,
        phone: newPhoneNumber,
        country: country,
        zip:addressZip, 
        
        // city:city,
        // zip:pincodeRef.current?.value,
        // address1:address1Ref.current?.value,
        // address2:address2Ref.current?.value,
      };
      const { data } = await userServices.postShippingAddress(
        userId,
        obj
      );
      setIsSavedShip(true)
    }

    const expiryDate = cardExpiry.split("/");
    setLoading(true)
    if (cusId && cardId) {
      updateCard({
        number: cardNumberRef.current?.value.replace(/-/g, ""),
        exp_month: expiryDate[0],
        exp_year: expiryDate[1],
        cvc: cvvRef.current?.value,
        name: fullNameRef.current?.value,
        address_line1:addressLine1,
        address_line2:addressLine2,
        address_country: country,
        address_state: addressState,
        address_zip:addressZip,
        address_city:addressZip ,
        phone:newPhoneNumber,
        oldCusID: cusId,
        oldCardID: cardId,
        // address_zip: pincodeRef.current?.value,
        // address_line1: address1Ref.current?.value,
        // address_line2: address2Ref.current?.value,
        // address_city: city,
      })
        .then((res) => {
          logger('./pages/components/AuthorizePayment.jsx', 'updateCard(): Res', res.data);
          if (res?.data?.success) {
            setCusId(res?.data?.cusId)
            setCardId(res?.data?.cardId)
            setLoading(false)
            setDoneMessage(true)
          }
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = err?.response?.data?.message || constants.APP_COPY_CARD_DETAILS_ERROR;
          setError(errorMessage)
          logger('./pages/components/AuthorizePayment.jsx', 'updateCard(): catch', err)
        });
    } else {
      createCard({
        number: cardNumberRef.current?.value.replace(/-/g, ""),
        exp_month: expiryDate[0],
        exp_year: expiryDate[1],
        cvc: cvvRef.current?.value,
        name: fullNameRef.current?.value,
        address_line1:addressLine1,
        address_line2:addressLine2,
        address_country: country,
        address_state: addressState,
        address_zip:addressZip,
        address_city:addressCity ,
        phone:newPhoneNumber
        // address_city: city,
        // address_line1: address1Ref.current?.value,
        // address_line2: address2Ref.current?.value,
        // address_zip: pincodeRef.current?.value,
      })
        .then((res) => {
          logger('./pages/components/AuthorizePayment.jsx', 'createCard(): Res', res.data);
          if (res?.data?.success) {
            setCusId(res?.data?.cusId)
            setCardId(res?.data?.cardId)
            setLoading(false)
            //payment tracking for meta pixel
            AddPaymentInfo()
            setDoneMessage(true)
          }
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = err?.response?.data?.message || constants.APP_COPY_CARD_DETAILS_ERROR;
          setError(errorMessage)
          logger('./pages/components/AuthorizePayment.jsx', 'createCard(): catch', err)
        });
    }
  }
  };

  const countrySelector = ({ target }) => {
    if (target?.value === "US") setCountry(target?.value);
    else setAlertMsg(constants.APP_COPY_COUNTRY_ERROR);
  };

  const handleExpiryDate = ({ target }) => {
    const { value } = target;
    // console.log(value,'card expiry value')
    if (value.length < 3) {
      if (value.length === 0) setCardExpiry("");
      const val = value.split("/")[0];
      const numVal = parseInt(val, 10);
      if (val.length === 1 && numVal > 1) setCardExpiry(`0${numVal}`);
      else if (val.length === 1) setCardExpiry(val);

      if (val.length === 2 && numVal > 12) setCardExpiry(`01/${numVal - 10}`);
      else if (val.length === 2) setCardExpiry(val);
    } else {
      const vs = value.split("/");
      if (vs.length < 2)
        setCardExpiry(`${value.slice(0, 2)}/${value.slice(2)}`);
      else
        parseInt(vs[0]) <= 12
          ? setCardExpiry(`${vs[0]}/${vs[1]}`)
          : setCardExpiry("");
    }
  };

  const blurHandler = ({ target }) => {
    const pattern = new RegExp(target.pattern);
    if (
      (target.required && !target.value.length) ||
      !pattern.test(target.value)
    )
      target.style.borderColor = "red";
  };

  const focusHandler = ({ target }) => {
    if (target.style.borderColor === "red")
      target.style.borderColor = "initial";
  };

  const handleNavigate = () => {
    if (type === 'buy_now') {
      if (is_virtual === 0) {
        navigateTo(`${isSavedShip ? '/bag' : '/shipping-info'}`, {
          state: {...state},
          replace: true
        });
      } else {
        navigateTo('/bag', {
          state: { ...state },
          replace: true
        });
      }
    }
    else if (type === 'bid'){
      if(isSavedShip) {
        navigateTo(`/lot/${itemId}/place-bid?cause_id=${causeId}&auction_id=${auctionId}`, {
          state: { type: 'bid', itemId, auctionId, causeId,cause_id:state?.cause_id },
          replace: true
        })
      } else {
        navigateTo('/shipping-info', { state: { type: "bid", itemId, causeId, auctionId,cause_id:state?.cause_id }, replace: true })
      }
    }
    else if (type === "add") navigateTo("/account")
    else if (type === 'from-bag') navigateTo(-1, {replace: true})
    else navigateTo(-1, {replace: true})
  }

  const myInput=useRef()
  const preventScroll=(e)=>{
    if(myInput.current){
      myInput.current.blur()
    }
    if(pincodeRef.current){
      pincodeRef.current.blur()
    }
  }

  return (
    <div style={{height:"100%",backgroundColor:primaryBackgroundColor}}>
      <Helmet>
        <title>Payment Information | Boundless Giving</title>
      </Helmet>
      <Alert open={!!error} message={error} handleConfirm={() => setError(null)} />
      {
        loading && <LoadingScreen/>
      }

           {/* <HeaderV2 isSubHeader={true} subHeaderTitle="Payment Methods" logoType={isMobile?'black2':'black'} primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} containerStyle={{position:"fixed"}}/> */}
           <HeaderV3 isSubHeader subHeaderTitle="Payment Methods" primaryBackgroundColor={primaryBackgroundColor} primaryColor={primaryColor} position="absolute"/>

     {
      doneMessage ? 
      <div>
        {/* <Dialog
          open={doneMessage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ fontFamily: "AvenirNext" }}>
            <DialogContentText textAlign="left">
              <p className='alert-login-signup'>
                Successfully Updated Payment Method.
              </p>
              <PrimaryBtn
                  text="Okay"
                  handleClick={handleNavigate}
              />
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
        <DialogMui isOpen={doneMessage} message={"Successfully Updated Payment Method."} ButtonText={'Okay'} handleClick={handleNavigate} />
      </div>
      : (
        <>
           <main style={{width:isMobile?"":"45vw",backgroundColor: primaryBackgroundColor, color: primaryColor,margin:isMobile?"":"none",marginTop:"30px",marginBottom:isMobile?"":"0"}} className={`capture-card-details ${!isMobile && "margin_class"}`}>
              {/* <section className="item-details">
                <header className="capture-card-details__section__heading">
                  Item Details
                </header>
              </section> */}
              <form onSubmit={primaryBtnHandler} className="capture-card-form-container">
                <div>
                <section id="payment-details">
                  <header style={{marginBottom:isMobile ? "15px" : "28px"}} className="capture-card-details__section__heading">
                    Payment Details
                  </header>
                  {type === 'bid' && (
                    <>
                      <p style={{ margin: 0, fontSize: "0.8rem",  }}>Before confirming your bid, we need to confirm your payment information.
                        But don't worry, <span style={{ fontWeight: "600" }}>YOUR CARD WILL NOT BE CHARGED UNLESS YOU WIN THE AUCTION.</span>
                      </p>
                      <hr />
                    </>
                  )}
                  <div className="card-icons-container">
                    <Desktop><div style={{ fontSize: "0.8rem", fontWeight: "600" }}>We accept:</div></Desktop>
                    <div className="card-icons visa_container">
                      <img className='visa_img' src={Visa} alt="" />
                    </div>
                    <div className="card-icons  master_container">
                      <img className='master_card_img' src={Mastercard} alt="" />
                    </div>
                    {/* <div className="card-icons">
                      <img src={PayPal} alt="" />
                    </div> */}
                    <div className="card-icons amex_container">
                      <img className='amex_img' src={Amex} alt="" />
                    </div>
                  </div>

                  <div style={{width:"100%"}}>
                    <label style={{color:primaryColor}} className="input_label" htmlFor="">Name on Card</label>
                  <input
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    type="text"
                    name="full-name"
                    autoComplete="off"
                    placeholder="Full name on card"
                    required
                    ref={fullNameRef}
                    className='form_input'
                    style={{border: `1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor}}
                  />
                  </div>
                  {/* <input
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    type="text"
                    name="credit-card"
                    autoComplete="card number"
                    placeholder="Card number"
                    inputMode="numeric"
                    pattern="[0-9]{8,19}"
                    required
                    ref={cardNumberRef}
                  /> */}
                  <div style={{width:"100%"}}>
                  <label style={{color:primaryColor}} className="input_label" htmlFor="">Card Number</label>
                  <InputMask
                    mask={
                      (cardNumber2.length > 1 && (cardNumber2.slice(0, 2) === '37' || cardNumber2.slice(0, 2) === '34'))
                        ? '9999-999999-99999'
                        : '9999-9999-9999-9999'
                    }
                    placeholder="Card number"
                    maskChar=""
                    ref={cardNumberRef}
                    onChange={e => setCardNumber2(e.target.value)}
                    className='form_input'
                    style={{border:`1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor}}
                  >
                  </InputMask>
                  </div>
                  <div style={{width:'65%'}}>
                  <label style={{color:primaryColor}} className="input_label" htmlFor="">Expiration Date</label>
                  <div className="card-secure-info">
                    <input
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      className="card-secure-info__input"
                      type="tel"
                      name="expiry-date"
                      placeholder="MM/YY"
                      maxLength={5}
                      pattern="[0-9]{2}/[0-9]{2}"
                      required
                      value={cardExpiry}
                      onChange={handleExpiryDate}
                      style={{border:`1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor}}
                    />
                    <input
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      className="card-secure-info__input"
                      type="text"
                      name="cvc"
                      placeholder="CVC"
                      pattern="[0-9]{3,}"
                      inputMode="numeric"
                      size={3}
                      required
                      ref={cvvRef}
                      style={{border:`1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor}}
                    />
                  </div>
                  </div>
                </section>
                <section id="billing-address" style={{marginBottom:"0px", marginTop:isMobile && "30px"}}>
                  <h5  className="capture-card-details__section__heading">
                    Billing Address
                  </h5>
                  <input
                    required
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    type="text"
                    // name="address"
                    name="address_line1"
                    autoComplete="off"
                    placeholder="Address Line 1"
                    // ref={address1Ref}
                    value={addressLine1}
                    onChange={(e)=>setAddressLine1(e.target.value)}
                    className='form_input'
                    style={{border:`1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor}}
                  />
                  <input
                    onBlur={blurHandler}
                    onFocus={focusHandler}
                    type="text"
                    // name="address"
                    name="address_line2"
                    autoComplete="off"
                    placeholder="Address Line 2"
                    // ref={address2Ref}
                    value={addressLine2}
                    onChange={(e)=>setAddressLine2(e.target.value)}
                    className='form_input'
                    style={{border: `1px solid ${primaryColor}`, backgroundColor:primaryBackgroundColor}}
                  />
                  <input
                    type="text"
                    // name="city"
                    name="address_city"
                    // value={city}
                    autoComplete="off"
                    placeholder="City"
                    required
                    // onChange={(e) => setCity(e.target.value)}
                    value={addressCity}
                    onChange={(e)=>setAddressCity(e.target.value)}
                    className='form_input'
                    style={{border: `1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor}}
                  />
                  <FormControl sx={{ m: 0, width: 1, flex: 1 }} style={{marginBottom:"25px",border:"none"}}>
                      {/* <Desktop>
                      <InputLabel id="select-country">
                        State
                      </InputLabel>
                      </Desktop> */}
                      <Select
                        required
                        labelId="select-state"
                        id="demo-simple-select"
                        value={addressState}
                        // value={cardDetails.address_state }
                        label="State"
                        onChange={(e)=>{
                          setAddressState(e.target.value)
                        }}
                        // onChange={handleDataChange}
                        // name='address_state'
                        sx={{ 
                          height: 48, 
                          borderRadius:'8px',
                          border:`1px solid ${primaryColor}`, 
                          backgroundColor: primaryBackgroundColor 
                        }}
                      >
                     <MenuItem disabled value=''>
                                <span
                                  style={{
                                    color: primaryColor,
                                    // fontFamily: 'AvenirNext',
                                    fontSize: '14px',
                                  }}
                                >
                                  Select State
                                </span>
                              </MenuItem>

                        {country
                        ? updatedState(country).map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))
                        : []}
                      </Select>
                    </FormControl>
                  {/* <input
                    type="number"
                    name="phone"
                    // value={phone}
                    value={ cardDetails?.phone}
                    autoComplete="phoneNumber"
                    placeholder="Phone Number"
                    required
                    // onChange={(e) => setPhone(e.target.value)}
                    onChange={handleDataChange}
                    ref={myInput}
                    onWheel={preventScroll}
                  /> */}
                  <InputMask
                    mask="999-999-9999"
                    placeholder="Phone Number"
                    maskChar=""
                    value={phone}
                    required
                    className='form_input'
                    name="phone"
                    onChange={e => setPhone(e.target.value)}
                    style={{border: `1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor }}
                    autoComplete="off"
                  />
                  <div className="card-secure-info">
                  <input
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      className="card-secure-info__input"
                      type="number"
                      // name="pin-code"
                      name="address_zip"
                      placeholder="ZIP Code"
                      maxLength={6}
                      inputMode="numeric"
                      // ref={pincodeRef}
                      onWheel={preventScroll}
                      required
                      value={addressZip}
                      onChange={(e)=>setAddressZip(e.target.value)}
                      style={{border: `1px solid ${primaryColor}`, backgroundColor: primaryBackgroundColor}}
                    />
                    <FormControl sx={{ m: 0, width: 1, flex: 1 }}>
                     {/* <Desktop>
                     <InputLabel id="select-country">
                        Country
                      </InputLabel>
                     </Desktop> */}
                      <Select
                        // labelId="select-country"
                        inputProps={{ 'aria-label': 'Without label' }}
                        displayEmpty
                        id="demo-simple-select"
                        value={country}
                        // label="Country"
                        onChange={countrySelector}
                        sx={{ 
                          height: 48, 
                          borderRadius:'8px',
                          border: `1px solid ${primaryColor}`, 
                          backgroundColor: primaryBackgroundColor 
                        }}
                      >

                     <MenuItem disabled value=''>
                                <span
                                  style={{
                                    color: primaryColor,
                                    // fontFamily: 'AvenirNext',
                                    fontSize: '12px',
                                  }}
                                >
                                  Select Country
                                </span>
                              </MenuItem>

                        {countryList()
                          .getData()
                          .map((item) => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  
                </section>
                <div style={{height:"35px",display:"flex",justifyContent:"flex-start",alignItems:"center",marginBottom:"25px"}}>
                <input type="checkbox" 
                style={{marginBottom:"0px",marginRight:"10px", width:'auto', borderRadius:'4px'}} 
                onChange={()=>{
                setShippingAddress(!shippingAddress)
                }}/>
                <label className="shipping-address-label">Use this as shipping address</label>
                </div>
                </div>
                <div>
                  {/* <Desktop>
                  <PrimaryBtn
                    type="submit"
                    text="Save Details and Continue"
                  />
                  <PrimaryBtn
                    style={{
                      backgroundColor: "white",
                      color: "#1A385C",
                      border: "1px solid #1A385C",
                      fontWeight: "600",
                      marginTop: "15px"
                    }}
                    text="Cancel"
                    handleClick={() => navigateTo(-1, { replace: true })}
                  />
                  </Desktop> */}
                    <NewButton type="submit" text={'SAVE'} backgroundColor={primaryBackgroundColor} color={redColor} style={{paddingBlock:'7px', width:isMobile?"100%":"40%", marginTop:'10px'}} />
                    <NeedHelp primaryColor={primaryColor} style={{marginTop:'50px',paddingBottom:"25px"}} />

                </div>
              </form>
            </main>
        </>
      )
     }
        {/* <MobileSubFooter needHelp style={{padding:'6vw 5vw 2vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
        <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />


     <DialogMui isOpen={alert} message={alertMsg} ButtonText={'Close'} handleClick={() => { (setAlert(false)) }} />
    </div>
  );
};

export default CaptureCardDetails;
