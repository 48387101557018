import { Backdrop, CircularProgress, Pagination } from '@mui/material';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { mobileStyle } from '../../Home';


const BrowsePagesCardSection = ({ params, primaryColor, primaryBackgroundColor = mobileStyle.backgroundColor[2], isLoading = false, children, containerStyle = {} }) => {
  const forMobile = useMediaQuery({
    maxWidth: 768
  })
  return (
    <>
    
      <div className="dbrowse-container"
        style={{
          backgroundColor: primaryBackgroundColor,
          padding: forMobile ? "5vw" : "0",
          ...containerStyle
        }}
      >
        {
          isLoading ?
            <div className='cards_loading_container' >
              {/* <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              </Backdrop> */}

              <CircularProgress color="inherit" />
            </div>
            :
            <>
              {children}
            </>
        }
      </div>
    </>
  )
}

export default BrowsePagesCardSection;