import React, { Fragment, useEffect, useState } from 'react';

import { Desktop, Mobile } from '../../../responsive/Responsive';
import { Link, useNavigate } from 'react-router-dom';
import donorServices from '../../../../../services/donor-services';
import style from './BrowsByDonor.module.css';
import logger from '../../../../../utils/logger';
import Heart from '../../../../../images/newHomeImages/heart.png'
import { clearAllFilter } from '../../../../../redux/shopNowFilterReducer';
import { useDispatch } from 'react-redux';

function BrowsByDonor({title, containerStyle, mobileStyle={backgroundColor:[], color:[]}}) {
    const navigate = useNavigate()
    const [featuredDonors, setFeaturedDonors] = useState([])
    const mobileBackground = mobileStyle.backgroundColor[1]
    const mobileColor = mobileStyle.color[2]
     const dispatch = useDispatch()
    const getFeaturedDonors = async () => {
        try {
            const data = await donorServices.featuredDonors();
            setFeaturedDonors(data?.data?.donor)
        } catch (error) {
            logger('./pages/components/Home.jsx', 'getFeaturedDonors(): catch', error);
            //   setIsLoading(false);
        }
    }
    useEffect(()=>{
        getFeaturedDonors()
    },[])

    // const navigateDonor=(donor)=>{
    //     const donorImage = donor?.auctions[0]?.image1
    //     const market_place = (donor?.auctions[0]?.market_place === "1") ? true : false
    //     const title = donor?.auctions[0]?.title
    //     const about = donor?.auctions[0]?.about
    //     navigate("/browse-items", {
    //         state:
    //             { from: "donor", donor: donor, donorImage,market_place,title,about}
    //     })
        

    // }

    const navigateDonor = (donor) => {
         dispatch(clearAllFilter())
        navigate(`/shop-now`, { state: { donor } }
        //  navigate(`/shop-now/${donor?.auctions[0]?.custom_url ? donor?.auctions[0]?.custom_url : donor?.auctions[0]?.id}`,
        //   {state:{ auction : donor?.auctions[0], image: donor?.auctions[0].image2, title:donor?.auctions[0].title, from: 'donor',market_place:true }}
          )
    }

    if (featuredDonors.length === 0) {
        return null
    }
    return (
        <>
            <Desktop>
                {/* <section style={{...containerStyle, padding: "10px 0"}}>
                    <div className={`${style.dsection} ${style.dcurrent_events} ${style.dcategories}`}>
                        <h3>{title}</h3>
                        <div className={style.dbrowse_cards}>
                            {
                                featuredDonors.map((donor, i) => {
                                    return (
                                        <BrowseByCards
                                            onClick={() => navigateDonor(donor)}
                                            image={donor.featured_icon}
                                            useFolder={false}
                                            name={donor?.org_name}
                                            key={donor.id}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </section> */}
              
            </Desktop>
            <section className={style.desktop_section}>
                <div className={`margin_class ${style.desktop_container}`}>
                    <div className={style.DonorHeader}>
                        <h2 className="headingH2">BRANDS YOU <img src={Heart} width={60} /></h2>
                        <p className="sectionSubtitle brandSubtitle">shop 50+ global brands where 50-80% of all sales go directly to charity</p>
                        </div>
                    <section className="clearfix">
                        <div style={{margin:'0'}} className="categories_list">
                            <div style={{margin:'0'}} className={style.category_container_donor}>
                                {featuredDonors.map((donor, idx) => (
                                    <Fragment key={idx}>
                                    <div
                                        style={{ cursor: "pointer", alignSelf:"center" }}
                                        key={idx}
                                        onClick={() =>navigateDonor(donor)}
                                    >
                                        <img
                                            src={donor?.featured_icon}
                                            loading='lazy'
                                            alt=""
                                            style={{ border:`1px solid ${mobileColor}`, borderRadius: "20px",objectFit:'cover',aspectRatio:'1 / 1' }}
                                        />
                                        <p style={{color:mobileColor}} className={style.donor_name} > {donor.org_name} </p>
                                    </div>
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* <div className={style.bottomLink}>
                        <Link to={'/'} >and more</Link>
                    </div> */}
                </div>
            </section>
            
            {/* <Mobile>
                <div style={{ paddingTop:'30px', backgroundColor:mobileBackground}} className="categories_sec">
                    <div className="page_heading" style={{ marginBottom: "5px" }} id="page_heading_coming_soon">
                        <h3 className={style.mobileHeading} style={{  fontWeight: 800, color:mobileColor, width:"80%" }}>{title}</h3>
                    </div>
                    <section className="scrollCategory" style={{marginTop:"20px !important"}}>
                        <div className="categories_list">
                            <div className={style.category_container_donor}>
                                {featuredDonors.map((donor, idx) => (
                                    <div
                                        style={{ cursor: "pointer" }}
                                        key={idx}
                                        onClick={() =>navigateDonor(donor)}
                                    >
                                        <img
                                            src={donor?.featured_icon}
                                            loading='lazy'
                                            alt=""
                                            style={{ border:`1px solid ${mobileColor}`, borderRadius: "20px",objectFit:'cover',aspectRatio:'1 / 1' }}
                                        />
                                        <h5 style={{color:mobileColor}} className={style.donor_name} > {donor.org_name} </h5>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </Mobile> */}
        </>
    )
}

export default BrowsByDonor