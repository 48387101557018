import React, { useEffect, useState } from "react";
import { Desktop, Mobile } from "../../responsive/Responsive";
import { useNavigate } from "react-router-dom";
import classes from './BrowseItemsBanner.module.css'
import ImageSkelton from "../../molecules/ImageSkelton/ImageSkelton";
import { Grid } from "@mui/material";



const BrowseItemsBanner = ({ image, primaryColor = "", heading = "", title = "", about = "", bannerTextClass = 'mp-banner-text', gradientClass = "", children, childrenContainerStyle = {}, fromEvent = false, params = {}, desktopImageStyle = {}, mobileImageStyle = {}, mobileHeadingStyle = {}, aboutStyle = {}, dbBoxColor = null, needBox = false, showIncomplete = false, lowQualityImage = null, imageStyle = {}, showDetailsOverBanner = true, skeltonStyle = {} }) => {

  const [imageLoaded, setImageLoaded] = useState(false);

  let customStyle = {}
  if (needBox) {
    customStyle = {
      backgroundColor: dbBoxColor,
      borderRadius: '25px'
    }
  }

  const navigate = useNavigate();

  const handleEventOverview = () => {
    if (params?.is_store == '1')
      return navigate(`/store-overview/${params?.id}`, { state: { auctionDetails: params, showIncomplete: showIncomplete ? true : false } })
    if (params?.market_place == '1')
      return navigate(`/marketplace-overview/${params?.id}`, { state: { auctionDetails: params, showIncomplete: showIncomplete ? true : false } })
    navigate(`/event-overview/${params?.id}`, { state: { auctionDetails: params, showIncomplete: showIncomplete ? true : false } })
  }

  const handleCauseOverview = () => {
    if (params.itemType === 'marketplace') return navigate(`/about-donor/${params?.donor_id}`)
    navigate(`/about-cause/${params?.cause_id}?auction_id=${params?.id}`, { state: { auctionDetails: params } })
  }

  return (
    <>
      {/* New */}
      <Desktop>
        <div className="mp_desktop_banner_container">
          {/* <div className="mp_desktop_banner_main" style={{ color: "white", position: 'relative', ...desktopImageStyle }}> */}
          {
            params?.causeDetails?.id && params?.ui === "flash_sale" ?

              <div className={classes.campaign_banner}>
                <Grid container
                  direction={{ xs: "column", md: "row" }}
                >
                  <Grid sx={12} md={6}>
                    <div className={classes.inner_content} style={{
                      backgroundImage: `url(${!imageLoaded && lowQualityImage ? lowQualityImage : image})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      padding: "40px",
                      minHeight: '510px'
                    }}>
                      <h1 className={classes.param_title}>{params.title}</h1>
                      <div className={classes.about_causes_btn}>
                        <button onClick={() => handleEventOverview(params)} style={{ backgroundColor: '#eff0f3', color: '#000' }}>ABOUT THIS DONOR</button>
                      </div>
                    </div>
                  </Grid>
                  <Grid sx={12} md={6}>
                    <div className={classes.inner_content} style={{
                      backgroundImage: `url(${params?.causeDetails?.images[0]})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "end",
                      padding: "40px",
                      minHeight: '510px'
                    }}>
                      <img src={`https://bg-assets-bucket-stage.s3.us-east-1.amazonaws.com/${params?.causeDetails?.cause_logo}`} alt="Cause Connection Logo" style={{ width: '200px', marginBottom: '60px' }} />

                      <div className={classes.about_causes_btn}>
                        <button onClick={handleCauseOverview} style={{ backgroundColor: '#eff0f3', color: '#000' }}>ABOUT THIS CAUSE</button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div> :

              <div className={classes.bannerContentSection}>
                <img
                  src={!imageLoaded && lowQualityImage ? lowQualityImage : image}
                  alt=""
                  //   className='low_quality_banner_image'
                  className={classes.bannerImage}
                  style={{ display: imageLoaded ? 'block' : 'none', ...imageStyle }}
                  onLoad={() => setImageLoaded(true)}
                />

                {
                  !lowQualityImage &&
                  <ImageSkelton loadingState={!imageLoaded} style={{ ...skeltonStyle }} />
                }

                {
                  showDetailsOverBanner &&
                  <>
                    <div className={`${classes.absoluteGradientDiv} ${gradientClass}`}></div>
                    <div className={`mp_desktop_gradient_section ${needBox && 'box_padding_class'} ${classes.newGradientSectionClass}`} >
                      <div style={{ ...childrenContainerStyle, ...customStyle, height: !needBox && '100%' }} className={`mp_desktop_banner_text margin_class ${needBox ? 'custom_padding_class' : ''} ${classes.newBannerTextClass}`}>
                        {children ? children :
                          <>
                            <div className='mp_desktop_about_container'>
                              <h1 style={{ color: primaryColor }} className="mp_desktop_heading">{heading} </h1>
                              <hr style={{ width: "100%", border: `1px solid ${primaryColor}` }} />
                            </div>
                            <div className='mp_desktop_about_container'>
                              <h5 style={{ color: primaryColor }} className="mp_desktop_sub_heading"> {title} </h5>
                              {about !== '' && fromEvent ? <p style={{ color: primaryColor, cursor: "pointer", ...aboutStyle }} className="mp_desktop_sub_text" onClick={() => handleEventOverview(params)}>{`${about.slice(0, 68)} ...`}</p> : <p style={{ color: primaryColor, ...aboutStyle }} className="mp_desktop_sub_text">{about} </p>}
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </>
                }
              </div>
          }
        </div>
        {/* </div> */}
      </Desktop>
      <Mobile>
        <div className="mp-banner-container" style={{ backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center', color: "white", ...mobileImageStyle }}>
          {
            (!imageLoaded && lowQualityImage) &&
            <img
              src={lowQualityImage}
              alt=""
              className='low_quality_banner_image'
            />
          }
          {
            showDetailsOverBanner &&
            <div className={`${bannerTextClass} ${gradientClass}`}>
              <div style={{ ...customStyle }} className={`mp-banner-text_wrapper ${needBox && 'custom_box_mobile'}`}>
                <div>
                  <h1 className="heading" style={{ borderBottom: `1px solid ${primaryColor}`, color: primaryColor, ...mobileHeadingStyle }}>{heading} </h1>
                </div>
                <div style={{ width: !needBox && '85%', gap: '0' }}>
                  <div style={{ color: primaryColor }} className="sub-heading">{title}</div>
                  {about !== '' && fromEvent ? <p style={{ color: primaryColor, cursor: "pointer" }} onClick={() => handleEventOverview(params)} className="sub-text">{`${about.slice(0, 68)} ...`} </p> : <p style={{ color: primaryColor }} className="sub-text">{about} </p>}
                </div>
              </div>
            </div>
          }
        </div>
      </Mobile>
    </>
  )
}

export default BrowseItemsBanner