import React from "react";
import "./socialItem.css";
import  Grid  from "@mui/material/Grid";


const SocialItems = (props) => {
  return (
    <div className="social-container">
      <Grid container className="social-grid justify-content-between align-items-center">
        <Grid md={6} xs={12} className="social-image-wrapper">
          <div className="social-image">
            <img src={props.image} alt="BG Turnkey Method" />
          </div>
        </Grid>
        <Grid md={4} sx={12} className="social-content-wrapper">
          <div className="social-content">
            <h1>
              <span className="highlight">Boundless Giving</span>{" "}
              <span className="text-black"> Does <br /> All The Work </span>
            </h1>
            <p className="greyBoldText">We’ve built a turnkey system to partner and ramp sales - you simply approve creatives for your brand and the nonprofit.</p>
            <div className="btn-holder">
              <button className="right_btn">Free Demo</button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SocialItems;
