import React from 'react'
import './mapSection.css'
import { Grid } from '@mui/material'
import MapImg from '../../../../icons/mapImage.png'

const mapSection = () => {
  return (
    <div>
      <div className='map-section'>
        <Grid container spacing={3}>
          <Grid item sx={12} md={7}>
            <div className="txt-holder">
              <h2>
                You'll Ignite Sales From <br /> Your Current Customers
              </h2>
              <p className='greyBoldText'>Increase LTV by 42% with your current customers and drive win-back sales with your lapsed customers.</p>
              {/* <div className='three-cards'>
                <div className='c-card'>
                  <div className='s-icon'><TimelineIcon /></div>
                  <strong>42% LTV Increase</strong>
                </div>
                <div className='c-card'>
                  <div className='s-icon'><SupervisorAccountIcon /></div>
                  <strong>Higher Retention</strong>
                </div>
                <div className='c-card'>
                  <div className='s-icon'><strong>$</strong></div>
                  <strong>Increased AOV</strong>
                </div>
              </div> */}
              <div className="btn_div">
                <button className="right_btn">Free Demo</button>
              </div>
            </div>
          </Grid>
          <Grid item sx={12} md={5}>
            <div className='right-side'>
              <div className="img-holder">
                <img src={MapImg} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>


    </div>
  )
}

export default mapSection