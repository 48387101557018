import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import downArrow from "../../../../images/downArrow.svg";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import ClearIcon from "@mui/icons-material/Clear";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Slider from "@mui/material/Slider";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
import { FilterContext } from "../../../../store/filter-context"
// import GlobalHeader from "../../molecules/GlobalHeader";
import BrowseCard from "../../molecules/BrowseCard";
import "./BrowseItems.css";
import auctionServices from "../../../../services/auction-services";
import donorServices from "../../../../services/donor-services";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import logger from "../../../../utils/logger";
import BrowseItemUpperHalf from "../../molecules/BrowseItemUpperHalf/BrowsItemUpperHalf";
import { Helmet } from "react-helmet-async"
import { Mobile, Desktop } from "../../responsive/Responsive"
// import Navbar from "../../../desktop/components/Navbar/Navbar"
// import SubNavbar from "../../../desktop/components/SubNavbar/SubNavbar"
// import BackContainer from "../../../desktop/components/BackContainer/BackContainer"
// import Footer from "../../../desktop/components/Footer/Footer"
import { useDispatch, useSelector } from "react-redux"
import { changePageNumber, clearPageNumber, setContextFrom } from "../../../../redux/itemsReducer"
// import Header from "../../../desktop/components/Header/Header";
import Pagination from '@mui/material/Pagination';
import { useMediaQuery } from "react-responsive";
import HeaderV2 from "../../molecules/Header/HeaderV2";
import { mobileStyle } from "../../Home";
// import MobileSubFooter from "../../atoms/MobileSubFooter/MobileSubFooter";
import NewButton from "../../atoms/NewButton/NewButton";
import LoadingScreen from "../../LoadingScreen/Loading";
import BrowsePagesCardSection from "../../molecules/BrowsePagesCardSection/BrowsePagesCardSection";
import causeServices from "../../../../services/cause-services";
import { Carousel } from "react-responsive-carousel";
import NewFooter from "../../molecules/NewFooter/NewFooter";
import { ChevronLeft } from "@mui/icons-material";
import AdminPreviewBanner from "../../atoms/AdminPreviewBanner/adminPreviewBanner";
import { getDisplayPrice } from "../../../../utils/displayPrice";


const getAuctionItemsList = async (category_id, page, limit, auction_id) => {
  try {
    const response = await auctionServices
      .getAuctionItemsByCategoryId(page, limit, category_id, auction_id)
    return response
  } catch (err) {
    logger('./pages/components/BrowseItems.jsx', 'getAuctionItemsList(): catch2', err);
  }
};
const getAllBuyNowAuctionItems = async (page, limit) => {
  try {
    const response = await auctionServices.getAllBuyNowItems(page, limit)
    return response
  } catch (err) {
    logger('./pages/components/BrowseItems.jsx', 'getAllBuyNowAuctionItems(): catch2', err);
  }
};

const getAllOnlyAuctionItems = async (page, limit) => {
  try {
    const response = await auctionServices.getAllOnlyAuctionItems(page, limit)
    return response
  } catch (err) {
    logger('./pages/components/BrowseItems.jsx', 'getAllOnlyAuctionItems(): catch2', err);
  }
};

const getAllItemsByCauseId = async (cause_category_id, page, limit) => {
  try {
    const response = await auctionServices
      .getAllItemsByCauseCategory(page, limit, cause_category_id)
    return response
  } catch (err) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByCauseId(): catch2', err);
  }
};

const getAllItemsByAuctionId = async (id, page, limit) => {
  try {
    const response = await auctionServices.getAuctionItemsById(id, page, limit)
    return response
  } catch (err) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByAuctionId(): catch2', err);
  }
};

const getAllItemsByDonor = async (donorId, page, limit) => {
  try {
    const data = await donorServices.getDonorItemsById(donorId, page, limit)
    return data
  } catch (error) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByDonor(): catch', error);
  }
}

const checkIfGroupBy = (data) => {
  if (typeof data === 'object' && !Array.isArray(data) && data !== null) {

    return true
  }
  return false
}

const getAuctionItemByAuctionId = async (auctionId, page, limit) => {
  try {
    const data = await auctionServices.getAuctionItemByAuctionId(auctionId, page, limit)
    return data
  } catch (error) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByDonor(): catch', error);
  }
}
const getBuyNowItemsByAuctionId = async (auctionId, page, limit) => {
  try {
    const data = await auctionServices.getBuyNowItemsByAuctionId(auctionId, page, limit)
    return data
  } catch (error) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByDonor(): catch', error);
  }
}
const getItemsByCustomCategoryByAuctionId = async (auctionId, customCategoryId, page, limit) => {
  try {
    const data = await auctionServices.getItemsByCustomCategoryByAuctionId(auctionId, customCategoryId, page, limit)
    return data
  } catch (error) {
    logger('./pages/components/BrowseItems.jsx', 'getAllItemsByDonor(): catch', error);
  }
}

const getAllMarketPlaceItems = async (page, limit) => {
  try {
    const data = await auctionServices.fetchAllMarketPlaceItems(page, limit)
    return data
  } catch (error) {
    logger('./pages/components/BrowseItems.jsx', 'getAllMarketPlaceItems(): catch', error);
  }
}

const validTitle = (from, params = {}) => {
  switch (from) {
    case "buyNow":
      return "Browse Buy Now"
    case "causeCategory":
      return "Browse Cause"
    case "auctionCategory":
      return "Browse Categories"
    case 'event':
      return "Browse Event"
    case "donor":
      return `${params?.donor ? `${params?.donor?.org_name} Gives` : 'Browse Store'}`
    case 'auction':
      return "Browse Auction"
    default:
      return "Browse Event"
  }
}

const BrowseItems = ({ market_place = false, donor = null, auction, eventParams = null }) => {
  const [isFilterScreenOpen, setIsFilterScreenOpen] = useState(false);
  const { sortBy, groupBy, setGroupBy, setSortBy, priceRange, handlePriceChange, reset } = useContext(FilterContext)
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [updated, setUpdated] = useState(false)
  // const [page, setPage] = useState(1)
  const totalPages = useRef(1)
  const totalCount = useRef(0)
  const { pageNumber: page, contextFrom } = useSelector(state => state.items)
  const [oldItemList, setOldItemList] = useState([])
  const [itemList, setItemList] = useState(null)
  const sortByRef = useRef();
  const groupByRef = useRef();
  const [sort, setSort] = useState(false);
  const [causeData, setCauseData] = useState(null);
  const [landscapeImage, setLandscapeImage] = useState([]);
  const [isAboutCauseOpen, setIsAboutCauseOpen] = useState(true)
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  let params = useLocation().state;

  const forMobile = useMediaQuery({
    maxWidth: 768
  })
  const isDesktop = useMediaQuery({
    minWidth: 768
  })

  if (eventParams) {
    params = eventParams;
  }

  if (market_place && donor !== null) {
    params = {
      from: "donor",
      donor: donor,
      donorImage: auction?.image1,
      market_place: market_place,
      title: auction.title,
      about: auction.about,
      auction: { id: auction?.id },
      type: 'Marketplace'
    }
  }
  const navigate = useNavigate();
  let limit
  if (forMobile) {
    limit = 20
  }
  else if (isDesktop) {
    limit = 20
  }
  const paginationCount = Math.ceil(totalCount.current / limit)

  useEffect(() => {
    if (params.from === "auction" || params.from === "buyNow") {
      reset()
      if (contextFrom && contextFrom !== params.from) {
        dispatch(setContextFrom(params.from))
        dispatch(clearPageNumber())
      }
      // dispatch(resetItems())
      // dispatch(clearFilterItems())
      // dispatch(clearItems())
    }
  }, [params.from])

  const dispatch = useDispatch()

  // useEffect(() => {
  //   const pos = JSON.parse(localStorage.getItem("browseScroll"))
  //   if (pos) {
  //     setTimeout(() => window.scrollTo({ top: pos, behavior: "auto" }), 200)
  //     localStorage.removeItem("browseScroll")
  //   }
  // }, [])

  const handleSorting = (e) => { setSortBy(e.target.value); setSort(true) };
  useEffect(() => {
    if ((groupBy === "" || !groupBy) && params && params.from) {
      if (params.from === "auctionCategory") {
        setGroupBy("itemCategory")
      } else if (params.from === "causeCategory") {
        setGroupBy("causeType")
      } else if (params.from === "event-byNow") {
        setGroupBy("event")
      } else if (params?.from === "event-auction") {
        setGroupBy("event")
      } else {
        setGroupBy(params.from)
      }
    }
  }, [groupBy, setGroupBy])

  // useEffect(()=>{
  //   console.log("calling",params.type,'----------------',groupBy)
  //   if((params.type ==='Store' || params.type === 'Marketplace') && (groupBy === "" || !groupBy) ){
  //   handleFilterApply(false,"itemCategory");
  // }
  // },[])
  // useEffect(()=>{
  //   let id
  //   if(params.type === 'Store' || params.type === 'Marketplace'){
  //     console.log("sore ytjnn");
  //     id=setTimeout(()=>{ 
  //       console.log('set group by')
  //       setGroupBy("itemCategory")},1000)
  //   }
  //   return ()=>clearTimeout(id)
  // },[])


  const calculateLength = (obj) => {
    let length = 0
    Object.keys(obj).forEach(key => {
      length += obj[key].length
    })
    return length
  }

  const appendItems = (items) => new Promise((resolve, reject) => {
    setOldItemList(items)
    resolve()
  })

  const getPriceOfItem = (item) => {
    let price = item.price
    if (item.buy_now_price && +item.buy_now_price > 0) {
      price = +item.buy_now_price
    } else if (item.store_item) {
      price = getDisplayPrice(item)
    }
    return +price
  }

  const handleFilterApply = async (data = null, defaultGroupBy = null) => {
    let array = data ? data : oldItemList;
    const sortedValue = array.filter(
      (a) => {
        const price = getPriceOfItem(a)
        return price > priceRange[0] && price < priceRange[1]
      }
    );
    // const sortedValue = oldItemList
    const sortedObj = {}

    switch (sortBy) {
      case "priceLow2High":
        sortedValue.sort((a, b) => getPriceOfItem(a) - getPriceOfItem(b));
        break;
      case "priceHigh2Low":
        sortedValue.sort((a, b) => getPriceOfItem(b) - getPriceOfItem(a));
        break;
      case "RecentlyAdded":
        sortedValue.sort((a, b) => new Date(b.created) - new Date(a.created));
        break;
      default:
        sortedValue.sort((a, b) => a.display_order - b.display_order);
        break;
    }
    // defaultGroupBy uses when we want to group by in the begening.
    let groupByCase
    if (defaultGroupBy) {
      groupByCase = defaultGroupBy;
      setGroupBy(defaultGroupBy)
    } else {
      groupByCase = groupBy;
    }
    switch (groupByCase) {
      case "itemCategory":
        if (params?.from === 'auctionCategory') {
          // dispatch(setFilteredItems(sortedValue))
          setItemList(sortedValue)
          setIsFilterScreenOpen(false)
          return
        }
        sortedValue.forEach(value => {
          // if(value.auction_item_category?.name === 'NA') return
          // const category = value.auction_item_category?.name ? value.auction_item_category?.name : value.custom_category?.category_name;
          // console.log(category, "categories ise ise")
          // if (sortedObj.hasOwnProperty(category)) {
          //   sortedObj[category].push(value)
          // } else {
          //   sortedObj[category] = [value]
          // }
          // console.log(sortedObj, "sorted obj after")
          value?.auction_item_category_xrefs?.length &&
            value?.auction_item_category_xrefs?.map((cat) => {
              if (cat?.category?.name === 'NA') return
              const category = cat?.category_id ? cat?.category?.name : cat?.custom_category?.category_name
              if (sortedObj.hasOwnProperty(category)) {
                sortedObj[category].push(value)
              }
              else {
                sortedObj[category] = [value]
              }

            })
        })
        break;
      case "causeType":
        if (params?.from === 'causeCategory') {
          setItemList(sortedValue)
          // dispatch(setFilteredItems(sortedValue))
          setIsFilterScreenOpen(false)
          return
        }
        sortedValue.forEach(value => {
          let name;
          if (value.auction?.market_place == '1') {
            name = 'Market Place'
          }
          else {
            name = value.auction?.cause?.cause_category?.name
          }
          if (sortedObj.hasOwnProperty(name)) {
            sortedObj[name].push(value)
          } else {
            sortedObj[name] = [value]
          }
        })
        break;
      case "buyNow":
        if (params?.from === 'buyNow') {
          // dispatch(setFilteredItems(sortedValue))
          setItemList(sortedValue)
          setIsFilterScreenOpen(false)
          return
        }
        sortedValue.forEach(value => {
          if (!value.store_item && !value.buy_now_price) return
          let title;
          if (value.store_item) {
            title = "Buy Now"
          } else if (value.buy_now_price) {
            title = "Buy Now and Biddable"
          }
          if (sortedObj.hasOwnProperty(title)) {
            sortedObj[title].push(value)
          } else {
            sortedObj[title] = [value]
          }
        })
        break;
      case 'event':
        if (params?.from === 'event' || params?.from === 'event-auction' || params?.from === 'event-byNow') {
          // dispatch(setFilteredItems(sortedValue))
          setItemList(sortedValue)
          setIsFilterScreenOpen(false)
          return
        }
        sortedValue.forEach(value => {
          let title = value.auction.title
          if (sortedObj.hasOwnProperty(title)) {
            sortedObj[title].push(value)
          } else {
            sortedObj[title] = [value]
          }
        })
        break;

      case 'auction':
        if (params?.from === 'auction') {
          // dispatch(setFilteredItems(sortedValue))
          setItemList(sortedValue)
          setIsFilterScreenOpen(false)
          return
        }
        sortedValue.forEach(value => {
          if (!value.store_item_id) {
            if (value.buy_now_price) {
              if (sortedObj.hasOwnProperty('Buy Now and Biddable')) {
                sortedObj['Buy Now and Biddable'].push(value)
              } else {
                sortedObj['Buy Now and Biddable'] = [value]
              }
            } else {
              if (sortedObj.hasOwnProperty('Only Biddable Items')) {
                sortedObj['Only Biddable Items'].push(value)
              } else {
                sortedObj['Only Biddable Items'] = [value]
              }
            }
          }
        })
        break
      default:
        break;
    }
    // console.log(sortedValue, sortedObj)
    if (!sort) {
      // dispatch(setFilteredItems(sortedObj))
      setItemList(sortedObj)
    } else {
      // dispatch(setFilteredItems(sortedValue))
      setItemList(sortedValue)

    }
    setIsFilterScreenOpen(false)
  };
  const handleGrouping = (e) => { setGroupBy(e.target.value); setSort(false) };
  const clearFilters = () => {
    setSort(false)
    window.location.reload()
  }

  const filterScreen = () => {
    return (
      <div className="filter-screen">
        <div className="filter-screen-top margin_class">
          {/* <Desktop>
          <GlobalHeader
            addClasses="filter-heading"
            PrimaryBtnContent="Reset"
            primaryBtnCTA={clearFilters}
            title="Filters"
            iconColor="black"
            bgColor="white"
            secondaryBtnCTA={toggleFilterScreen}
            SecondaryBtnContent={ClearIcon}
          />
          </Desktop> */}
          {/* <Mobile> */}
          <div className="mobile_filter_header">
            <h5 style={{ color: mobileStyle.color[1] }} className="filters_heading" >Filters</h5>
            <h5 onClick={clearFilters} style={{ color: mobileStyle.color[0] }} className="reset_button">Reset</h5>
          </div>
          {/* </Mobile> */}
          <section className="filter-control-wrapper">
            <div className="filter-control-1">
              <div className="sort-by">
                <FormControl>
                  <FormLabel sx={{ color: mobileStyle.color[1], marginTop: '20px' }} className="filter-control-heading">
                    Sort By
                  </FormLabel>
                  <RadioGroup
                    value={sortBy}
                    onChange={handleSorting}
                    ref={sortByRef}
                    sx={{ gap: '7px' }}
                  >
                    <FormControlLabel
                      value="Recommended"
                      control={<Radio />}
                      label="Recommended"
                    />
                    <FormControlLabel
                      value="RecentlyAdded"
                      control={<Radio />}
                      label="Recently Added"
                    />
                    <FormControlLabel
                      value="priceLow2High"
                      control={<Radio />}
                      label="Price: Low to high"
                    />
                    <FormControlLabel
                      value="priceHigh2Low"
                      control={<Radio />}
                      label="Price: High to low"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="filter-control-2">
              <FormControl>
                <FormLabel sx={{ color: mobileStyle.color[1] }} className="filter-control-heading">
                  Group By
                </FormLabel>
                <RadioGroup
                  value={groupBy}
                  onChange={handleGrouping}
                  ref={groupByRef}
                  sx={{ gap: '7px' }}
                >
                  <FormControlLabel
                    value="itemCategory"
                    control={<Radio />}
                    label="Item Category"
                  />
                  <FormControlLabel
                    value="causeType"
                    control={<Radio />}
                    label="Cause Type"
                  />
                  <FormControlLabel
                    value="buyNow"
                    control={<Radio />}
                    label="Buy Now"
                  />
                  {(params?.from === 'buyNow' || params?.from === 'event-byNow') ? null :
                    <FormControlLabel
                      value='auction'
                      control={<Radio />}
                      label="Auction"
                    />}
                  <FormControlLabel
                    value='event'
                    control={<Radio />}
                    label="Event"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="filter-control-3">
              <FormLabel sx={{ color: mobileStyle.color[1] }} className="filter-control-heading price-range">
                Price Range
              </FormLabel>
              <div className="price-slider-wrapper">
                <Slider
                  value={priceRange}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={25000}
                  step={25}
                />
              </div>
              <div className="slider-labels">
                <span className="slider-label-item">${priceRange[0]}</span>
                <span className="slider-label-item">${priceRange[1]}</span>
              </div>
            </div>
          </section>
          <br />
          <section className="filter-apply-wrapper">
            {/* <Desktop>
            <button onClick={() => handleFilterApply()} className="apply-filter-btn">
              Apply
            </button>
            </Desktop> */}
            {/* <Mobile> */}
            <NewButton
              text={'APPLY'}
              onClick={() => handleFilterApply()}
              color={mobileStyle.color[0]}
              backgroundColor={mobileStyle.backgroundColor[2]}
              style={{
                width: isDesktop ? '50%' : '100%',
                fontSize: '16px',
                lineHeight: '16px',

                paddingBlock: '7px'
              }}
            />
            {/* </Mobile> */}
          </section>
        </div>
      </div>
    );
  };

  const toggleFilterScreen = () => {
    setIsFilterScreenOpen(!isFilterScreenOpen);
  };


  const fetchData = async (page = 1) => {
    setIsLoading(true)
    let response;
    switch (params.from) {
      case "buyNow":
        response = await getAllBuyNowAuctionItems(page, limit);
        setIsLoading(false)
        break;
      case "causeCategory":
        response = await getAllItemsByCauseId(params.cause_category_id, page, limit);
        setIsLoading(false)
        break;
      case "auctionCategory":
        response = await getAuctionItemsList(params.auction_category_id, page, limit);
        setIsLoading(false)
        break;
      case 'auction':
        response = await getAllOnlyAuctionItems(page, limit);
        setIsLoading(false)
        break
      case 'event':
        response = await getAllItemsByAuctionId(params?.auction?.id, page, limit);
        setIsLoading(false)
        break;
      case 'donor':
        // response = await getAllItemsByDonor(params?.donor?.id, page, limit)
        response = await getAllItemsByAuctionId(params?.auction?.id, page, limit);
        setIsLoading(false)
        break
      case 'event-auction':
        response = await getAuctionItemByAuctionId(params?.auction?.id, page, limit)
        setIsLoading(false)
        break
      case 'event-byNow':
        response = await getBuyNowItemsByAuctionId(params?.auction?.id, page, limit)
        setIsLoading(false)
        break
      case "event-category":
        response = await getAuctionItemsList(params.categoryId, page, limit, params?.auction?.id);
        setIsLoading(false)
        break;
      case 'event-customCategory':
        response = await getItemsByCustomCategoryByAuctionId(params?.auction?.id, params?.customCategoryId, page, limit)
        setIsLoading(false)
        break;
      case 'shopNow-marketplace':
        response = await getAllMarketPlaceItems(page, limit)
        setIsLoading(false)
        break;
      default:
        break;
    }

    return response;
  }


  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const response = await fetchData(page);
      if (response) {
        totalPages.current = response.total_page
        setOldItemList(response.data)
        setItemList(response.data)
        totalCount.current = response?.total_count
        const pos = JSON.parse(localStorage.getItem("browseScroll"))
        if (pos) {
          setTimeout(() => window.scrollTo({ top: pos, behavior: "smooth" }), 0)
          localStorage.removeItem("browseScroll")
        }
        //for marketplace and store group by itemCategory initially.
        if (params.type === 'Store' || params.type === 'Marketplace') {
          handleFilterApply(response.data, "itemCategory")
        }
      }
      setIsLoading(false)
    })()
  }, [params.from]);
  useEffect(() => {
    const getCauseInfo = async () => {
      try {
        await causeServices.causeInfo(params.auction?.cause_id).then(
          (response) => {
            setCauseData(response?.data);
            setLandscapeImage(response?.data?.images)
          },
          (error) => {
            setCauseData(null);
          }
        );
      } catch (err) {
        setCauseData(null);
      }
    };
    getCauseInfo();
  }, [params.auction?.cause_id]);

  const loadMore = async (e, paginationPage) => {
    if (oldItemList.length >= totalCount.current) return
    setIsLoadingMore(true)
    dispatch(changePageNumber(paginationPage))
    const newPage = paginationPage;
    const response = await fetchData(newPage)
    await appendItems(response.data)
    handleFilterApply(response.data)
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsLoadingMore(false)
  }

  const handleGoToEvent = () => {
    navigate(`/event/${params?.auction?.custom_url ? params?.auction?.custom_url : params?.auction?.id}`, { state: { from: 'event', admin_preview: params?.auction.status === 'building' ? true : false } })
  }

  const handleGoToShop = () => {
    navigate('/shop-now')
  }

  // useEffect(() => {
  //   if (typeof oldItemList === "object") setIsLoading(false);
  // }, [itemList]);

  function DonorAccrodian({ about }) {
    return (
      <Accordion className="don_auccor"
        defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          About This Donor
        </AccordionSummary>
        <AccordionDetails className="don_auccor_details">
          {about}
        </AccordionDetails>
      </Accordion>
    )
  }

  return isLoading ? (
    <LoadingScreen bgColor="#F0E8E3" />
  ) : (
    <>
      <Helmet prioritizeSeoTags>
        <title>{params && params.from ? validTitle(params.from, params) : "Browse Event"} | Boundless Giving</title>
      </Helmet>
      <div className="browse-page" style={{ minHeight: (forMobile) ? "100vh" : "", backgroundColor: mobileStyle.backgroundColor[2], justifyContent: (forMobile) ? 'space-between' : "" }}>

        <Mobile>
          {!market_place
            &&
            <HeaderV2
              primaryBackgroundColor={(params.from === "event" || params.from === "buyNow" || params.from === "auction" || params.from === "event-auction" || params.from === "event-byNow" || params.from === "auctionCategory" || params.from === "causeCategory" || params.from === "event-category" || params.from === 'event-customCategory' || params.from === "shopNow-marketplace") ? mobileStyle.backgroundColor[2] : "none"}
              logoType={params.from === "event" ? 'black2' : "white2"}
              primaryColor={(params.from === "event" || params.from === "auctionCategory" || params.from === "causeCategory" || params.from === "event-category" || params.from === 'event-customCategory' || params.from === "shopNow-marketplace") ? mobileStyle.color[1] : "white"}
              containerStyle={{ position: 'fixed' }}
              transparentNav
            />}
        </Mobile>
        {!market_place && <Desktop>
          {/* <Navbar />
          <SubNavbar />
          <Header title="Items" />
          <BackContainer className="browse-page-container" /> */}
          <HeaderV2
            primaryBackgroundColor={(params.from === "event" || params.from === "buyNow" || params.from === "auction" || params.from === "event-auction" || params.from === "event-byNow" || params.from === "auctionCategory" || params.from === "causeCategory" || params.from === "event-category" || params.from === 'event-customCategory' || params.from === "shopNow-marketplace") ? mobileStyle.backgroundColor[2] : "none"}
            logoType={params.from === "event" ? 'black' : "white"}
            primaryColor={(params.from === "event" || params.from === "auctionCategory" || params.from === "causeCategory" || params.from === "event-category" || params.from === 'event-customCategory' || params.from === "shopNow-marketplace") ? mobileStyle.color[1] : "white"}
            containerStyle={{ position: 'fixed' }}
            transparentNav
          />
        </Desktop>
        }
        {/* <div className={`browse-page-container`} style={{padding: (params.from === "donor" || "event-auction" || "event-byNow" ||  "auctionCategory") ? "0":"5vw 5vw" }}> */}
        {/* /// now changing for market place */}
        {!market_place && <BrowseItemUpperHalf params={params} />}

        {/*  the below one is to show cause details in browse item page */}
        {/* {
          ((!market_place && (params.from == 'event-byNow'))) && <div className="cause_container" ><p>{`${params.auction?.title} Benefit ${causeData.legal_name}!`}</p></div>
        } */}
        <div className="margin_class" style={{ backgroundColor: mobileStyle.backgroundColor[2] }}>

          {/* {!market_place && 
          // <Mobile>
            // <BrowseItemUpperHalf params={params} />
          // </Mobile>
          } */}
          {/* {params?.from === 'donor' && params?.donor?.about &&
            <DonorAccrodian
              about={params?.donor?.about}
            />} */}
          {/* <Mobile> */}
          {/* {params.from === 'buyNow' && <div className="buyNow-from-header">Items You Can Buy Now</div>} */}

          <div className="items-control" style={{ padding: forMobile && (params.from === "donor" || params.from === "event-auction" || params.from === "event-byNow" || params.from === "buyNow" || params.from === "auction" || params.from === "auctionCategory" || params.from === "causeCategory" || params.from === "event-category" || params.from === "event-customCategory" || params.from === "shopNow-marketplace" || params.type === 'Store') ? "5vw 5vw" : "0 0px" }}>

            {/* <Mobile> */}
            {
              !checkIfGroupBy(itemList) ? (
                <span className="items-count" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button onClick={() => { navigate(-1, { preventScrollReset: true }) }}><ChevronLeft style={{ color: 'rgba(252, 51, 0, 1)' }} /></button>

                  {totalCount.current} {`item${totalCount.current > 1 ? "s" : ""}`}
                </span>
              ) : (
                <span className="items-count">
                  <button onClick={() => { navigate(-1, { preventScrollReset: true }) }}><ChevronLeft style={{ color: 'rgba(252, 51, 0, 1)' }} /></button>

                  {calculateLength(itemList)} {`item${calculateLength(itemList) > 1 ? "s" : ""}`}
                </span>
              )
            }
            {/* </Mobile> */}
            <button className="filter-btn" onClick={toggleFilterScreen}>
              {/* <FilterAltIcon fontSize="small" />{" "} */}
              <span className="filter-btn__text">Filter By</span>
              <img
                src={downArrow}
                style={{ marginLeft: '5px', transform: isFilterScreenOpen && 'rotate(180deg)' }}
                alt="Arrow"
              />
            </button>
          </div>
          {/* </Mobile> */}
          {/* <div className="dbrowse-container" 
             style={{
                   backgroundColor:mobileStyle.backgroundColor[2] , 
                   paddingBlock: forMobile && (params.from === "event-category" || params.from === "event-customCategory" || params.from === "donor" || params.from === "event-auction" || params.from === "event-byNow" || params.from === 'buyNow' || params.from === "auction" ||  params.from === "auctionCategory" ||  params.from === "causeCategory" || params.from === 'event-customCategory' || params.from === 'event' || params.from === "event-customCategory" || params.from === "shopNow-marketplace" ) ? "5vw":"0",
                   paddingInline: ( forMobile && ( params.from === "event-category" || params.from === "event-customCategory" || params.from === "donor" || params.from === "event-auction" || params.from === "event-byNow" || params.from === 'buyNow' || params.from === "auction" ||  params.from === "auctionCategory" ||  params.from === "causeCategory" || params.from === 'event-customCategory' || params.from === 'event' || params.from === "event-customCategory" || params.from === "shopNow-marketplace") ) ? "5vw":"0",
                  }}
                   >
            {
              isLoading ? 
              <div>
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" />
                </div>
              </Backdrop>
            </div>
              : 
              <div className="dbrowse-items-list" style={{marginBottom:(market_place) ? "10px" : "10px"}}>
              {
                checkIfGroupBy(itemList) ?
                  (Object.keys(itemList).map(key => (
                    <div key={key}>
                      <h1 style={{ fontSize: "1.3rem", marginTop: "16px", marginLeft: "10px", fontWeight: "bold" }}>{key}</h1>
                      <hr />
                      <div className="browse-items">
                        {itemList[key].map((item, index) => (
                          <BrowseCard key={index} {...item} banner={item?.assets.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
                        )
                        )}
                      </div>
                    </div>
                  ))) : (
                      <>
                    <div className="browse-items">
                      {itemList?.map((item, index) => (
                        <BrowseCard key={index} {...item} banner={item?.assets.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
                      ))}
                    </div>
                      {itemList?.length < 1 && (
                        <div className="no-data" style={{paddingTop: "4vh",display: "flex",justifyContent:"center",alignItems: "center"}} >                          
                          Coming Soon!
                        </div>
                      )}
                      </>
                  )
              }
             {itemList?.length > 0 && ( <div style={{ fontSize: "1.1rem", marginTop: "30px", fontWeight: 600, display: 'flex', justifyContent: 'center',marginBottom:"50px" }} >
               {!isLoading && <Pagination
                  count={paginationCount}
                  onChange={loadMore}
                  page={page}
                />}
              </div> )} 
            </div>
            }             
              </div>  */}



          <BrowsePagesCardSection params={params} isLoading={isLoading}>
            <div className="dbrowse-items-list" style={{ marginBottom: (market_place) ? "10px" : "10px" }}>
              {
                checkIfGroupBy(itemList) ?
                  (Object.keys(itemList).map(key => (
                    <div key={key}>
                      <h1 style={{ fontSize: "1.3rem", marginTop: "16px", marginLeft: "10px", fontWeight: "bold" }}>{key}</h1>
                      <hr />
                      <div className="browse-items">
                        {itemList[key].map((item, index) => (
                          <BrowseCard key={index} {...item} banner={item?.assets.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
                        )
                        )}
                      </div>
                    </div>
                  ))) : (
                    <>
                      <div className="browse-items">
                        {itemList?.map((item, index) => (
                          <BrowseCard key={index} {...item} banner={item?.assets.find(img => img.context_type === 'portrait')?.name} status={item?.status} />
                        ))}
                      </div>
                      {itemList?.length < 1 && (
                        <div className="no-data" style={{ paddingTop: "4vh", display: "flex", justifyContent: "center", alignItems: "center" }} >
                          Coming Soon!
                        </div>
                      )}
                    </>
                  )
              }
              {(itemList?.length > 0 || Object.keys(itemList ?? {})?.length) ? (<div style={{ fontSize: "1.1rem", marginTop: "30px", fontWeight: 600, display: 'flex', justifyContent: 'center', marginBottom: "0px" }} >
                {!isLoading && <Pagination
                  count={paginationCount}
                  onChange={loadMore}
                  page={page}
                />}
              </div>) : null}
            </div>
          </BrowsePagesCardSection>
          {/* // the below one is to show cause details in browse item page */}
          <Desktop>
            {
              ((!market_place && (params.from == 'event-byNow'))) &&
              <div style={{ margin: "25px 0px", paddingBottom: '35px', borderBottom: "1px solid rgb(48, 49, 58)" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
                  <h1>About Cause</h1>
                  <img
                    src={downArrow}
                    style={{ marginLeft: '5px', transform: !isAboutCauseOpen && 'rotate(180deg)', cursor: "pointer" }}
                    alt="Arrow"
                    onClick={() => setIsAboutCauseOpen(!isAboutCauseOpen)}
                  />
                </div>
                {
                  (isAboutCauseOpen) &&
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ width: "30%" }}>
                      {/* <img src={causeData?.images[0]} alt="" style={{height:"150px",width:"200px",objectFit:"cover"}}/> */}
                      <Carousel
                        // infiniteLoop={true}
                        showArrows={false}
                        showIndicators={causeData?.images?.length > 1}
                        showThumbs={true}
                        // onClickItem={null}
                        onClickThumb={null}
                      >
                        {causeData?.images &&
                          causeData?.images.map((i, idx) => (
                            <div key={i}>
                              <img style={{ height: "150px", width: "200px", objectFit: "cover" }} src={i} alt='cause' />
                            </div>
                          ))}
                      </Carousel>
                    </div>
                    <div style={{ width: "50%" }}>
                      <p style={{ fontSize: "18px", fontWeight: "400", lineHeight: '24px', paddingBlock: '20px', fontFamily: "DM Mono", marginBottom: "0" }}>{`[${causeData?.legal_name}]`}</p>
                      <p style={{ paddingBottom: '35px', textAlign: "left", fontSize: "18px", lineHeight: "24px", fontWeight: "400" }}>{causeData?.about}</p>
                    </div>

                  </div>
                }
              </div>

            }
          </Desktop>

          {/* {isLoadingMore ? <div style={{display: "flex", justifyContent: "center", marginTop: "25px"}}><CircularProgress color="inherit" size="2rem" /></div> : null}
          {(oldItemList.length < cTolCount && !isLoadingMore) && <div style={{textAlign: "center", margin: "25px 0 10px 0", fontSize: "1.1rem", fontWeight: 600}} onClick={loadMore}>Load More</div>} */}
          <Desktop>
            <div style={{ display: "flex", flexDirection: "column", gap: "5px", margin: "25px auto 40px", paddingBottom: '50px' }} >
              {(params.from === 'event-byNow' || params.from === "event-auction" || params.from === 'event-category' || params.from === "event-customCategory")
                &&
                <p style={{ textAlign: "left", width: "ft-content" }}>
                  <button onClick={handleGoToEvent} style={{ borderBottom: `1px solid #30313A`, margin: '0' }} className='to_top_btn'>Return to Event</button>
                </p>
              }
              <p style={{ textAlign: "left", width: "ft-content" }}>
                <button onClick={handleGoToShop} style={{ borderBottom: `1px solid #30313A`, margin: '0' }} className='to_top_btn'>Return to Boundless Shop</button>
              </p>
            </div>
          </Desktop>
        </div>

        {/* // the below one is to show cause details in browse item page */}
        {
          (!market_place && (params.from == 'event-byNow')) &&
          <Mobile>
            <div style={{ margin: "5vw" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <h1>About Cause</h1>
                <img
                  src={downArrow}
                  style={{ marginLeft: '5px', transform: !isAboutCauseOpen && 'rotate(180deg)', cursor: "pointer" }}
                  alt="Arrow"
                  onClick={() => setIsAboutCauseOpen(!isAboutCauseOpen)}
                />
              </div>
              {
                (isAboutCauseOpen) &&
                <div>
                  <div style={{ margin: "20px 0" }}>
                    {/* <img src={causeData?.images[0]} alt="" style={{height:"100px",width:"150px",objectFit:"cover"}}/> */}
                    <Carousel
                      // infiniteLoop={true}
                      showArrows={false}
                      showIndicators={causeData?.images?.length > 1}
                      showThumbs={true}
                      // onClickItem={null}
                      onClickThumb={null}
                    >
                      {causeData?.images &&
                        causeData?.images.map((i, idx) => (
                          <div key={i}>
                            <img style={{ height: "150px", width: "200px", objectFit: "cover" }} src={i} alt='cause' />
                          </div>
                        ))}
                    </Carousel>
                  </div>
                  <p style={{ fontSize: "18px", fontWeight: "400", lineHeight: '24px', paddingBlock: '20px', fontFamily: "DM Mono", marginBottom: "0" }}>{`[${causeData?.legal_name}]`}</p>
                  <p style={{ paddingBottom: '35px', textAlign: "left", fontSize: "18px", lineHeight: "24px", fontWeight: "400", borderBottom: "1px solid rgb(48, 49, 58)" }}>{causeData?.about}</p>
                </div>
              }
            </div>
          </Mobile>
        }
        {/* <MobileSubFooter style={{margin:'0 5vw'}} primaryColor='#30313A'/>
           */}
        <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />
        {/* {!market_place && 
        <Desktop>
          <Footer style={{ marginTop: "100px" }} />
        </Desktop>
        } */}
        {
          (params?.auction?.status === 'building') && <AdminPreviewBanner type={params?.type} title={params?.auction?.title} />
        }
        {/* <Mobile> */}
        <SwipeableDrawer
          anchor="left"
          open={isFilterScreenOpen}
          onClose={toggleFilterScreen}
          onOpen={toggleFilterScreen}
          sx={{ paddingInline: '30px' }}
        >
          {filterScreen()}
        </SwipeableDrawer>
        {/* </Mobile> */}
      </div>
    </>
  );
};

export default BrowseItems;
