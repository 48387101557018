import { Helmet } from "react-helmet-async";
import HeaderV3 from "../../../molecules/Header/HeaderV3/HeaderV3";
import SectionContainer from "../../../molecules/SectionContainer/SectionContainer";
import { config } from "../../../../../config/config";
import classes from './ShopNowV3.module.css'
import { imageUrls } from "../../../../../constants/url";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useRef } from "react";
import auctionServices from "../../../../../services/auction-services";
import donorServices from "../../../../../services/donor-services";
import { fetchCausesHaveEvents } from '../../../../../redux/causeReducer';
import { useDispatch, useSelector } from 'react-redux';
import { clearAllFilter, clearFilter, fetchShopNowProducts, setPage, setSelectedFilters, setShopNowSearchText, setSortValue } from "../../../../../redux/shopNowFilterReducer";
import NewProductCard from "../../../molecules/NewProductCard/NewProductCard";
import { Grid } from "@mui/material";
import LoadingScreen from "../../../LoadingScreen/Loading";
import NewFooterV2 from "../../../molecules/NewFooter/NewFooterV2/NewFooterV2";
import SwipableDrawerComponent from "../../../atoms/SwipableDrawerComponent/SwipableDrawerComponent";
import ShopNowSortScreen from "../../../molecules/ShopNowSortAndFilterScreenScreen/ShopNowSortScreen";
import PaginationV2 from "../../../molecules/PaginationV2/PaginationV2";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ShopNowV3.module.css';
import BrowseByCheckBox from "../../../atoms/BrowseByDropDown/BrowseByCheckBox ";
import CategoryCheckBox from "../../../atoms/BrowseByDropDown/CategoryCheckBox ";
import { useLocation } from "react-router-dom";


const { REACT_APP_URL } = config

const { shop_now_v3_banner_image_desktop, shop_now_v3_banner_image_mobile } = imageUrls
const ShopNowV3 = () => {
  const location = useLocation();
  const { category, donor, cause,shop } = location.state || {};


  let image = shop_now_v3_banner_image_desktop;
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  const is1100 = useMediaQuery({
    minWidth: 1100
  })
  let grid = { rows: 4, fill: 'row' }
  if (isMobile) {
    image = shop_now_v3_banner_image_mobile
    grid = { rows: 1, fill: 'row' }
  }
  const dispatch = useDispatch()
  const scrollContainerRef = useRef();
  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [selectedSort, setSelectedSort] = useState("");
  const [allCategories, setAllCategories] = useState([])
  const [allBrands, setAllBrands] = useState([])
  const [showSortScreen, setShowSortScreen] = useState(false)
  const [sortScreenType, setSortScreenType] = useState('sortScreen')
  const { causes, loading } = useSelector(state => state.causesHaveEvents);
  const { selectedFilters, selectedFiltersObj, products, totalPage, totalCount, isShopNowLoading, page, selectedSortValue, searchText } = useSelector(state => state.shopNow);
  const [isLoading, setIsLoading] = useState(false)
  let limit = 60;
  if (isMobile) {
    limit = 50;
  }

  let scroller;
  useEffect(() => {
    getAllCategories()
    getAllBrands()
    dispatch(fetchCausesHaveEvents({ page: 1, limit: 100, from: "shop-now" }))

    return () => {
      clearTimeout(scroller)
    }
  }, [])
  useEffect(() => {
    if (products?.length > 0) {
      scrollToPosition()
    }

  }, [products])
  useEffect(() => {
    setFiltersInitialized(false);

    if (category) {
      dispatch(setSelectedFilters({ key: 'category', value: category }));
    }
    setFiltersInitialized(true);
  }, [category]);

  useEffect(() => {
    setFiltersInitialized(false);
    if (donor) {
      dispatch(setSelectedFilters({ key: 'brands', value: donor }));
    }
    setFiltersInitialized(true);
  }, [donor]);
  useEffect(() => {
    setFiltersInitialized(false);

    if (cause) {
      dispatch(setSelectedFilters({ key: 'causes', value: cause }));
    }
    setFiltersInitialized(true);
  }, [cause]);
  useEffect(() => {
    if (filtersInitialized ) {

      dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName: true, searchText: searchText }))//filterByName is for fetching products with same custom_category names.
    }
  }, [selectedFilters, selectedSortValue])

  useEffect(() => {
   
    if(shop==='shop'){
      
     dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName: true, searchText: searchText }))//filterByName is for fetching products with same custom_category names.
    
    }
    }, [])

    useEffect(() => {
   
      if(!shop && selectedFilters.brands.length==0 && selectedFilters.category.length==0 && selectedFilters.causes.length==0 && selectedFilters.customCategory.length==0){
        
       dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName: true, searchText: searchText }))
      
      }
      }, [])


  const scrollToPosition = () => {
    const pos = JSON.parse(localStorage.getItem("browseScroll"))
    if (pos && !isShopNowLoading) {
      scroller = setTimeout(() => {
        window.scrollTo({ top: pos, behavior: "smooth" })
        localStorage.removeItem("browseScroll")
      }, 500)
    }
  }

  const getAllCategories = async () => {
    try {
      setIsLoading(true)
      const data = await auctionServices.getAuctionCategoryList()// First true is for sending showEvery where query. This will check the status show_everywhere in custom_categories. Second true is for grouping custom category by name.
      setAllCategories(data?.data)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const getAllBrands = async () => {
    try {
      setIsLoading(true)
      const { data } = await donorServices.featuredDonors()
      setAllBrands(data?.donor)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleClearRemoveFromFilter = (value, arrayType) => {
    if (arrayType === 'all') {
      dispatch(clearAllFilter())
    }
    else {
      dispatch(clearFilter({ key: arrayType, value: value }))
    }
  }

  const checkFiltersPresent = () => {
    let filtersPresent = false
    for (let key in selectedFilters) {
      if (selectedFilters[key]?.length) {
        filtersPresent = true;
        break;
      }
    }
    return filtersPresent;
  }

  const handleShowSort = (screenType = '') => {
    setShowSortScreen(!showSortScreen)
    setSortScreenType(screenType)
  }

  const loadMore = (event, newPage) => {
    window.scrollTo({ top: 500, behavior: "smooth" });
    dispatch(setPage({ page: newPage }))
    dispatch(fetchShopNowProducts({ page: newPage, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName: true, searchText: searchText }))
  }

  const checkAddedProduct = (item) => {
    let value = false
    if (selectedFilters?.causes?.length && item?.auction_auction_item_xrefs?.length) {
      for (let xrefObj of item?.auction_auction_item_xrefs) {
        if (selectedFilters?.causes?.includes(xrefObj?.auction?.cause?.id)) {
          value = true;
          break
        }
      }
    }
    return value;
  }

  const handleSearchChange = (e) => {
    dispatch(setShopNowSearchText({ searchText: e.target.value }))
  }


  const clearSearchData = () => {
    if (!searchText && !searchText?.length) return;
    dispatch(setShopNowSearchText({ searchText: "" }))
    dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: selectedSortValue, filterByName: true }))
  }

  const scrollFilters = () => {
    if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft += 90
  }

  const handleSortChange = (sortValue) => {
    setSelectedSort(sortValue);
    dispatch(setSortValue({ sortValue: sortValue }))
    dispatch(fetchShopNowProducts({ page: page, limit: limit, filters: selectedFilters, isFilters: checkFiltersPresent(), selectedSortValue: sortValue, filterByName: true, searchText: searchText }))
  }

  return (
    <div>
      <Helmet prioritizeSeoTags>
        <title>Charitable Shopping For Goods at Boundless Giving</title>
        <meta
          name="description"
          content="At Boundless Giving, explore the range of charity shop fundraising ideas where 50-80% of all proceeds from charitable shopping for goods go directly to charity."
        />
        <meta
          name="keywords"
          content="Shopping For Good"
        />
        <link rel="canonical" href={`${REACT_APP_URL}/shop-now`} />
      </Helmet>
      {
        isLoading ? <LoadingScreen bgColor="var(--red-color)" /> :
          <>
            <HeaderV3 />

            <SectionContainer childClass={'paddingBottomZero'}>
              <div className={classes.headerContainer}>
                <h2 className={`newTitleClass ${classes.filterHeading}`} >SHOP Now </h2>

              </div>
              {/* Products section start */}

              <Grid
                container
                sx={{
                  borderTop: "1px solid rgb(134, 133, 133, 0.3)",
                }}
              >
                <Grid xs={12} md={3} lg={2} sx={{ display: { xs: 'none', md: 'block' }, borderRight: "1px solid rgb(134, 133, 133, 0.3)", padding: "0 20px 0 0" }}>

                  <div className="customAccordion">
                    <Accordion defaultExpanded={category && category} sx={{
                      boxShadow: "none",
                      minHeight: "auto",
                      '&::before': {
                        display: "none"
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5-content"
                        id="panel5-header"
                      >
                        <Typography component="span">[CATEGORY]</Typography>
                      </AccordionSummary>

                      <CategoryCheckBox filterArray={allCategories} category={category} filterType={"categoryFilter"} selectedFilters={selectedFilters} />

                    </Accordion>

                    <hr className="accordionSep" />
                    <Accordion sx={{
                      boxShadow: "none",
                      minHeight: "auto",
                      '&::before': {
                        display: "none"
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel7-content"
                        id="panel7-header"
                      >
                        <Typography component="span">[Sort]</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "8px 16px 0" }}>
                        <label className="customCheckbox">
                          Featured
                          <input type="checkbox" checked={selectedSort === "relevance"} onClick={() => handleSortChange('relevance')} />
                          <span className="checkmark" />
                        </label>
                        <label className="customCheckbox">
                          Low to High
                          <input type="checkbox" checked={selectedSort === "lowestPrice"} onClick={() => handleSortChange('lowestPrice')} />
                          <span className="checkmark" />
                        </label>
                        <label className="customCheckbox">
                          High to Low
                          <input type="checkbox" checked={selectedSort === "highestPrice"} onClick={() => handleSortChange('highestPrice')} />
                          <span className="checkmark" />
                        </label>
                        <label className="customCheckbox">
                          A-Z
                          <input type="checkbox" checked={selectedSort === "aToZ"} onClick={() => handleSortChange('aToZ')} />
                          <span className="checkmark" />
                        </label>
                        <label className="customCheckbox">
                          Z-A
                          <input type="checkbox" checked={selectedSort === "zToA"} onClick={() => handleSortChange('zToA')} />
                          <span className="checkmark" />
                        </label>
                      </AccordionDetails>
                    </Accordion>
                    <hr className="accordionSep" />
                    <Accordion defaultExpanded={donor && donor} sx={{
                      boxShadow: "none",
                      minHeight: "auto",
                      '&::before': {
                        display: "none"
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel8-content"
                        id="panel8-header"
                      >
                        <Typography component="span">[Shop by Brand]</Typography>
                      </AccordionSummary>
                      <BrowseByCheckBox filterArray={allBrands} donor={donor} filterType={"brandsFilter"} selectedFilters={selectedFilters} />


                    </Accordion>
                    <Accordion defaultExpanded={cause && cause} sx={{
                      boxShadow: "none",
                      minHeight: "auto",
                      '&::before': {
                        display: "none"
                      }
                    }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel9-content"
                        id="panel9-header"
                      >
                        <Typography component="span">[Shop by Cause]</Typography>
                      </AccordionSummary>
                      <BrowseByCheckBox filterArray={causes} cause={cause} filterType={"causeFilter"} selectedFilters={selectedFilters} />


                    </Accordion>
                  </div>
                </Grid>
                {
                  products.length === 0 ? <div className={classes.noProductsContainer}><h3 className={classes.noProductsText}>Coming Soon</h3></div> :

                    <Grid xs={12} md={9} lg={10} sx={{ paddingTop: "30px", paddingLeft: "30px" }}>
                      {/* <div className={classes.productsSection}> */}
                      <div className="custom-space">
                        <div className={classes.abc} >

                          <Grid container spacing={5} sx={{ justifyContent: "beween" }}>
                            {
                              products?.map((item) => {
                                return (
                                  <Grid item xs={12} md={6} lg={4}>
                                    <NewProductCard
                                      item={item?.auction_item}
                                      imageContainerClass='shopNowImageContainerClass'
                                      customItemNameClass="shopNowItemName"
                                      cardContainerClass="shopNowCardContainer"
                                      needXref={checkAddedProduct(item?.auction_item)}
                                      key={item?.id}
                                    />
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </div>
                        <PaginationV2 customContainerClass={classes.shopNowPagination} totalPage={totalPage} page={page} onLoadMore={loadMore} />
                      </div>
                    </Grid>
                }
              </Grid>

              {/* Products section end */}
            </SectionContainer>
          </>
      }
      <SwipableDrawerComponent open={showSortScreen} setOpen={setShowSortScreen} >
        {
          sortScreenType === "filterScreen" ?
            // <Mobile>
            //     <ShopNowFilterScreenMobile handleShowSort={handleShowSort} categories={allCategories} brands={allBrands} causes={causes} />
            // </Mobile>
            null
            :
            <ShopNowSortScreen handleShowSort={handleShowSort} />
        }
      </SwipableDrawerComponent>
      {!isLoading && <NewFooterV2 />}
    </div >
  )
}

export default ShopNowV3;