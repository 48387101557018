import { setBrowseBySelectedFilters } from '../redux/browseItemsReducer';
import { setSelectedFilters } from '../redux/shopNowFilterReducer';

export const getFilterDetails = (item, filterType) => {;

    let data = {
        name: '',
        id: '',
        catType: ''
    }
    switch (filterType) {
        case 'categoryFilter':
            if (item?.id) {
                data = {
                    name: item?.name,
                    id: item?.id,
                    valueType: 'category',
                }
            }
            else {
                data = {
                    name: item?.custom_category?.category_name,
                    id: item?.custom_category?.id,
                    valueType: 'custom-category',
                }
            }
            break;
        case 'brandsFilter':
            data = {
                name: item?.org_name,
                id: item?.id,
                valueType: 'donor',
            }
            break;
        case 'causeFilter':
            data = {
                name: item?.legal_name,
                id: item?.id,
                valueType: 'cause',
            }
            break
        default:
            break;
    }
    return data;
}

export const checkChecked = (item, filterType, selectedFilters) => {
    let checked = false
    switch (filterType) {
        case 'categoryFilter':
            if (item?.valueType === 'category') {
                if (selectedFilters?.category?.includes(item?.id)) {
                    checked = true
                }
            }
            else {
                if (selectedFilters?.customCategory?.includes(item?.id)) {
                    checked = true
                }
            }
            break;
        case 'brandsFilter':
            if (selectedFilters?.brands?.includes(item?.id)) {
                checked = true
            }
            break;
        case 'causeFilter':
            if (selectedFilters?.causes?.includes(item?.id)) {
                checked = true
            }
            break
        default:
            break;
    }
    return checked;
}

export const handleFilterChange = (item, filterType, dispatch, pageType="shop-now") => {
    switch (filterType) {
        case 'categoryFilter':
            if (item?.valueType === 'category') {
                dispatch(setSelectedFilters({key:"category", value: item}))
            }
            else {
                dispatch(setSelectedFilters({key:"customCategory", value: item}))
            }
            break;
        case 'brandsFilter':
            if(pageType === 'shop-now') {
                dispatch(setSelectedFilters({key:"brands", value: item}))
            }
            else if(pageType === 'event-browse-items') {
                dispatch(setBrowseBySelectedFilters({key:"brands", value: item}))
            }
            break;
        case 'causeFilter':
            dispatch(setSelectedFilters({key:"causes", value: item}))
            break
        default:
            break;
    }
}