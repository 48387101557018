import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFeaturedItems } from "../../../../../redux/featuredItemsReducer";
import SectionContainer from "../../../molecules/SectionContainer/SectionContainer";
import { SwiperSlide } from "swiper/react";
import NewProductCard from "../../../molecules/NewProductCard/NewProductCard";
import { useMediaQuery } from "react-responsive";
import CarouselSectionContainer from "../../../molecules/CarouselSectionContainer/CarouselSectionContainer";
import auctionServices from "../../../../../services/auction-services";
import NewProductCardForFlashSale from "../../../molecules/NewProductCardForFlashSale/NewProductCardForFlashSale";
import { useNavigate } from "react-router-dom";
import logger from "../../../../../utils/logger";

function FlashSalesItems({ title = 'Flash' }) {
  // const { featuredItems } = useSelector(state => state.featuredItems)
  const [flashSaleItem, setFlashSaleItem] = useState({data:[]});
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const min1440 = useMediaQuery({
    minWidth: 1440,
  });
  const min1100 = useMediaQuery({
    minWidth: 1100,
  });
  const isMobile = useMediaQuery({
    maxWidth: 800,
  });

  const getFlashSaleItems = async () => {
    try {
      let data = await auctionServices.getAllFlashSaleItems({
        limit: 50,
        page: 1,
      });
      setFlashSaleItem(data|| [])
      // console.log(data.data, "data");
    } catch (error) {
           logger('./pages/components/organisms/HomePageComponents/FlashSaleItems/FlashSaleItems.jsx','getFlashSaleItems()1: catch',error);
      // console.log(error);
    }
  };

  useEffect(() => {
    getFlashSaleItems();
    // dispatch(fetchFeaturedItems({ limit: 50, page: 1 }))
  }, []);

  if (!flashSaleItem?.data?.length) {
      return ;
  }

  const handleNavigate = (item) => {
    // window.location.href = `/event/${item?.id}`;
    // console.log(item.auction,"item")

    navigate(`/lot/${item?.id}?auction_id=${item?.auction_id}&cause_id=${item?.auction?.cause_id}`);
      
  }

  return (
    <SectionContainer sectionBg="pure-white">
      <CarouselSectionContainer
        slidesPerView={`${isMobile ? 1 :min1100 ? min1440 ? 4 : 3 : 2}`}
        // actionButtonText="VIEW ALL"
        // actionButtonHref="/event"
        title={title}
        titleStyle={{width:'fit-content'}}
        // headerPara={
        //   "50-80% of all proceeds from purchases go directly to charity."
        // }
      >
        {flashSaleItem?.data?.map((item) => {
          // console.log("this is dataaa of flash sale--------------", item);
          return (
            <SwiperSlide key={item?.id}>
              <NewProductCardForFlashSale item={item} handleClick={()=>handleNavigate(item)} />
            </SwiperSlide>
          );
        })}
      </CarouselSectionContainer>
    </SectionContainer>
  );
}

export default FlashSalesItems;
