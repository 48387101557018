import React, { useEffect } from 'react';
import './featuredSection.css';
// import marine from '../../../images/HomePageImages/marine.png'
// import theCauseConncetion from '../../../images/HomePageImages/theCauseConnection.png';
// import giftOfAdoption from '../../../images/HomePageImages/giftOfAdoption.png';
// import cancerCartel from '../../../images/HomePageImages/cancerCartel.png';


// import nike from '../../../images/HomePageImages/nike.png'
// import sustainable from '../../../images/HomePageImages/sustainable-threads.png'
// import orimage from '../../../images/HomePageImages/or-image.png'
import { Link } from 'react-router-dom';
import { getImageVariant } from '../../../../utils/util-functions';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { getAuction } from '../../../../redux/eventReducer';


const FeaturedSection = ({ storeData = [] }) => {

   const dispatch = useDispatch();
    const { auctions, isLoading } = useSelector(state => state.auction);    
  
    useEffect(() => {
      if (auctions?.total_page === 0) {
        dispatch(getAuction({ page: 1, limit: 20, cause_category_id: null }));
      }
    }, [dispatch, auctions?.total_page]);
  
  const forMobile = useMediaQuery({
    maxWidth: 768
  })
  return (
    <section className="featured-section">
      <h2 className='headingH2'>[Featured Campaigns]</h2>
      <h2 className="section-title">
      </h2>

      <p className="sectionSubtitle">
        Discover how leading brands are transforming their sustainability initiatives into measurable social impact through our platform.
      </p>

        {(storeData || auctions?.data) && (storeData.length > 0 || auctions?.data.length>0) ? (
          <div className="campaigns-container">
            {(storeData || []).concat(auctions?.data || []).map((store, index) => (
              store.ui === 'flash_sale' ?
                <Link to={`/store/${store.custom_url}`} className="campaign-link campaign" key={index}>
                  <div className='compaign-img'>
                    <img
                      src={
                        getImageVariant(
                          !forMobile ? store?.image2 : store?.image1
                        )

                      }
                      alt="The Gift of Adoption"
                      className="campaign-image"
                    />
                  </div>
                  <div>
                    <p className="campaign-category">[{store?.title}]</p>
                    <h3 className="campaign-title">{store.cause.legal_name}</h3>
                  </div>
                </Link> : ""
            ))}
          </div>
      ):<h3 className='campaign no-data' style={{ color: '#666', fontWeight: 'bold', padding: '4vh', margin: '30px auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Coming Soon!</h3>}

    </section>
  );
};


export default FeaturedSection;
