import React from 'react'
import { Helmet } from "react-helmet-async"
import './About.css';
// import HeaderV2 from '../molecules/Header/HeaderV2';
import HeaderV3 from '../molecules/Header/HeaderV3/HeaderV3';
import { mobileStyle } from '../Home';
import NewFooter from '../molecules/NewFooter/NewFooter';
import { imageUrls } from '../../../constants/url';
import { config } from '../../../config/config';
import { Grid } from '@mui/material';
import Favourite from '../../../icons/AboutPageHeart.png';
import bgCart from '../../../images/about-bg.png';
import { Box } from '@mui/material';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';


const { REACT_APP_URL } = config

const rayan = imageUrls.bg_rayan;
const nick = imageUrls.bg_nick;
const brandon = imageUrls.bg_brandon;
const neil = imageUrls.bg_neil;
const julie = imageUrls.bg_julie
const primaryColor = mobileStyle.color[0];
const primaryBackgroundColor = "var(--pure-white)";

const About = () => {
  const redColor = mobileStyle.backgroundColor[0];
  return (
    <>
      <Helmet>
        {/* <title>About | Boundless Giving</title> */}
        <title>Nonprofit Charity Fundraising Software & Solutions</title>
        <meta
          name="description"
          content="Boundless Giving provide you with online nonprofit school fundraising software for innovative charity events & schools fundraising solutions. Partner with us"
        />
        <meta
          name="keywords"
          content="School Fundraising Software"
        />
        <link rel="canonical" href={`${REACT_APP_URL}/about`} />
      </Helmet>
      <div style={{ backgroundColor: primaryBackgroundColor }}>
        <HeaderV3 position='absolute' />
        <div className='overflow-hidden'>
          <div style={{ color: primaryColor }} className='about-container'>
            <div className='about-banner'>
              <Grid container spacing={0} className='align-items-center'>
                <Grid item xs={6} md={6}>
                  <div className='hero-section'>
                    <strong className='subheading'>[About Us]</strong>
                    <h1>Powering Increased <br /> Brand Sales and <br /> Nonprofit Donations</h1>
                    <p>We’re building a future where every purchase creates meaningful impact, connecting consious consumers with causes that matter.</p>
                  </div>
                </Grid>
                <Grid item xs={6} md={6} sx={{ marginBottom: '10px', paddingLeft: '10px' }}>
                  <div className='img-holder about-img'>
                    <img src={bgCart} alt="about img" />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className='counter-section'>
            <strong className='subheading'>[OUR MISSION]</strong>
          </div>

          <div className='two-columns about-banner'>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={3} >
                <Grid item xs={12} md={6}>
                  <div style={{ maxWidth: '530px' }}>
                    <h3>Transforming How We <br /> Think About Giving</h3>
                    <p>Boundless Giving was founded with mission to help nonprofits increase fundraising impact and expand donor bases through innovation partnerships with purpose driven brands.
                    </p>
                    <p>We ensure that 40-80% of our marketplace proceeds to directly to our partners causes, creating a sustainable model for impact that benefits everyone involved.</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} className='margin-auto'>
                  <div className='heart-img'>
                    <img src={Favourite} alt="heart image" />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>

          <div className='our-value-sec about-container'>
            <div className='head'>
              <strong className='subheading'>[our values]</strong>
              <h2>What Drives Us</h2>
            </div>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <div className='item'>
                  {/* <FavoriteBorderOutlinedIcon sx={{ fontSize: 40, color: 'red', display: { xs: 'none', md: 'block' } }} /> */}
                  <strong className='title'>[Purpose]</strong>
                  <p>Even action we take is driven by our mission to maximize impact for causes.</p>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className='item'>
                  {/* <HandshakeOutlinedIcon sx={{ fontSize: 40, color: 'red', display: { xs: 'none', md: 'block' } }} /> */}
                  <strong className='title'>[Partnership]</strong>
                  <p>We believe in the power of collaboration between brands and causes.</p>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className='item'>
                  {/* <AttachMoneyOutlinedIcon sx={{ fontSize: 40, color: 'red', display: { xs: 'none', md: 'block' } }} /> */}
                  <strong className='title'>[Transparency]</strong>
                  <p>We’re committed to clear, direct impact reporting for every purchase.</p>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className='our-team-sec about-container'>
            <h2 className='headingH2 ab-heading'>[Our Team]</h2>
            <div className='team-container'>
              <div className='profile'>
                <img src={rayan} loading='lazy' alt="Ryan Jaffe" />
                <div className='description'>
                  <p className='name'>Ryan Jaffe</p>
                  <p className='designation'>Co-founder / CEO</p>
                </div>
              </div>
              <div className='profile'>
                <img src={nick} loading='lazy' style={{ backgroundColor: '#30313a', borderRadius: '20px' }} alt="Nick Danzis" />
                <div className='description'>
                  <p className='name'>Nick Danzis</p>
                  <p className='designation'>Co-Founder / Advisor</p>
                </div>
              </div>
              <div className='profile'>
                <img src={brandon} loading='lazy' alt="Brandon Watson" />
                <div className='description'>
                  <p className='name'>Brandon Watson </p>
                  <p className='designation'>Co-Founder / Advisor</p>
                </div>
              </div>
              <div className='profile'>
                <img src={julie} loading='lazy' alt="Julie Berkus" />
                <div className='description'>
                  <p className='name'>Julie Berkus </p>
                  <p className='designation'>Head of Partnerships</p>
                </div>
              </div>
              <div className='profile'>
                <img src={neil} loading='lazy' alt="Neil Collier" />
                <div className='description'>
                  <p className='name'>Neil Collier </p>
                  <p className='designation'>Co-Founder / CTO</p>
                </div>
              </div>
            </div>
          </div>

          <div className='our-mission-sec'>
            <strong className='title'>
              “We’re on a mission to reimagine charitable fundraising in a way that’s fun, sustainable and a great way to support brands determined to do good.”
            </strong>
            <strong className='auther-name'>RYAN JAFFE ,  FOUNDER</strong>
          </div>
        </div>
      </div>

      {/* </Mobile> */}
      <NewFooter primaryBackgroundColor={redColor} primaryColor={primaryBackgroundColor} logoColor={mobileStyle.color[1]} />
    </>
  )
}

export default About;