import React from "react";
import "./banner.css";

function Baner(props) {
  return (
    <div className="np-container padding-none">
      <div className="bannerContainer">
        <h2 className="headingH2">{props.heading}</h2>
        <p className="banner_dsc">{props.subheading}</p>
        
        <div className="demo-btn">
          <button className="banner_button">Free Demo</button>
        </div>
      </div>
    </div>
  );
}

export default Baner;
