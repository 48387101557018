import { FavoriteBorderOutlined } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../../../store/auth-context';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import ConfirmAlertLogin from '../../ConfirmAlertLogin';

const FavouriteIcon = ({color="red", fontSize="35px", badgeStyle={}}) => {
    const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);
    const favourites = useSelector(state => state.favourites)

    const handleCloseConfirmAlert = () => {
        setOpenConfirmAlert(false)
        navigate("/favorites")
    }

    const isDesktop = useMediaQuery({
        minWidth: 768
      })
    return (
        <>
            {
                authCtx?.isLoggedIn ? (
                    <div>
                        {(!isDesktop && pathname === '/account') ? null : <button type="button" onClick={() => {
                            if (!authCtx.isLoggedIn) {
                                setOpenConfirmAlert(true);
                                return
                            }
                            navigate("/favorites")
                        }
                        }>
                            <span style={badgeStyle} className="badge">{favourites.count}</span>
                            <FavoriteBorderOutlined sx={{ color: color, fontSize: fontSize }} />
                        </button>}
                        <ConfirmAlertLogin
                            openConfirmAlert={openConfirmAlert}
                            handleCloseConfirmAlert={handleCloseConfirmAlert} />
                    </div>
                ) : null
            }
        </>
    )
}

export default FavouriteIcon;