import React, { useContext, useState } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import './SideBarTabs.css';
import { Grid } from '@mui/material';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import NewFooterV2 from '../../molecules/NewFooter/NewFooterV2/NewFooterV2';
import AuthContext from '../../../../store/auth-context';
import authService from '../../../../services/auth-services';
import { useNavigate } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PurchaseHistory from '../PurchaseHistory/PurchaseHistory';
import CustomerSupport from '../CustomerSupport/CustomerSupport';
import Preferences from '../Preferences/Preferences';
import SavedOffers from '../favorites/SavedOffers';
import TrackMyOrder from '../PurchaseHistory/TrackMyOrder';
import HomeAddresses from '../Address/HomeAddresses';
import PaymentMethods from '../PaymentMethods/PaymentMethods';
import ConfirmAlertLogin from '../../ConfirmAlertLogin';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SideBarTabs = (props) => {
  const authCtx = useContext(AuthContext);
  const [value, setValue] = React.useState(props.message === 'frvt' ? 1 : 0);
  const [orderId, setOrderId] = React.useState('');
  const navigate = useNavigate();
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);


  const handleCloseConfirmAlert = () => {
    setOpenConfirmAlert(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if ((newValue === 2 || newValue === 3 || newValue === 4) && !authCtx.isLoggedIn) {
      setOpenConfirmAlert(true);
      return;
    }
    setValue(newValue);
  };

  const lable =
    "abc"
  const handleLogout = () => {
    authService.logout();
    // navigate("/login");
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ width: '100%', overflow: 'hidden', height: {xs:'100%', md: '94vh'} }} className="tabs-container">
        <Grid container spacing={0} className='grid-tabs-container'>
          <Grid xs={12} md={4} lg={3} spacing={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 0, padding: 0 }} className="tabs-sidebar">
              <Tabs style={{marginTop:'30px'}}  value={value} onChange={handleChange} aria-label="basic tabs examples">
                <Tab label={
                  <span className="tab-content">
                    <LocalMallOutlinedIcon />
                    <span className='labels'> Purchase History</span>
                  </span>
                }
                 {...a11yProps(0)} className={value === 0 || value === 12 ? 'tab tab-active' : 'tab'} />
                <Tab label={
                  <span className="tab-content">
                    <FavoriteBorderOutlinedIcon />
                    <span className='labels'>My Favourites</span>
                  </span>
                } {...a11yProps(1)} className={value === 1 ? 'tab tab-active' : 'tab'} />
                <Tab label={
                  <span className="tab-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" overflow='visible' viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                      <path d="M12 21c6-5.33 9-9.33 9-13.5a9 9 0 0 0-18 0C3 11.67 6 15.67 12 21z" />
                      <circle cx="12" cy="8.5" r="2.5" />
                    </svg>
                    <span className='labels'>Addresses</span>
                  </span>
                } {...a11yProps(2)} className={value === 2 ? 'tab tab-active' : 'tab'} />
                <Tab label={
                  <span className="tab-content">
                    <CreditCardOutlinedIcon />
                    <span className='labels'>Payment Methods</span>
                  </span>
                } {...a11yProps(3)} className={value === 3 ? 'tab tab-active' : 'tab'} />
                <Tab label={
                  <span className="tab-content">  
                    <NotificationsNoneOutlinedIcon />
                    <span className='labels'>Preferences</span>
                  </span>
                } {...a11yProps(4)} className={value === 4 ? 'tab tab-active' : 'tab'} />
                <Tab label={
                  <span className="tab-content">
                    <HelpOutlineIcon />
                    <span className='labels'>Customer Support</span>
                  </span>
                } {...a11yProps(6)} className={value === 5 ? 'tab tab-active' : 'tab'} />
                {authCtx?.isLoggedIn ? (
                  <div onClick={handleLogout} style={{ padding: '12px 16px', color: '#858585', borderBottom: '1px solid', marginTop: "25px", width: "fit-content", cursor: 'pointer', fontSize: "18px" }}>
                    Logout
                  </div>
                ) : (
                  <div
                    style={{ cursor: 'pointer', padding: '12px 16px' }}
                    className="logout_option"
                    onClick={() => navigate("/login")}
                  >
                    <span style={{display: 'flex', alignItems: 'center', gap: '5px', color: '#858585'}}>
                      <LogoutOutlinedIcon />
                      <h5 style={{ color: '#858585', fontSize: "18px", fontWeight: "700", letterSpacing: "-2%", margin: '0' }}>Login</h5>
                    </span>
                  </div>
                )}
              </Tabs>
            </Box>
          </Grid>
          <Grid xs={12} md={8} lg={9} className='grid-tabs-content'>
            <Box className='tabs-content'>
              <CustomTabPanel value={value} index={0}>
                <PurchaseHistory setValue={setValue} setOrderId={setOrderId}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={12}>
                <TrackMyOrder orderId={orderId} setValue={setValue}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <SavedOffers />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <HomeAddresses />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <PaymentMethods />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Preferences />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <CustomerSupport />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid >
      </Box>
      <ConfirmAlertLogin
        openConfirmAlert={openConfirmAlert}
        handleCloseConfirmAlert={handleCloseConfirmAlert}
        context={{
          returnUrl:'/account'
        }}
      />
      <NewFooterV2 />

    </>
  )
}

export default SideBarTabs;