import { FormControl, useFormControlContext } from "@mui/base/FormControl";
import { Input } from '@base-ui-components/react/input';
import { styled } from "@mui/system";
import clsx from "clsx";
import Visa from "../../../../images/visa.png"
import Mastercard from "../../../../images/mastercard-logo.png"
import Amex from "../../../../images/amex.png"
import { Grid, Select, MenuItem, FormHelperText } from "@mui/material";

import React, { useContext, useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import InputMask from "react-input-mask";


import "./PaymentMethods.css";
import {
  createCard,
  fetchSavedCard,
  updateCard,
} from "../../../../services/payment-services";

import AuthContext from "../../../../store/auth-context";
import logger from "../../../../utils/logger";
import { AddPaymentInfo } from "../../../../utils/meta-pixel-tracking";
import userServices from "../../../../services/user-services";
import { State, Country } from "country-state-city";
import { useEffect } from "react";
import constants from "../../../../constants/en";
import { mobileStyle } from "../../Home";
import { useMediaQuery } from "react-responsive";
import DialogMui from "../../molecules/Dialog/Dialog";
import LoadingScreen from "../../LoadingScreen/Loading";

export default function AddPaymentMethods({ setPaymentMethod }) {
  const { setCardId, setCusId, cusId, cardId, setIsSavedShip, userInfo, userId } = useContext(AuthContext);
  const [country, setCountry] = useState("US");
  const [addressState, setAddressState] = useState("CA");
  const [cardExpiry, setCardExpiry] = useState("");
  const fullNameRef = useRef('');
  const cardNumberRef = useRef('');
  const cvvRef = useRef('');
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState("");
  const [shippingAddress, setShippingAddress] = useState(false)
  const [doneMessage, setDoneMessage] = useState(false)
  const [alertMsg, setAlertMsg] = useState(null)
  const [alert, setAlert] = useState(false)
  const authCtx = useContext(AuthContext);
  const [addressLine1, setAddressLine1] = useState("")
  const [addressLine2, setAddressLine2] = useState("")
  const [addressCity, setAddressCity] = useState("")
  const [addressZip, setAddressZip] = useState("")
  const [cardNumber2, setCardNumber2] = useState("")

  const codesObj = {};
  const location = useLocation();
  console.log("Current Page:", location.pathname);

  const countries = useMemo(() => {
    return Country.getAllCountries().map((c) => {
      return {
        label: c.name,
        value: c.isoCode,
      };
    });
  }, []);

  useEffect(() => {
    fetchCardData()
  }, [])

  useEffect(() => {
    // console.log({addressLine1,addressLine2,addressCity,addressZip,phone,cardNumber2})
  }, [addressLine1, addressLine2, addressCity, addressZip, phone, cardNumber2])

  const fetchCardData = async () => {
    try {
      if (!cusId || !cardId) return
      setLoading(true)
      const card = await fetchSavedCard(authCtx.cusId, authCtx.cardId)

      setAddressLine1(card.address_line1)
      setAddressLine2(card.address_line2)
      setAddressCity(card.address_city)
      setPhone(card.phone)
      setAddressZip(card.address_zip)

      setAddressState(card?.address_state)
      setLoading(false)
    }
    catch (error) {
      setAlertMsg('Something went wrong')
      setAlert(true)
    }
    finally {
      setLoading(false)
    }
  }

  const primaryBtnHandler = async (e) => {
    e.preventDefault();
    const newPhoneNumber = phone.replace(/-/g, '');
    setAlert(false)
    setAlertMsg(null)
    if (shippingAddress) {
      const obj = {
        to_firstname: userInfo.first_name,
        to_lastname: userInfo.last_name,
        address1: addressLine1,
        address2: addressLine2,
        city: addressCity,
        state: addressState,
        phone: newPhoneNumber,
        country: country,
        zip: addressZip,
        email: userInfo.email,

      };
      const { data } = await userServices.postShippingAddress(
        userId,
        obj
      );
      setIsSavedShip(true)
    }

    const expiryDate = cardExpiry.split("/");
    setLoading(true)

    if (cusId && cardId) {
      updateCard({
        number: cardNumberRef.current?.value.replace(/-/g, ""),
        exp_month: expiryDate[0],
        exp_year: expiryDate[1],
        cvc: cvvRef.current?.value,
        name: fullNameRef.current?.value,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_country: country,
        address_state: addressState,
        address_zip: addressZip,
        address_city: addressCity,
        phone: newPhoneNumber,
        oldCusID: cusId,
        oldCardID: cardId,
      })
        .then((res) => {
          logger('./pages/components/AuthorizePayment.jsx', 'updateCard(): Res', res.data);
          if (res?.data?.success) {
            setCusId(res?.data?.cusId)
            setCardId(res?.data?.cardId)
            setLoading(false)
            setDoneMessage(true)
          }
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = err?.response?.data?.message || constants.APP_COPY_CARD_DETAILS_ERROR;
          setError(errorMessage)
          logger('./pages/components/AuthorizePayment.jsx', 'updateCard(): catch', err)
        });
    } else {
      createCard({
        number: cardNumberRef.current?.value.replace(/-/g, ""),
        exp_month: expiryDate[0],
        exp_year: expiryDate[1],
        cvc: cvvRef.current?.value,
        name: fullNameRef.current?.value,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_country: country,
        address_state: addressState,
        address_zip: addressZip,
        address_city: addressCity,
        phone: newPhoneNumber
      })
        .then((res) => {
          logger('./pages/components/AuthorizePayment.jsx', 'createCard(): Res', res.data);
          if (res?.data?.success) {
            setCusId(res?.data?.cusId)
            setCardId(res?.data?.cardId)
            setLoading(false)
            AddPaymentInfo()
            setDoneMessage(true)
          }
        })
        .catch((err) => {
          setLoading(false)
          const errorMessage = err?.response?.data?.message || constants.APP_COPY_CARD_DETAILS_ERROR;
          setError(errorMessage)
          logger('./pages/components/AuthorizePayment.jsx', 'createCard(): catch', err)
        });
    }
    // }
  };

  const handleExpiryDate = ({ target }) => {
    const { value } = target;
    if (value.length < 3) {
      if (value.length === 0) setCardExpiry("");
      const val = value.split("/")[0];
      const numVal = parseInt(val, 10);
      if (val.length === 1 && numVal > 1) setCardExpiry(`0${numVal}`);
      else if (val.length === 1) setCardExpiry(val);

      if (val.length === 2 && numVal > 12) setCardExpiry(`01/${numVal - 10}`);
      else if (val.length === 2) setCardExpiry(val);
    } else {
      const vs = value.split("/");
      if (vs.length < 2)
        setCardExpiry(`${value.slice(0, 2)}/${value.slice(2)}`);
      else
        parseInt(vs[0]) <= 12
          ? setCardExpiry(`${vs[0]}/${vs[1]}`)
          : setCardExpiry("");
    }
  };

  const handleNavigate = () => {
    setDoneMessage(false)
    // setPaymentMethod(true)
  }

  return (
    <>
      <div style={{ height: 'auto'}} className="container-payment-add form-container">
      {location.pathname === '/cart-checkout' &&<h3 style={{fontSize:'20px',fontWeight:'bold',marginBottom:'20px'}}>Payment Detail</h3>}

        <div className="checkout">
          {loading && (
            <LoadingScreen loadingText="Updating Payment Method..." />
          )}
          <form onSubmit={primaryBtnHandler}>
            <div className="">
              <div className="three_cards">
                <div className="card-icons-container">
                  <div style={{ fontSize: "0.8rem", fontWeight: "600" }}>We accept:</div>
                  <div className="card-icons visa_container">
                    <img className='visa_img' src={Visa} alt="" />
                  </div>
                  <div className="card-icons  master_container">
                    <img className='master_card_img' src={Mastercard} alt="" />
                  </div>
                  <div className="card-icons amex_container">
                    <img className='amex_img' src={Amex} alt="" />
                  </div>
                </div>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <FormControl required>
                    <Label>Name on Card</Label>
                    <Input
                      required
                      className={'field-input '}
                      ref={fullNameRef}
                      // onChange={(e) => fullNameRef.current = e.target.value}
                      placeholder="Full name on card"

                      style={{
                        height: '35px',
                        fontSize: '14px',
                        borderRadius: '4px',
                      }}
                    />
                    <HelperText />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl required>

                    <Label>Card Number</Label>

                    <InputMask
                      value={cardNumber2}
                      placeholder="Card number"
                      mask={
                        cardNumber2.length > 1 &&
                          (cardNumber2.slice(0, 2) === "37" ||
                            cardNumber2.slice(0, 2) === "34")
                          ? "9999-999999-99999"
                          : "9999-9999-9999-9999"
                      }
                      maskChar=""
                      ref={cardNumberRef}
                      onChange={(e) => setCardNumber2(e.target.value)}
                      className="form_input"
                      style={{
                        borderRadius: '4px',
                        fontSize: '14px',
                        height: '35px',
                        border: '1px solid #E5E1E1',
                        padding: '10px',
                        width: '100%'
                      }}
                      onBlur={(e) => (e.target.style.border = "1px solid grey")}
                      onMouseLeave={(e) => (e.target.style.border = "1px solid grey")}
                    />


                    <HelperText />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl required>
                    <Label>Expiry Date</Label>

                    <input
                      className="card-secure-info__input"
                      type="tel"
                      name="expiry-date"
                      placeholder="MM/YY"
                      maxLength={5}
                      pattern="[0-9]{2}/[0-9]{2}"
                      required
                      value={cardExpiry}
                      onChange={handleExpiryDate}
                      style={{
                        border: '1px solid grey', boxShadow: 'none'
                      }}
                    />

                    <HelperText />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl required>
                    <Label>CVC</Label>
                    <input
                      className="card-secure-info__input"
                      type="text"
                      name="cvc"
                      placeholder="CVC"
                      pattern="[0-9]{3,}"
                      inputMode="numeric"
                      maxLength={3}
                      required
                      ref={cvvRef}
                      style={{ border: '1px solid grey', boxShadow: 'none' }}
                    />
                    <HelperText />
                  </FormControl>
                </Grid>

              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                  <h5  >
                    Billing Address
                  </h5>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl
                    defaultValue=""
                    required
                  >
                    <Input
                      required
                      className={'field-input'}
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      placeholder="Address line 1" />
                    <HelperText />
                  </FormControl>

                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl
                    defaultValue=""

                  >
                    <Input
                      className={'field-input '}
                      value={addressLine2}
                      onChange={(e) => setAddressLine2(e.target.value)}
                      placeholder="Address line 2" />
                    <HelperText />
                  </FormControl>

                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl
                    defaultValue=""
                    required
                  >
                    <Input
                      required
                      placeholder="City"
                      className={'field-input '}
                      value={addressCity}
                      onChange={(e) => setAddressCity(e.target.value)}
                      placeholder="Enter your City" />
                    <HelperText />
                  </FormControl>

                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth required>
                    <Select
                      required
                      placeholder="Select State"
                      value={addressState || ""}
                      onChange={(e) => setAddressState(e.target.value)}
                      displayEmpty
                      style={{ width: '100%' }}
                      sx={{
                        padding: 0,
                        "& .MuiSelect-select": {
                          padding: 1,
                          border: '1px solid grey',
                          borderRadius: '8px'
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                          border: '1px solid grey',
                          borderRadius: '8px'
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select State</em>
                      </MenuItem>
                      {State.getStatesOfCountry("US").map((state) => (
                        <MenuItem key={state.isoCode} value={state.isoCode}>
                          {state.name} ({state.isoCode})
                        </MenuItem>
                      ))}
                    </Select>

                    <HelperText />
                  </FormControl>


                </Grid>

                <Grid item xs={12}>
                  <FormControl required>
                    <InputMask
                      required
                      placeholder="Phone Number"
                      sx={{ width: "100%" }}
                      mask="999-999-9999"
                      value={phone}
                      onChange={(event) => {
                        if (event.target.value !== phone) {
                          setPhone(event.target.value);
                        }
                      }}
                      maskChar=""
                    >
                      {(inputProps) => (
                        <Input
                          className={'field-input'}
                          required
                          {...inputProps}
                          id="phone-input"
                          placeholder={`+${codesObj[country] ? codesObj[country] : "Phone"
                            }`}
                        />
                      )}
                    </InputMask>
                    <FormHelperText />
                  </FormControl>


                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    defaultValue=""
                    required
                  >
                    <Input
                      maxLength={6}
                      placeholder="Zip Code"
                      className={'field-input '}
                      value={addressZip}
                      onChange={(e) => setAddressZip(e.target.value)}
                      placeholder="Enter your Zip Code" />
                    <HelperText />
                  </FormControl>

                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth required>
                    <Select
                      value={country || ""}
                      placeholder="Select Country"
                      onChange={(e) => setCountry(e.target.value)}
                      displayEmpty
                      style={{ width: '100%' }}
                      sx={{
                        padding: 0,
                        "& .MuiSelect-select": {
                          padding: 1,
                          border: '1px solid grey',
                          borderRadius: '8px'
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(0, 0, 0, 0.23)",
                          border: '1px solid grey',
                          borderRadius: '8px',
                          outline: 'none'
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select Country</em>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label} ({country.value})
                        </MenuItem>
                      ))}
                    </Select>

                    <HelperText />
                  </FormControl>
                </Grid>
              </Grid>
              <div style={{ height: "35px", display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "25px" }}>
                <input type="checkbox"
                  style={{ marginBottom: "0px", marginRight: "10px", width: 'auto', borderRadius: '4px' }}
                  onChange={() => {
                    setShippingAddress(!shippingAddress)
                  }} />
                <label className="shipping-address-label">Use this as shipping address</label>
              </div>
              {location.pathname === '/account' && <div className="paynow-btn">
                <button type="submit" className="save-payment-details-btn">Save</button>
              </div>}
              <DialogMui isOpen={doneMessage} message={"Successfully Updated Payment Method."} ButtonText={'Okay'} handleClick={handleNavigate} />

            </div>
          </form>
        </div>
      </div>
      {location.pathname === '/cart-checkout' && <div className="paynow-btns">
        <button type="submit" onClick={primaryBtnHandler} className="save-payment-details-btns">Save Info</button>
      </div>}
    </>

  );
}



const Label = styled(
  ({
    children,
    className,
  }: {
    children?: React.ReactNode,
    className?: string,
  }) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = React.useState(false);

    React.useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return <p>{children}</p>;
    }

    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;

    return (
      <p
        className={clsx(className, error || showRequiredError ? "invalid" : "")}
      >
        {children}
        {required ? " *" : ""}
      </p>
    );
  }
)`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props: {}) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <p {...props}>This field is required.</p> : null;
})`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};