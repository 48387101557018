import React from "react";
import "./marketingExpense.css";
// import icon from "../../../icons/checked.svg";
import { Grid } from "@mui/material";
import zeroimage from '../../../../icons/nonprofits 1.png'

function MarketingExpense(props) {
  return (
    <div className="marketmaindiv">
      <Grid container className="justify-content-between">
        <Grid item xs={12} md={4}>
          <div className="clearfix">
            <div>
              <p className="top_title">0% Marketing Expense <br /> Ratio</p>
              <p className="bottom_text">Boundless Giving runs the campaign with brand and incurs all maketing and sales costs, protecting your fundraising expense ratio</p>
            </div>
            <div className="market_btn_div">
              <button className="bottom_btn">Free Demo</button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="right_box_main">
            <div className="right_main">
              <img src={zeroimage} alt="" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default MarketingExpense;
