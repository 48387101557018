import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux'

import NoData from "./NoData";
import { getAuction, resetAuction} from '../../redux/eventReducer'
import {Helmet} from "react-helmet-async"
import { Desktop, Mobile } from "./responsive/Responsive";
import classes from "../desktop/styles/auctionMain.module.css"
import moment from "moment-timezone";
import { fetchPreviewAuction, resetPreviewAuction } from "../../redux/previewEventReducer";
import { fetchCompletedAuction, resetCompletedAuction } from "../../redux/completedEventReducer";
import HeaderV2 from "./molecules/Header/HeaderV2";
import { mobileStyle } from "./Home";
import LoadingScreen from "./LoadingScreen/Loading";
import Image from "./atoms/Image/Image";
import { useMediaQuery } from "react-responsive";
import NewFooter from "./molecules/NewFooter/NewFooter";
import ShopMore from "./molecules/ShopMore/ShopMore";
import constants from "../../constants/en";
import {config} from "../../config/config";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config

const EventCard = ({auction,eventStatus='live'})=> {
  switch (eventStatus) {
    case 'live':
      eventStatus=`Ends on ${moment(auction.end_date).format("MMMM Do YYYY")}`
      break;
    case 'preview':
      eventStatus=`Opens ${moment(auction.start_date).format("MMMM Do YYYY")}`
      break;
    case 'ended':
      eventStatus=`Event closed`
      break;
    default:
      break;
  }
  const navigate = useNavigate();
  const handleNavigate = ()=>{
    let queryParams =  (auction.has_buy_now === "0" || auction.has_auction === "0") ? "?item-type=event":""
    navigate(`/${auction.is_store === 1 ? 'store' : 'event'}/${auction.custom_url ? auction.custom_url : auction.id}${queryParams}`, { state: { auction: auction, image: auction?.image2, from: 'event' } })
  }
  return(
    <div className={classes.event_card}>
        <div className={classes.event_card_image_container}>{auction?.event_logo ?
          <img src={auction.event_logo} loading='lazy' style={{maxWidth:'100%', width:'100%',height:"100%",objectFit:'cover'}} alt={auction?.auction_logo_alt}/>
          :
          <Image src={`${auction.image2}_small`} style={{maxWidth:'100%', width:'100%', height:'100%',objectFit:'cover'}} alt={auction?.auction_logo_alt}/>
          }
        </div>
        <div className={classes.details_section}>
        <div className={classes.names_section} >
        <p className={classes.cause_name} >{`[ ${auction?.cause.legal_name} ]`}</p>
        <p className={classes.event_title}>{auction.title}</p>
        </div>
        <div className={classes.names_section}>
        <p> {eventStatus}</p>
        <button onClick={handleNavigate}>View event</button>
        </div>
        </div>
   </div>
  )
}

function AuctionMain() {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get("type") || "current";
  const queryType = new URLSearchParams(search).get("type");
  const cause_category_id = new URLSearchParams(search).get("cause_category_id");
  const cause_name = new URLSearchParams(search).get("cause_name");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [completePage,setCompletePage] = useState(1);
  const [compltedLimit,setCompletedLimit] = useState(20);
  const [loading,setLoading] = useState(false);
  const [loadingMore,setLoadingMore] = useState(false);
  const [previewPage, setPreviewPage] = useState(1)
  const [previewLimit, setPreviewLimit] = useState(10) 

  useEffect(() => {
    dispatch(resetCompletedAuction())
    dispatch(resetPreviewAuction())
    dispatch(fetchCompletedAuction({page:completePage, limit:compltedLimit}))
    dispatch(fetchPreviewAuction({page:previewPage, limit:previewLimit}))
  },[])

  useEffect(() => {
   auctions?.total_page===0 && dispatch(getAuction({type, cause_category_id, page, limit}))
  }, [type])


useEffect(()=>{
  // const getCompletedAuctions = async () => {
  //   setLoading(true)
  //   try {
  //     await auctionServices.getAllCompletedAuctionsList(completePage, compltedLimit).then(
  //       (response) => {
  //         setcompletedAuctionList(response)
  //         setLoading(false)
  //         setLoadingMore(false)
  //       },
  //       (error) => {
  //       //  console.log('./pages/components/Home.jsx','getCompletedAuctions(): catch1',error);
  //        setLoading(false)
  //        setLoadingMore(false)
  //       }
  //     );
  //   } catch (err) {
  //     setLoading(false)
  //     setLoadingMore(false)
  //     // console.log('./pages/components/Home.jsx','getCompletedAuctions(): catch2',err);
  //   }
  // };
  // const getPreviewAuctions = async () => {
  //   setLoading(true)
  //   try {
  //     await auctionServices.getAllPreviewAuctionsList(1, 20).then(
  //       (response) => {
  //         setPreviewAuctionList(response)
  //         setLoading(false)
  //         setLoadingMore(false)
  //       },
  //       (error) => {
  //       //  console.log('./pages/components/Home.jsx','getCompletedAuctions(): catch1',error);
  //        setLoading(false)
  //        setLoadingMore(false)
  //       }
  //     );
  //   } catch (err) {
  //     setLoading(false)
  //     setLoadingMore(false)
  //     // console.log('./pages/components/Home.jsx','getCompletedAuctions(): catch2',err);
  //   }
  // };
  // // getCompletedAuctions();
  // getPreviewAuctions() 
},[completePage])

  const {auctions,isLoading,isLoadingMore,from}=useSelector(state=>state.auction)
  // console.log("This is auctions----------------->", auctions)
  const {completedAuction, completedLoading, completedLoadingMore}=useSelector(state=>state.completedAuction)
  const {previewAuction, previewLoading, previewLoadingMore}=useSelector(state=>state.previewAuction)

  const loadMoreAuction = async () => {
    setPage(page+1)
    dispatch(getAuction({type, cause_category_id, page:page+1, limit}))
 
  };

  const loadMoreCompletedAuction=()=>{
    setLoadingMore(true)
    setCompletePage(completePage+1)
    dispatch(fetchCompletedAuction({page:completePage+1, limit:compltedLimit}))
  }

  const loadMorePreviewAuction = () => {
    setLoadingMore(true)
    setPreviewPage(previewPage+1)
    dispatch(fetchPreviewAuction({page:previewPage+1, limit:previewLimit}))
  }
  const isMobile = useMediaQuery({
    maxWidth: 768
  })
  return (
    <>
      <Helmet>
        {/* <title>Events | Boundless Giving</title> */}
        <title>Explore Online Charity Auctions & Events - Boundless Giving</title>
        <meta 
          name="description" 
          content="Discover & participate in ongoing online charity auctions and events hosted by Boundless Giving. Bid on unique items & experiences to support meaningful causes." 
        />
        <meta 
          name="keywords"
          content="Online Charity Auction"
        />
        <link rel="canonical" href={`${REACT_APP_URL}/auction${queryType ? `?type=${queryType}`:''}`} />
      </Helmet>
      <div className="page_outer homePage auctionMainPage">
        <div className="container">
          {/* <HeaderV2 
            primaryBackgroundColor={mobileStyle.backgroundColor[2]} 
            primaryColor={mobileStyle.color[1]} 
            logoType={isMobile ? "black2": 'black' }
            containerStyle={{position:'fixed'}} 
            isSubHeader={true}
            subHeaderTitle="Event"
          /> */}
          <HeaderV3 
            isSubHeader
            subHeaderTitle="Event"
            primaryBackgroundColor={"var(--pure-white)"} 
            primaryColor={mobileStyle.color[1]} 
          />

          {isLoading || completedLoading || previewLoading ? (
            <div >
              <LoadingScreen bgColor="#F0E8E3"/>
            </div>) : (
            <main className={`${classes.main_div} margin_class`}>
              {
                !auctions?.data.length && !previewAuction?.data.length && !completedAuction?.data.length ?
                 <div className={classes.event_empty_container}>
                  <ShopMore title={constants.APP_COPY_EMPTY_EVENTS_PAGE} />
                 </div>
                : 
              <div className="current_banner" style={{ paddingTop: 0, marginBottom: "14%" }}>

               {/* <Desktop>
                 <div className={classes.dauctionMain_no}>
                    <p style={{ fontSize: 14, padding: "20px 0" }}>
                      Total <span style={{ fontWeight: 600 }}>{auctions?.data.length}</span> events found
                      {cause_name && <span> for <span style={{ fontWeight: 600 }}>{cause_name}</span> cause</span>}
                    </p>
                 </div>
               </Desktop> */}

                {/* <Mobile> */}
                {auctions && auctions?.data && auctions?.data.length > 0 &&
                   <h1 className={classes.moblie_auction_group_title}>Live Events</h1>
                }
                {/* </Mobile> */}
                {/* <Desktop>
                {auctions && auctions?.data && auctions?.data.length > 0 &&
                <div className={classes.desktop_auction_group_title_wrapper} >
                   <h3 className={classes.desktop_auction_group_title} >Events Happening Now</h3>
                </div>
                }
                </Desktop> */}
                <div className={`auctionDiv2 ${classes.dauctionMain_cards}`} style={{marginTop:'20px'}} >
                  {/* {console.log("This is auction data.data", auctions.data)} */}
                  {auctions && auctions?.data && auctions?.data.length > 0 ? (
                    auctions?.data?.map((auction, i) => (
                      <React.Fragment key={i} >
                        {/* <Mobile key={i}> */}
                          {/* {auction.ui === "flash_sale" && console.log("This is ui type in the auction data---------", auction.ui)} */}
                          <EventCard auction={auction} eventStatus="live"/>
                        {/* </Mobile> */}
                        {/* <Desktop>
                            <div onClick={() => navigate(`/event/${auction.custom_url ? auction.custom_url : auction.id}`, { state: { auction: auction, image: auction?.image2, from: 'event' } })} style={{cursor: "pointer"}} className={classes.dauctionMain_card}>
                                <div className={classes.dauctionMain_card_image}>
                                  <img src={auction.image1} alt="" />
                                </div>
                                <div className={classes.dauctionMain_card_content}>
                                  <div>{auction.title}</div>
                                  <div>
                                  <EventNoteIcon style={{fontSize: "14px", marginRight: "4px", color: "gray"}} />{`${moment(auction?.start_date).format("MMMM DD")} - ${
                                            moment(auction?.end_date).format("MMMM DD")
                                            }
                                  `}
                                  </div>
                                  <div>More Info</div>
                                </div>
                            </div>
                        </Desktop> */}
                      </React.Fragment>
                    ))
                  ) :
                  //  !isLoading ? (
                  //   <NoData />
                  // ) :
                   <div></div>}
                  </div>
                  {/* <Mobile> */}
                    {isLoadingMore ? (
                      <div className={classes.auction_main_load_more} >
                        Loading <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                      </div>
                    ) : page <= auctions?.total_page ? (
                      <div></div>
                      // <div
                      //   className={classes.auction_main_load_more}
                      //   onClick={loadMoreAuction}
                      // >
                      //   Load more events &nbsp;
                      //   <img src={downArrow} alt="" />
                      // </div>
                    ) : (
                      <div className={classes.auction_main_load_more}>
                       {/* { 'No more events'} */}
                      </div>
                    )}
                  {/* </Mobile> */}
                
                {/* ///// preview auction  */}
                {/* <Mobile> */}
                {previewAuction && previewAuction?.data && previewAuction?.data.length > 0 &&
                   <h1 className={classes.moblie_auction_group_title}>Upcoming Events</h1>
                }
                {/* </Mobile> */}
                {/* <Desktop>
                {previewAuction && previewAuction?.data && previewAuction?.data.length > 0 &&
                <div className={classes.desktop_auction_group_title_wrapper} >
                   <h3 className={classes.desktop_auction_group_title}>Preview Events</h3>
                </div>
                }
                </Desktop>  */}
                <div className={`auctionDiv2 ${classes.dauctionMain_cards}`} style={{marginTop:"20px"}}>
                  
                  {previewAuction && previewAuction?.data && previewAuction?.data.length > 0 ? (
                    previewAuction?.data?.map((auction, i) => (
                      <React.Fragment key={i}>
                      
                        {/* <Mobile key={i}> */}
                         <EventCard key={i} auction={auction} eventStatus="preview"/>
                        {/* </Mobile> */}
                        {/* <Desktop>
                            <div onClick={() => navigate(`/event/${auction.custom_url ? auction.custom_url : auction.id}`, { state: { auction: auction, image: auction?.image2, from: 'event' } })} style={{cursor: "pointer"}} className={classes.dauctionMain_card}>
                                <div className={classes.dauctionMain_card_image}>
                                  <img src={auction.image1} alt="" />
                                </div>
                                <div className={classes.dauctionMain_card_content}>
                                  <div>{auction.title}</div>
                                  <div>
                                  <EventNoteIcon style={{fontSize: "14px", marginRight: "4px", color: "gray"}} />{`${moment(auction?.start_date).format("MMMM DD")} - ${
                                            moment(auction?.end_date).format("MMMM DD")
                                            }
                                  `}
                                  </div>
                                  <div>More Info</div>
                                </div>
                            </div>
                        </Desktop> */}
                      </React.Fragment>
                    ))
                  ) : !previewLoading ? <div></div> : <div></div>}                 
                </div>
                {/* <Mobile> */}
                    {previewLoadingMore ? (
                      <div className={classes.auction_main_load_more}>
                        Loading <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                      </div>
                    ) : previewPage <= previewAuction?.total_page ? (
                      <div></div>
                      // <div
                      //   className={classes.auction_main_load_more}
                      //   onClick={loadMorePreviewAuction}
                      // >
                      //   Load More Preview Events &nbsp;
                      //   <img src={downArrow} alt="" />
                      // </div>
                    ) :previewAuction?.data.length > 0 ? (
                      <div className={classes.auction_main_load_more}>
                        No More Preview Events
                      </div>
                    ): ''}
                  {/* </Mobile> */}



      {/* /////completed auctions section */}
                {/* <Mobile> */}
                {completedAuction && completedAuction?.data && completedAuction?.data.length > 0 &&
                   <h1 className={classes.moblie_auction_group_title}>Recently Completed Events</h1>
                }
                {/* </Mobile> */}
                {/* <Desktop>
                {completedAuction && completedAuction?.data && completedAuction?.data.length > 0 &&
                   <div className={classes.desktop_auction_group_title_wrapper} >
                   <h3 className={classes.desktop_auction_group_title}>Completed Events</h3>
                   </div>
                }
                </Desktop>  */}
                <div className={`auctionDiv2 ${classes.dauctionMain_cards}`} style={{marginTop:"20px"}}>
                  
                  {completedAuction && completedAuction?.data && completedAuction?.data.length > 0 ? (
                    completedAuction?.data?.map((auction, i) => (
                      <React.Fragment key={i}>
                      
                        {/* <Mobile key={i}> */}
                         <EventCard auction={auction} eventStatus="ended"/>
                        {/* </Mobile> */}
                        {/* <Desktop>
                            <div onClick={() => navigate(`/event/${auction.custom_url ? auction.custom_url : auction.id}`, { state: { auction: auction, image: auction?.image2, from: 'event' } })} style={{cursor: "pointer"}} className={classes.dauctionMain_card}>
                                <div className={classes.dauctionMain_card_image}>
                                  <img src={auction.image1} alt="" />
                                </div>
                                <div className={classes.dauctionMain_card_content}>
                                  <div>{auction.title}</div>
                                  <div>
                                  <EventNoteIcon style={{fontSize: "14px", marginRight: "4px", color: "gray"}} />{`${moment(auction?.start_date).format("MMMM DD")} - ${
                                            moment(auction?.end_date).format("MMMM DD")
                                            }
                                  `}
                                  </div>
                                  <div>More Info</div>
                                </div>
                            </div>
                        </Desktop> */}
                      </React.Fragment>
                    ))
                  ) : !completedLoading ? (
                    null
                  ) : <div></div>}
             </div>
                  {/* <Mobile> */}
                    {completedLoadingMore ? (
                      <div
                      className={classes.auction_main_load_more}
                      >
                        Loading <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                        <span className="loader__dot">.</span>
                      </div>
                    ) : completePage <= completedAuction?.total_page ? (
                      <div></div>
                      // <div
                      //   className={classes.auction_main_load_more}
                      //   onClick={loadMoreCompletedAuction}
                      // >
                      //   Load more completed events &nbsp;
                      //   <img src={downArrow} alt="" />
                      // </div>
                    ) : completedAuction?.data.length > 0 ? (
                      <div className={classes.auction_main_load_more} >
                        No More Completed Events
                      </div>
                    ): ''}
                  {/* </Mobile> */}  
              </div>
            }           
            </main>
          )}
        </div>
      </div>
      {/* <Mobile> */}
        <NoData customStyle={{backgroundColor: "#FC3300",height: "306px", borderRadius:'0px'}}/>
      {/* </Mobile> */}
      {/* <Desktop>
        <Footer />
      </Desktop> */}
      <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

    </>
  );
}

export default AuctionMain;
