import axios from "axios";
import { headerKey } from "./api-header";
import { config } from '../config/config.js';
import logger from "../utils/logger";
import { getGuestUserId } from "../utils/util-functions.js";
const API_BASE_URL = config.REACT_APP_BASE_URL;

const getUserBids = async (id, type) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/users/${id}/bids`, {
    headers: { ...headerKey },
    params: {
      type: type,
    },
  });
  return response.data;
};

const getUserOrderStatus = async (orderId) => {
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/order-status/${orderId}`, {
      headers: { ...headerKey }
    })
  
    return data
  } catch (error) {
    logger('./services/user-services.js','getUserOrderStatus(): catch',error);
  }
}

const getUserShippingAddress = async (id) => {
  const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/${id}/shipping-details`, {
    headers: { ...headerKey }
  })
  return data
}

const postShippingAddress = async (id, body) => {
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/users/${id}/shipping-details`, {...body}, {
      headers: { ...headerKey }
    })
    return data
  } catch (error) {
    logger('./services/user-services.js','postShippingAddress(): catch',error);
  }
}

const getUserPurchaseHistory = async (id, status, page, limit) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/users/${id}/purchase-history`, {
    headers: { ...headerKey },
    params: {
      status: status,
      page: page,
      limit: limit,
    }
  });
  return response.data;
};

const checkUserBidItem = async (uid, itemId) => {
  const response = await axios.get(`${API_BASE_URL}/api/v1/users/${uid}/item/${itemId}/user-bid-item`, {
    headers: { ...headerKey }
  });
  return response.data;
};

const updateUserBids = async (userId, auctionId, itemId, payload) => {
  const response = await axios.put(`${API_BASE_URL}/api/v1/users/${userId}/auction/${auctionId}/item/id/${itemId}/update-bid`, payload,
  {
    headers: { ...headerKey },
  });
  return response.data;
};

const addToCart = async (body)=>{
  try {
    const res = await axios.post(`${API_BASE_URL}/api/v1/users/cart-items/add`, {...body}, {
      headers: { ...headerKey }
    })
    return res
  } catch (error) {   
    logger('./services/user-services.js','addToCart(): catch',error);
    return error
  }

}

const getUserCartItems = async () =>{
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/cart-items/get-all-items`, {
      headers: { ...headerKey }
    })
    return data
  }
  catch(error) {
    throw error
  }
}

const removeFromCart = async (id, isLoggedIn) =>{
  try{
    let res;
    if(isLoggedIn) {
      res= await axios.delete(`${API_BASE_URL}/api/v1/users/remove-cart-item/${id}`, {
        headers: { ...headerKey }
      })
    }
    else {
      res= await axios.delete(`${API_BASE_URL}/api/v1/users/guest/remove-cart-item/${id}`, {
        params: {
          guest_user_id: getGuestUserId()
        }
      })
    }
    return res;
  }
  catch(error){
    logger('./services/user-services.js','removeFromCart(): catch',error);
  }
  
}

const checkCartItem = async (sku,isLoggedIn) => {
  try{
    if(isLoggedIn) {
     const res = await axios.get(`${API_BASE_URL}/api/v1/users/check-cart-item/${sku}`, {
       headers: { ...headerKey }
     })
     return res
    }
    else {
      if(getGuestUserId()) {
        const res = await axios.get(`${API_BASE_URL}/api/v1/users/guest/check-cart-item/${sku}`, {
          params: {
            guest_user_id: getGuestUserId()
          }
        })
        return res
      }
    }
  }catch(error){
    logger('./services/user-services.js','checkCartItem(): catch',error);
    return error
  }
  
}

const updateQuantity = async (body,id, isLoggedIn) =>{
  try{
  let data;
  if(isLoggedIn) {
    data = await axios.put(`${API_BASE_URL}/api/v1/users/update-cart-item-quantity/${id}`,body,{
      headers: { ...headerKey }
    })
  }
  else {
    data = await axios.put(`${API_BASE_URL}/api/v1/users/guest/update-cart-item-quantity/${id}`,body,{
      params: {
        guest_user_id: getGuestUserId()
      }
    })
  }
  return data
  }catch(error){
    logger('./services/user-services.js','updateQuantity(): catch',error);
    return error
  }
}

 const fetchAlBlogs =async(limit, page) => {
  try {
    const data = await axios.get(`${API_BASE_URL}/api/v1/users/blogs/get-all-blog?limit=${limit}&page=${page}`, {
      headers: { ...headerKey },
    })
    return data
  } catch (error) {
      logger('src/services/user-service.js','fetchAllBlogs()1: catch',error);
    // console.log(error)
  }
}

const getBlogData = async(pretty_url) => {
  try {
    const data = await axios.get(`${API_BASE_URL}/api/v1/users/blog/${pretty_url}`, {
      headers: { ...headerKey },
    })
    return data
  } catch (error) {
    throw error
  }
}


const changeMailPreferences = async(preferenceValue) => {
  try {
    const data = await axios.patch(`${API_BASE_URL}/api/v1/users/update-subscription`, {preferenceValue},{
         headers: { ...headerKey },
    })
    return data
  } catch (error) {
    throw error
  }
}

const sendGetStartedEmail = async(formDetails) => {
  try {
    const data = await axios.post(`${API_BASE_URL}/api/v1/users/send-get-started-mail`,{formDetails} ,{

      headers: { ...headerKey },
    })
    return data
  } catch (error) {
    throw error
  }
}
const brandOrNonprofitContact = async(formDetails) => {
  try {
    const data = await axios.post(`${API_BASE_URL}/api/v1/users/send-brand-or-nonprofit-contact-email`,formDetails ,{

      headers: { ...headerKey },
    })
    return data
  } catch (error) {
    throw error
  }
}

const addToFavourites = async (body)=>{
  try {
    const {data} = await axios.post(`${API_BASE_URL}/api/v1/users/favourites/add`, {...body}, {
      headers: { ...headerKey }
    })
    return {data}
  } catch (error) {   
    logger('./services/user-services.js','addToFavourites(): catch',error);
    throw error
  }
}

const getUserFavouriteItems = async () =>{
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/favourites/get-all-items`, {
      headers: { ...headerKey }
    })
    return data
  }
  catch(error) {
    logger('./services/user-services.js','getUserFavouriteItems(): catch',error);
    throw error
  }
}

const checkFavouriteItem = async (sku) => {
  try{
   const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/check-favourite-item/${sku}`, {
    headers: { ...headerKey }
  })
   return data
  }catch(error){
    logger('./services/user-services.js','checkFavouriteItem(): catch',error);
    throw error
  }
}

const removeFromFavourites = async (id) =>{
  try{
    const res= await axios.delete(`${API_BASE_URL}/api/v1/users/remove-favourite-item/${id}`, {
      headers: { ...headerKey }
    })
    return res;
  }
  catch(error){
    logger('./services/user-services.js','removeFromFavourites(): catch',error);
  }
  
}

const addGuestUserCart = async (body)=>{
  try {
    const res = await axios.post(`${API_BASE_URL}/api/v1/users/guest/cart-items/add`, {...body}, {
      params: {
        guest_user: true,
        guest_user_id: getGuestUserId()
      }
    })
    return res
  } catch (error) {   
    logger('./services/user-services.js','addGuestUserCart(): catch',error);
    return error
  }
}

const getGuestUserCartItems = async () =>{
  try {
    const {data} = await axios.get(`${API_BASE_URL}/api/v1/users/guest/cart-items/get-all-items`, {
      params: {
        guest_user_id: getGuestUserId()
      }
    })
    return data
  }
  catch(error) {
    throw error
  }
}

const userServices = {
  getUserBids,
  getUserPurchaseHistory,
  checkUserBidItem,
  updateUserBids,
  getUserShippingAddress,
  postShippingAddress,
  getUserOrderStatus,
  addToCart,
  getUserCartItems,
  removeFromCart,
  checkCartItem,
  updateQuantity,
  fetchAlBlogs,
  getBlogData,
  changeMailPreferences,
  sendGetStartedEmail,
  brandOrNonprofitContact,
  addToFavourites,
  getUserFavouriteItems,
  checkFavouriteItem,
  removeFromFavourites,
  addGuestUserCart,
  getGuestUserCartItems,
};

export default userServices;
