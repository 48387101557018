import React, { useContext } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeaderV3 from "../molecules/Header/HeaderV3/HeaderV3";
import { mobileStyle } from "../Home";
import SideBarTabs from "./SideBarTabs/SideBarTabs";
import AuthContext from "../../../store/auth-context";
import { useLocation } from 'react-router-dom';
function Account() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();
  const { account } = location.state || {};

  return (
    <>
      <HeaderV3
        primaryBackgroundColor={"var(--pure-white)"}
        primaryColor={mobileStyle.color[1]}
        isSubHeader subHeaderTitle="Account"
      />
      <div className="name-div">
        <h1>
          {(authCtx?.userInfo?.first_name || '') + ' ' + (authCtx?.userInfo?.last_name || '')}
        </h1>
      </div>
      <SideBarTabs message={account} />
    </>
  )
}

export default Account;
