import { Helmet } from "react-helmet-async"
import './CustomerSupport.css';
import SupportPart from "./SupportPart";
import { useMediaQuery } from "react-responsive";
import NeedHelp from "../../atoms/NeedHelp/NeedHelp";

export default function CustomerSupport() {

  const isDesktop = useMediaQuery({ minWidth: 768 })

  return (
    <section className="customer_support_section purchase-container">
      <Helmet>
        <title>Support | Boundless Giving</title>
      </Helmet>
      <h1>Customer Support</h1>
      <div className="customer_support purchase-history">
        <h2>Need Customer Support?</h2>
        <div style={{ display: 'flex', flexDirection: "column", gap: '25px' }} >
          <SupportPart />
        </div>
        {/* <NeedHelp primaryColor="#F0E8E3" style={{ marginTop: "140px" }} /> */}

      </div>
    </section>
  )
}

