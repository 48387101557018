import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { getFilterDetails, checkChecked, handleFilterChange } from "../../../../utils/shopnow.utils";
import { AccordionDetails } from "@mui/material";
import { use } from "react";
import { setSelectedFilters } from "../../../../redux/shopNowFilterReducer";

const CategoryCheckBox = ({ filterArray = [], filterType = "",category, selectedFilters = { selectedFilters }, pageType = "shop-now" }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    // const { selectedFilters } = useSelector(state => state.shopNow);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch()
    const handleClick = (event) => {        
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (category) {
             dispatch(setSelectedFilters({key:"category", value: category}))
        }
    }
        , [category,filterArray,dispatch])
    
    return (

        <AccordionDetails handleClick={handleClick}
            sx={{ padding: "8px 16px 0" }}>
            {filterArray && filterArray.map((category) => (
                <label key={category.id} className="customCheckbox">
                    {category.name}
                    <input
                        key={category.id}
                        type="checkbox"
                        value={getFilterDetails(category, filterType).id}
                        checked={checkChecked(getFilterDetails(category, filterType), filterType, selectedFilters)}
                        onChange={() => handleFilterChange(getFilterDetails(category, filterType), filterType, dispatch, pageType)}
                    />
                    <span className="checkmark" />
                </label>
            ))}
        </AccordionDetails>
    )
}

export default CategoryCheckBox;