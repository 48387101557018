import axios from 'axios';
import { headerKey } from './api-header';
import { config } from '../config/config.js';
import logger from '../utils/logger.js';
const API_BASE_URL = config.REACT_APP_BASE_URL;

const createAdvatixOrder = async (payload) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/order/create_order_advatix`,
    payload,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const createVirtualOrder = async (payload) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/order/create_bg_vir_order`,
    payload,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const updateStoreItemQuantity = async (item_id, payload) => {
  const response = await axios.put(
    `${API_BASE_URL}/api/v1/order/item/${item_id}/update_inventory_quantity`,
    payload,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

// const getShippingRate = async (auction_item_id, delivery_option) => {
//   try{
//   const response = await axios.get(
//     `${API_BASE_URL}/api/v1/payment/shipping-rates`,
//     {
//       params: {
//         auction_item_id: auction_item_id,
//         delivery_option: delivery_option,
//       },
//       headers: { ...headerKey },
//     }
//   );
//   return response.data;
//   }catch(error){
//   console.log(error)
//   }
// };

const getShippingRate = async (productsTotalWeight, delivery_option) => {
  try{
  const response = await axios.get(
    `${API_BASE_URL}/api/v1/payment/shipping-rates`,
    {
      params: {
        productsTotalWeight: productsTotalWeight,
        delivery_option: delivery_option,
      },
      headers: { ...headerKey },
    }
  );
  return response.data;
  }catch(error){
      logger('src/services/order-service.js','getShippingRate()1: catch',error);
  // console.log(error)
  }
};

const getSalesTax = async (amount) => {
  try{
    const {data} = await axios.get(
      `${API_BASE_URL}/api/v1/order/get_sales_tax/${amount}`,
      {
        headers: {...headerKey}
      }
    )
    return data.tax
  }catch(error){
    throw error
  }

}

const createSelfOrder = async (payload) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/order/create_self_order`,
    payload,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const createEdiOrder = async (payload) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/order/create_edi_order`,
      payload,
      {
        headers: { ...headerKey },
      }
    );
    return response.data;
  }
  catch(error) {
    throw error;
  }
};

const confirmOrder = async (payload,confirmOrder) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/order/confirm_order`,
    {payload,confirmOrder},
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};
const updatePaymentFailure = async (payload) => {
  const response = await axios.post(
    `${API_BASE_URL}/api/v1/order/update_payment_failure`,
    payload,
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const orderServices = {
  createAdvatixOrder,
  createVirtualOrder,
  updateStoreItemQuantity,
  getShippingRate,
  getSalesTax,
  createSelfOrder,
  confirmOrder,
  updatePaymentFailure,
  createEdiOrder
};

export default orderServices;
