import React from "react";
import "./socialdonation.css";
import Grid from '@mui/material/Grid';

function SocialDonation(props) {
  return (
    <div className="maindiv">
      <Grid container spacing={3} className="social-grid justify-content-between">
        <Grid item xs={12} md={5} className="social-image-wrapper">
          <div className="img-holder" style={{maxWidth: '430px', margin: '0 auto'}}>
            <img src={props.image} alt="Social-Button" />
           
          </div>
        </Grid>
        <Grid item xs={12} md={4} className="social-content-wrapper">
          <div className="social_btn_main">
            <div>
              <p className="right_text left_text">{props.heading}</p>
              <p className="right_text_bottom left_text greyBoldText">{props.subheading}</p>
            </div>
            <div className="btn_div">
              <button className="right_btn">Free Demo</button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SocialDonation;
