import React,{useState,useEffect, useContext} from 'react';
import './Preferences.css';
import AuthContext from '../../../../store/auth-context';
import userServices from '../../../../services/user-services';

const Preferences = () => {
  const ctx = useContext(AuthContext);
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState({ mailLoading: false })
  const { updateSubscriptionStatus } = ctx;
  useEffect(() => {
    if (ctx?.subscriptionStatus == 1) {
      setChecked(true)
    }
    else {
      setChecked(false)
    }
  }, [ctx?.subscriptionStatus])
  const handleMailPreferenceChane = async (e) => {
    try {
      setLoading({ ...loading, mailLoading: true })
      const data = await userServices.changeMailPreferences(e.target.checked)
      if (data?.status === 200) {
        setChecked(!checked)
        updateSubscriptionStatus(data?.data?.value)
      }
      setLoading({ ...loading, mailLoading: false })
    } catch (error) {
      setLoading({ ...loading, mailLoading: false })
    }
  }
  return (
    <div className='purchase-container'>
      <h1>PREFERENCES</h1>
      <div className="purchase-history">
        {/* <h4 className='email-h'>Email Notifications</h4> */}
        <form>
          <div className="checkbox-group">
            <input checked={checked} onChange={handleMailPreferenceChane}  type="checkbox" />
            <label>
            Grant permission to receive emails from Boundless.
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Preferences;
