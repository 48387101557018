import React from "react";
import "./makeimpact.css";
import { useNavigate } from "react-router-dom";


function MakeImpact(props) {
  const navigate = useNavigate();

  return (
    <div className="impact_main">
      <h1>
        READY TO MAKE AN <br /> IMPACT?
      </h1>
      <h2>
      Join leading nonprofits creating meaningful change through purpose -driven commerce.
      </h2>

      <div className="impact_btn_div">
        <button onClick={()=>navigate("/brand-partner")}  className="impact_btn">Partner With Us</button>
      </div>
    </div>
  );
}

export default MakeImpact;
