import React from 'react';
import './PartnerWithUs.css';
import { useNavigate } from 'react-router-dom';

const PartnerWithUs = () => {

  const navigate = useNavigate();

  const HandleBrandsClick = () =>  {
    navigate("/brand");
  }

  const HandleNonProfit = () => {
    navigate('/non-profit')
  }
  
  
  return (
    <div className="partner-container">
      <h1 className="partner-heading">PARTNER WITH US</h1>
      <p className="partner-subheading">
        Join our community of brands and nonprofits working together to solve
        industry pain points while creating positive social and environmental
        impact.
      </p>
      <div className="partner-buttons">
        <button className="theme_button" onClick={HandleBrandsClick}>Brands</button>
        <button className="theme_button" onClick={HandleNonProfit}>Nonprofits</button>
      </div>
    </div>
  );
};

export default PartnerWithUs;
