import React, { useEffect, useState } from "react";
import { TextField } from '@mui/material';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import parseQuery from "query-string";
import CircularProgress from '@mui/material/CircularProgress';
import AlertComp from '@mui/material/Alert';
import InputPassword from "../components/atoms/InputPassword/InputPassword";
import LogoImg from "../../images/newHomeImages/whiteLogo.svg";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import authService from '../../services/auth-services';

import './ForgotPassword.css';
import { Desktop, Mobile } from "../components/responsive/Responsive";
import classes from "../desktop/styles/forgotpass.module.css";
import { grey } from "@mui/material/colors";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import HeaderV3 from "../components/molecules/Header/HeaderV3/HeaderV3";

function ChangedPassword({ step, alert, handleBtn }) {//Success Password Changed
  return (
    <>
      {/* <Desktop>
        <div className={classes.comp_wrapper}>
        <Player
          autoplay
          speed={1}
          loop
          src="/success_gif.json"
          style={{ height: "250px", width: "250px" }}
        />
        <h3>Success</h3>
        {alert.open && (
        <AlertComp severity={alert.type}>{alert.msg}</AlertComp>
      )}
      <button disabled={alert.loading || alert.open} onClick={alert.loading?null:()=>handleBtn()} className={classes.succes_login}>
        { alert.loading ? <CircularProgress style={{color:'#fff'}} size='1.5rem' /> :
          step===2?'Login':step===1?'Reset Password':'Recovery Link'
        }
      </button>
        </div>
      </Desktop> */}
      <div style={{ paddingTop: '70px' }} className="comp_wrapper">
        <Player
          autoplay
          speed={1}
          loop
          src="/success_gif.json"
          style={{ height: "300px", width: "300px" }}
        />
        <h3 style={{ color: "#F0E8E3" }}>Success</h3>
      </div>
    </>
  )
}
function ResetPassword({ password = '', confirmPassword = '', setPass, setConPass, alert, handleBtn, step, setShowPwd, showPwd, showConfPwd, setShowConfPwd }) {
  return (
    <>
      {/* <Desktop>
   <div className={classes.forgetpass_wrapper}>
        <div className={classes.forgetpass_sec}>
          <div className={classes.forgetpass_sec_left}>
            <img src={resetpic} alt="" />
          </div>
          <div className={classes.forgetpass_sec_right}>
            <div style={{textAlign:"center", marginBottom: "40px"}}>
              <img src={LogoImg} alt="BoundlessGiving Logo" style={{width: '100px', height: '100px', alignSelf: "center"}}/>
              <h3 style={{color:'grey'}} >Create New Password</h3>
              <p style={{textAlign:"center",fontSize:'0.8rem'}}>Your new password must be different from previous used passwords</p>
            </div>
            <div className={classes.pass_input}>
              <label>New Password</label>
              <input type={showPwd ? "text" : "password"} value={password} onChange={setPass} placeholder="Enter Password"/>
            </div>
            <div className={classes.pass_input}>
              <label>Confirm Password</label>
              <div className={classes.pass_input_container}>
              <input type={showConfPwd ? "text" : "password"} value={confirmPassword} onChange={setConPass} placeholder="Confirm Password"/>
              <RemoveRedEyeIcon className={classes.eye} onClick={setShowConfPwd}/>
              </div>
              {alert.open && (
                <AlertComp severity={alert.type} style={{width:"100%",margin: "0 auto",marginTop:'15px',marginBottom:"15px", textAlign:"center"}}>{alert.msg}</AlertComp>
              )}
            </div>
            <button disabled={alert.loading || alert.open} onClick={alert.loading?null:()=>handleBtn()} className={classes.recovery_link}>
               { alert.loading ? <CircularProgress style={{color:'#fff'}} size='1.5rem' /> :
                   step===2?'Login':step===1?'Reset Password':'Recovery Link'
               }
            </button>
          </div>
        </div>
      </div>
   </Desktop> */}
      <div className="comp_wrapper">
        <div className="extra-class" style={{ height: "fit-content", width: "95%" }}>
          <div className="wrapper_top">
            <h3 className="reset-link-heading">Create New Password</h3>
            <p className="reset-link-desc">Your new password must be different from previous used passwords</p>
          </div>
          <div className="comp_reset_password_input">
            <InputPassword
              className='comp_forgot_input'
              pass={password}
              onChange={setPass}
            />
            <InputPassword
              className='comp_forgot_input'
              placeholder="Confirm Password"
              pass={confirmPassword}
              onChange={setConPass}
            />
          </div>
        </div>

      </div>
    </>
  )
}
function SendResetLink({ email = '', setEmail, alert, handleBtn, step }) {
  const { state } = useLocation()
  const goTo = useNavigate();
  return (
    <>
      {/* <Desktop>
      <div className={classes.forgetpass_wrapper}>
        <div className={classes.forgetpass_sec}>
          <div className={classes.forgetpass_sec_left}>
            <img src={resetpic} alt="" />
          </div>
          <div className={classes.forgetpass_sec_right}>
            <div style={{textAlign:"center"}}>
            <img src={LogoImg} alt="BoundlessGiving Logo" style={{width: '150px', height: '150px', alignSelf: "center"}}/>
            <h3 style={{color:'grey'}} >Reset Password</h3>
            <p style={{textAlign:"center",fontSize:'0.8rem'}}>No worries,<br /> We will send you Reset Instructions</p>
            </div>
            <div className={classes.email_input}>
              <label>Email Address</label>
              <input type="text" value={email} onChange={setEmail} placeholder="Enter email"/>
              {alert.open && (
                <AlertComp severity={alert.type} style={{width:"100%",margin: "0 auto",marginTop:'15px',marginBottom:"15px", textAlign:"center"}}>{alert.msg}</AlertComp>
              )}
            </div>
            <button disabled={alert.loading || alert.open} onClick={alert.loading?null:()=>handleBtn()} className={classes.recovery_link}>
              { alert.loading ? <CircularProgress style={{color:'#fff'}} size='1.5rem' /> :
                  step===2?'Login':step===1?'Reset Password':'Recovery Link'
              }
            </button>
          </div>
        </div>
      </div>
    </Desktop> */}
      <div className="comp_wrapper">
        <div className="extra-class reset-link-container login-form-container">
          <div className="wrapper_top ">
            <h1 className="heading">Reset Password</h1>
            <p className="reset-link-desc">No worries. Enter you email and We we'll send you reset instructions</p>
          </div>
          {/* <TextField
        label='Email Address'
        id="outlined-start-adornment"
        sx={{ m: 1, width: "25ch" }}
        value={email}
        onChange={setEmail}
        className='comp_forgot_input'
      /> */}
          <div className="field_outer">
            <i className="input-icon">
              <EmailOutlinedIcon />
            </i>
            <input className="reset-email-input login-form-control" type="text" value={email} onChange={setEmail} placeholder="Email Address" />
          </div>
          <button disabled={alert.loading || alert.open} onClick={alert.loading ? null : () => handleBtn()} className="login-blockBtn not-allowed-btn" >
            {alert.loading ? <CircularProgress style={{ color: '#fff' }} size='1.5rem' /> :
              step === 2 ? 'Login' : step === 1 ? 'Reset Password' : 'send recovery link'
            }
          </button>
          {step === 0 && (
            <div style={{ textAlign: "center", fontFamily: "Instrument Sans" }}>
              <span onClick={() => goTo('/login', { state: { redirect: state && state.redirect ? state.redirect : null } })} style={{ textDecoration: "underline", color: "#30313A", fontSize: "15px", fontWeight: "400", cursor: "pointer" }}>Back to login</span>
            </div>
          )}

        </div>

      </div>
    </>
  )
}
export default function ForgotPassword() {
  // 0=SendResetLink / 1=ResetPassword / 2=Success{or}ChangedPassword
  const [step, setStep] = useState(0);
  const [reqBody, setReqBody] = useState({});
  const [alert, setAlert] = useState({ type: 'error', msg: '', open: false, loading: false });
  const goTo = useNavigate();
  const searchQuery = useLocation();
  const [showPwd, setShowPwd] = useState(false);
  const [showConfPwd, setShowConfPwd] = useState(false);
  const { state } = useLocation()
  const urlQuery = parseQuery.parse(searchQuery.search);

  useEffect(() => {
    const { ticket } = parseQuery.parse(searchQuery.search);
    if (ticket) {
      setStep(1);
      setReqBody({ ...reqBody, resetToken: ticket });
    } else {
      setStep(0);
    }
  }, [searchQuery]);
  useEffect(() => {
    if (alert.open) {
      const interval = setInterval(() => {
        setAlert({ ...alert, open: false });
      }, 5 * 1000);
      return () => clearInterval(interval);
    }
  }, [alert]);

  async function handleBtn() {
    if (step === 2) {
      goTo('/login', { state: { redirect: urlQuery && urlQuery?.redirect ? urlQuery?.redirect : null } });
      return '';
    }
    // Loading Starts Here
    setAlert({ ...alert, loading: true });
    // Loading Starts Here
    await authService.forgotPasswordApi(step, reqBody, setStep, setAlert, state?.redirect);//State.redirect is the link of the last page url. From there we came to login page. And we need to go to that page after completing the reset password process.
    return '';
  }

  return (
    <>
      <HeaderV3 />
      {/* <Desktop>
    {
      (step === 0)? 
      <SendResetLink 
      email={reqBody.email}
      setEmail={(e)=>setReqBody({...reqBody,email:e.target.value})}
       alert={alert}
       handleBtn={handleBtn}
       step={step}
       setStep={setStep}
      />   : 
      (step === 1) ? 
      <ResetPassword
      password={reqBody.newPassword}
      confirmPassword={reqBody.confirmPassword}
      setPass={(e)=>setReqBody({...reqBody,newPassword:e.target.value})}
      setConPass={(e)=>setReqBody({...reqBody,confirmPassword:e.target.value})}
      alert={alert}
      handleBtn={handleBtn}
      step={step}
      setStep={setStep}
      showPwd={showPwd}
      setShowPwd={()=>setShowPwd(!showPwd)}
      showConfPwd={showConfPwd}
      setShowConfPwd={()=>setShowConfPwd(!showConfPwd)}
      />:
      <ChangedPassword step={step} alert={alert} handleBtn={handleBtn}/>
    }   
   </Desktop> */}
      <div className="forgot_password">
        {step !== 2 && (<img src={LogoImg} alt="BoundlessGiving Logo" style={{ width: "85%", height: "100px", alignSelf: "center", cursor: "pointer" }} onClick={() => goTo("/")} />)}
        {/* Main Content */}
        {step === 0 ?
          <SendResetLink
            email={reqBody.email}
            setEmail={(e) => setReqBody({ ...reqBody, email: e.target.value })}
            alert={alert}
            handleBtn={handleBtn}
            step={step}
            setStep={setStep}
          /> :
          step === 1 ?
            <ResetPassword
              password={reqBody.newPassword}
              confirmPassword={reqBody.confirmPassword}
              setPass={(e) => setReqBody({ ...reqBody, newPassword: e.target.value })}
              setConPass={(e) => setReqBody({ ...reqBody, confirmPassword: e.target.value })}
              alert={alert}
              handleBtn={handleBtn}
              step={step}
              setStep={setStep}
              showPwd={showPwd}
              setShowPwd={() => setShowPwd(!showPwd)}
              showConfPwd={showConfPwd}
              setShowConfPwd={() => setShowConfPwd(!showConfPwd)}
            /> :
            <ChangedPassword step={step} alert={alert} handleBtn={handleBtn} />
        }
        {/* Main Content */}
        {alert.open && (
          <div className="forget-pass-alert-container">
            <AlertComp className="forget-pass-alert" severity={alert.type}>{alert.msg}</AlertComp>
          </div>

        )}
        {/* <button disabled={alert.loading || alert.open} onClick={alert.loading ? null : () => handleBtn()} className="new-btn-reset-link" >
          {alert.loading ? <CircularProgress style={{ color: '#fff' }} size='1.5rem' /> :
            step === 2 ? 'Login' : step === 1 ? 'Reset Password' : 'send recovery link'
          }
        </button>
        {step === 0 && (
          <div style={{ textAlign: "center", fontFamily: "Instrument Sans" }}>
            <span onClick={() => goTo('/login', { state: { redirect: state && state.redirect ? state.redirect : null } })} style={{ textDecoration: "underline", color: "#F0E8E3", fontSize: "15px", fontWeight: "400", cursor: "pointer" }}>Back to login</span>
          </div>
        )} */}
      </div>
    </>
  )
}
