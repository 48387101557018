import { FormControl, MenuItem, Select } from '@mui/material'
import React, { useEffect } from 'react'
import "./VariantsSelector.css";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { color } from 'framer-motion';



const checkQTY_V = (variants, selected, setAuctionActive, activeByDate) => {
  const var_types_selected = variants.filter(v => {
    const show = Object.keys(selected).map(s => v[s] === selected[s]);
    return show.filter(s => s === true).length === show.length;
  });
  if (var_types_selected.length) {
    const { quantity_in_stock } = var_types_selected[0];
    if (!quantity_in_stock) {
      setAuctionActive(false);
    } else {
      setAuctionActive(true && activeByDate);
    }
  }
}

const VariantsSelector = ({ status_event, start_date_event, end_date_event, setAuctionActive, selected, selectedVar, setSelected, options, setSelectedVar, variants, auctionComplete, setHasQuantity }) => {
  const handleSelect = (e) => {
    let sel_here = selected;
    sel_here = { ...selected, [e.target.name]: e.target.value };
    checkQTY_V(variants, sel_here, setAuctionActive, !auctionComplete
    );
    setSelected({ ...selected, [e.target.name]: e.target.value });
  }
  useEffect(() => {
    if (!selected) {
      const obj = {}
      Object.keys(options).forEach(key => {
        if (options[key] && options[key].length > 0) {
          obj[key] = options[key][0]
        }
      })
      setSelected(obj)
      return
    }
    setSelectedVar(null)
    if (Object.keys(selected).length === Object.keys(options).length) {
      variants.forEach(variant => {
        let flag = true
        Object.keys(selected).forEach(key => {
          if (variant[key] !== selected[key]) {
            flag = false
          }
        })
        if (flag) {
          setSelectedVar(variant)
          if (variant?.quantity_in_stock <= 0) {
            setAuctionActive(false);
            setHasQuantity(false)
          }
          else {
            setHasQuantity(true)
          }
          return
        }
      })
    }
  }, [selected])

  const sortOptions = () => {
    const sorted = { ...options }; // Create a copy of options

    for (const key in options) {
      sorted[key].sort((a, b) => {
        const aQuantity = variants.find(variant => variant[key] === a)?.quantity_in_stock || 0;
        const bQuantity = variants.find(variant => variant[key] === b)?.quantity_in_stock || 0;

        if (aQuantity === 0 && bQuantity !== 0) {
          return 1;
        } else if (bQuantity === 0 && aQuantity !== 0) {
          return -1;
        }
        return 0;
      });
    }
    return sorted;

  }

  const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    borderRadius: "15px !important",
    border: "2px solid black !important",
    margin: "10px !important",
    padding: "5px 10px !important",
    display: 'block !important',
    boxSizing: 'border-box !important',
    overflow: 'hidden !important',
    minWidth: "60px !important",
    "&.Mui-selected": {
      backgroundColor: "#000 !important",
      color: "#fff",
      border: "2px solid black !important",
    },
    "&:hover": {
      backgroundColor: "#000 !important",
      color: "#fff",
    },
  }));

  return (
    <>
   
      <div>
        {Object.keys(sortOptions()).map(optionName => (
          <div key={optionName}>
            <div className='variant-selector-container'>
              {selected && (
                <ToggleButtonGroup
                  value={selected[optionName]}
                  exclusive
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      handleSelect({ target: { name: optionName, value: newValue } });
                    }
                  }}
                  size="small"
                >
                  {sortOptions()[optionName].map(value => (
                    <StyledToggleButton key={value} value={value} sx={{ fontSize: "0.8rem" }}>
                      {value}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* </div> */}
    </>
  )
}

export default VariantsSelector