import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import causeServices from "../../services/cause-services";
// import auctionServices from '../../services/auction-services';
import { Carousel } from 'react-responsive-carousel';
import logger from "../../utils/logger";
import { FilterContext } from "../../store/filter-context";
import { Desktop, Mobile } from "./responsive/Responsive";
// import classes from ".././desktop/styles/aboutcause.module.css"
import HeaderV2 from "./molecules/Header/HeaderV2";
import LoadingScreen from "./LoadingScreen/Loading";
import ItemDetailCardLayout from "./molecules/ItemDetailCardLayout/ItemDetailCardLayout";
import style from './AboutCause.module.css'
import NewFooter from "./molecules/NewFooter/NewFooter";
import { mobileStyle } from "./Home";
// import MobileSubFooter from "./atoms/MobileSubFooter/MobileSubFooter";
import AdminPreviewBanner from "./atoms/AdminPreviewBanner/adminPreviewBanner";
import { Helmet } from "react-helmet-async";
import { config } from "../../config/config";
import HeaderV3 from "./molecules/Header/HeaderV3/HeaderV3";
const {REACT_APP_URL} = config

function AboutCause() {
  const [causeData, setCauseData] = useState(null);
  const {reset} = useContext(FilterContext)
  const { causeId } = useParams();
  const [loading,setLoading] =useState(false)
  // const search = useLocation().search;
  const [landscapeImage,setLandscapeImage] = useState([]);
  const [curentImage, setCurrentImage] = useState(0);
  const params = useLocation().state;
  const bgColor = "var(--pure-white)"

  const handleGoToCurrentBrowseItems = async () => {
    // try {
    //   if(params?.auctionDetails?.auction && params?.auctionDetails?.auction?.image2) {
    //     navigate(`/event/${params?.auctionDetails?.auction?.custom_url ? params?.auctionDetails?.auction?.custom_url : params?.auctionDetails?.auction?.id}`, {
    //       state: { 
    //         auction: params?.auctionDetails?.auction,
    //         image: params?.auctionDetails?.auction?.image2,
    //         from: 'event'
    //       }
    //     });
    //   } else {
    //     const { data } = await auctionServices.getAuctionById(params?.auctionId);
    //     navigate(`/event/${data?.custom_url ? data?.custom_url : data?.id}`, {
    //       state: { 
    //         auction: data,
    //         image: data?.image2,
    //         from: 'event'
    //       }
    //     });
    //   }
    // } catch (error) {
    //   logger('./pages/components/AboutCause.jsx','handleGoToCurrentBrowseItems(): catch',error);
    //   navigate('/not-found');
    // }
    navigate(-1)
  };

  let navigate = useNavigate();
  useEffect(() => {
    const getCauseInfo = async () => {
      setLoading(true)
      try {
        await causeServices.causeInfo(causeId).then(
          (response) => {
            setCauseData(response?.data);
            setLandscapeImage(response?.data?.cause_logo)
            setLoading(false)
            
          },
          (error) => {
            logger('./pages/components/AboutCause.jsx','getCauseInfo()1: catch',error);
            setCauseData(null);
            setLoading(false)
            navigate("/not-found");
          }
        );
      } catch (err) {
        logger('./pages/components/AboutCause.jsx','getCauseInfo()2: catch',err);
        setCauseData(null);
        setLoading(false)
        navigate("/not-found");
      }
    };
    getCauseInfo();
    reset()
  }, [causeId]);

  const getNews = () => {
    window.location.href = process.env.REACT_APP_MAILTO;
  };

  return (
    <>
     <Helmet>
        <title>{causeData?.legal_name}</title>
        <meta 
          name="description" 
          content={causeData?.about}
        />
        <link rel="canonical" href={`${REACT_APP_URL}${window.location.pathname}`} />
      </Helmet>
    {
      loading && <LoadingScreen bgColor="rgb(240, 232, 227)"/>
    }
     <Desktop>
      <div  style={{minHeight:"100vh",backgroundColor:{bgColor}}}>
     
      {/* <HeaderV2 isSubHeader={true} subHeaderTitle="About The Cause" primaryBackgroundColor="#F0E8E3" primaryColor="#30313A" logoType="black2" containerStyle={{position:"fixed"}}/> */}
      <HeaderV3 isSubHeader subHeaderTitle="About The Cause" primaryBackgroundColor={bgColor} position="absolute"/>

        <div className="margin_class" style={{paddingTop:"200px"}}>

        <ItemDetailCardLayout alt={causeData?.cause_banner_alt} customImageClass={'custom_image_class'}  landscapeImage={landscapeImage && landscapeImage[curentImage]} onThumbNailClick={(idx) => {setCurrentImage(idx)}} >
            <div className={style.about_content}  >
              <h1 style={{fontSize:"47px",fontWeight:"400", lineHeight:'47px', paddingBottom:'30px', }}>About <br /> {causeData?.legal_name}</h1>
              <p className="para_fontsize" style={{textAlign: "left",fontSize:"18px",lineHeight:"24px",fontWeight:"400"}}> {causeData?.about} </p>
            </div>
        </ItemDetailCardLayout>
        {causeData?.video && (
              <video width="100%" height="auto" style={{ marginTop: 50, borderRadius: "15px" }} controls autoPlay muted>
                <source src={causeData?.video} type="video/mp4" />
              </video>
            )}
            <div style={{marginTop:"25px", textAlign: "left",width:"fit-content",paddingBottom:"50px" }}>
                <button onClick={handleGoToCurrentBrowseItems} style={{borderBottom:`1px solid #30313A`, margin:'0'}} className='to_top_btn'>Return to Event</button>
            </div>
        </div>
        {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
        <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

      </div>
      </Desktop>
      <Mobile className="page_outer about-cause-page">
        <div className="container" style={{height:"100%",backgroundColor:{bgColor}}}>
          {/* <HeaderV2 isSubHeader={true} subHeaderTitle="About The Cause" primaryBackgroundColor="#F0E8E3" primaryColor="#30313A" logoType="black2"/> */}
          <HeaderV3 isSubHeader subHeaderTitle="About The Cause" primaryBackgroundColor={bgColor} position="absolute"/>
          <div className='about-cause-page-banner'>
            <Carousel
              // infiniteLoop={true}
              showArrows={false}
              showIndicators={causeData?.images?.length > 1}
              showThumbs={true}
              // onClickItem={null}
              onClickThumb={null}
            >
              {causeData?.images &&
                causeData?.images.map((i, idx) => (
                  <div style={{padding: "25px 20px"}} key={i}>
                    <img style={{borderRadius: "9px"}} loading='lazy' src={i} alt={causeData?.cause_banner_alt} />
                  </div>
                ))}
            </Carousel>
          </div>
          <main style={{paddingInline:'20px'}}>
            <div className={style.about_content}>
              <h1 style={{fontSize:"32px",fontWeight:"400", lineHeight:'35px', paddingBlock:'30px', letterSpacing:'0.64px'}}>About <br /> {causeData?.legal_name}</h1>
              <p style={{textAlign: "left",fontSize:"18px",lineHeight:"24px",fontWeight:"400"}}> {causeData?.about} </p>
            </div>
            {causeData?.video && (
              <video width="100%" height="auto" style={{ marginTop: 50, borderRadius: "15px" }} controls autoPlay muted>
                <source src={causeData?.video} type="video/mp4" />
              </video>
            )}
            <p style={{ textAlign: "left",width:"fit-content" }}>
                <button onClick={handleGoToCurrentBrowseItems} style={{borderBottom:`1px solid #30313A`, margin:'0'}} className='to_top_btn'>Return to Event</button>
            </p>
          </main>
          {/* <MobileSubFooter style={{padding:'2vw 5vw 7vw 5vw'}} primaryBackgroundColor="#F0E8E3" primaryColor='#30313A'/> */}
          <NewFooter primaryBackgroundColor={mobileStyle.backgroundColor[1]} primaryColor={mobileStyle.color[2]} logoColor={mobileStyle.color[0]} />

        </div>
      </Mobile>
      {
        (params?.auctionDetails?.status === 'building')  && <AdminPreviewBanner title={params?.auctionDetails?.title}/>
    
      }
    </>
  );
}

export default AboutCause;
